import React, { useState } from "react";
import { Link } from "react-router-dom";
import images from "../../../../../core/constants/images";
import strings from "../../../../../core/constants/strings";
import MainButton from "../../../../components/MainButton";

import styles from "./styles.module.scss";

interface IProps {
  name: string;
  role: string;
  profilePic: string;
  profilePicHover: string;
  currentIndex: number;
}

const ProfileCard: React.FC<IProps> = ({
  name,
  role,
  profilePic,
  profilePicHover,
  currentIndex,
}) => {
  const [profileHover, setProfileHover] = useState<string>(profilePic);
  const [profileStyleHover, setProfileStyleHover] = useState<string>(
    styles.profilePicture
  );

  const location = {
    pathname: `/team/${name.replace(" ", "")}`,
    state: { currentIndex },
  };

  return (
    <div
      className={styles.profileBackground}
      style={{ backgroundImage: `url(${images.profile_background})` }}
    >
      <img
        className={profileStyleHover}
        src={profileHover}
        alt={`${name}'s profile`}
        onMouseEnter={() => {
          setProfileHover(profilePicHover);
          setProfileStyleHover(styles.profilePictureHover);
        }}
        onMouseLeave={() => {
          setProfileHover(profilePic);
          setProfileStyleHover(styles.profilePicture);
        }}
      />
      <div className={styles.memberDetailsContainer}>
        <span className={styles.name}>{name}</span>
        <span className={styles.role}>{role}</span>
      </div>
      <div className={styles.seeProfileButtonContainer}>
        <Link
          to={location}
          state={{ currentIndex }}
          className={styles.buttonLink}
        >
          <MainButton
            title={strings.ourTeam.seeProfile}
            primaryColor={"#EDA751"}
            onClick={() => {}}
          />
        </Link>
      </div>
    </div>
  );
};

export default ProfileCard;
