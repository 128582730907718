import React from "react";
import { isIOS } from "react-device-detect";

import images from "../../../core/constants/images";
import strings from "../../../core/constants/strings";
import routes from "../../../core/navigation/routes";
import styles from "./styles.module.scss";

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div className={styles.contactButtonContainer}>
          <a
            className={styles.contactBtnGroup}
            href={
              isIOS
                ? "maps:https://maps.google.com/maps?q=47.652677,23.585400"
                : "geo:?q=47.652677,23.585400"
            }
          >
            <div className={styles.contactButtonInner}>
              <img src={images.location} className={styles.buttonInnerImage} />
            </div>
            <div className={styles.contactButton}></div>
          </a>
          <p>{strings.footer.address}</p>
        </div>
        <div className={styles.contactButtonContainer}>
          <div className={styles.contactBtnGroup}>
            <div className={styles.contactButtonInner}>
              <img src={images.mail} className={styles.buttonInnerImage} />
            </div>
            <div className={styles.contactButton}></div>
          </div>
          <p>{strings.footer.email}</p>
        </div>
        <div className={styles.contactButtonContainer}>
          <div className={styles.contactBtnGroup}>
            <div className={styles.contactButtonInner}>
              <img src={images.phone} className={styles.buttonInnerImage} />
            </div>
            <div className={styles.contactButton}></div>
          </div>
          <p>{strings.footer.phone}</p>
          <p>{strings.footer.contactPerson}</p>
        </div>
      </div>
      <img src={images.logo} className={styles.logo}></img>
      <p className={styles.copyrightText}>{strings.footer.copyright}</p>
      <span
        className={styles.GDPR}
        onClick={() => (window.location.href = "/GDPR")}
      >
        GDPR
      </span>
      <div className={styles.row} style={{ width: "15%" }}>
        <a href="https://www.facebook.com/nomorecoffeeplease">
          <img src={images.facebook} />
        </a>
        <a href="https://www.instagram.com/nomorecoffeepls/?hl=ro">
          <img src={images.instagram} />
        </a>
        <a href="https://www.linkedin.com/company/nomorecoffeeplease/">
          <img src={images.linkedin} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
