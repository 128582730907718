import React, { useState } from "react";
import images from "../../../core/constants/images";
import styles from "./style.module.scss";

const ScrollUp = () => {
  const [isScrollButtonActive, setScrollButtonActive] = useState(false);

  window.onscroll = function () {
    if (window.pageYOffset < 800) {
      setScrollButtonActive(false);
    } else {
      setScrollButtonActive(true);
    }
  };

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  return (
    <div className={styles.scrollTopButtonContainer}>
      <div
        className={
          isScrollButtonActive
            ? styles.topBtnGroup
            : `${styles.topBtnGroup} ${styles.hideThis}`
        }
        onClick={topFunction}
      >
        <div className={styles.upCircleButton}>
          <span className={styles.goTopImg}></span>
        </div>
        <div className={styles.goTopButton}></div>
      </div>
    </div>
  );
};

export default ScrollUp;
