import React, { useState, useEffect } from "react";
import routes from "../../../core/navigation/routes";
import images from "../../../core/constants/images";
import styles from "./style.module.scss";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [show, setShow] = useState(false);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY === 0) {
        setShow(false);
      } else if (window.scrollY > lastScrollY) {
        setShow(true);
      } else {
        setShow(false);
        setShowMenu(false);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);
  return (
    <div className={styles.container}>
      <div className={`${styles.navbar} ${show && styles.hidden}`}>
        <div
          className={styles.menuContainer}
          onClick={() => {
            if (!showMenu) {
              setShowMenu(true);
            } else {
              setShowMenu(false);
            }
          }}
        >
          <div
            className={
              showMenu ? styles.menuFirstLineEnd : styles.menuFirstLine
            }
          />
          <div
            className={
              showMenu ? styles.menuSecondLineEnd : styles.menuSecondLine
            }
          />
          <div
            className={
              showMenu ? styles.menuThirdLineEnd : styles.menuThirdLine
            }
          />
        </div>

        {showMenu ? (
          <div className={styles.links}>
            {Object.values(routes).map(
              (item: { name: string; route: string }, index) => (
                <a key={index} href={item.route}>
                  {item.name}
                </a>
              )
            )}
          </div>
        ) : null}
        <a href={routes.home.route}>
          <img className={styles.logo} src={images.logo}></img>
        </a>
      </div>
    </div>
  );
};

export default Navbar;
