import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import images from '../../../core/constants/images';
import ScreenHeader from '../../components/ScreenHeader/ScreenHeader';
import Footer from '../../components/Footer/Footer';
import BlogCard from '../../components/BlogCard';
import { blogCategoryArray } from '../../../core/constants/arrays';
import MainButton from '../../components/MainButton';
import { colors } from '../../../core/theme/colors';
import ScrollUp from '../../components/ScrollUp/ScrollUp';
import { getBlogPosts } from '../../../functions/firebase';

interface BlogType {
  title: string;
  date: string;
  image: any;
  category: string;
  all: string;
  color: string;
  background: any;
  text: string;
}

interface FirebaseBlogType {
  blogTitle: string;
  blogDate: string;
  blogCategory: string;
  blogBody: string;
  all: string;
  color: string;
  image: any;
  background: any;
  authorName: string;
  authorFunction: string;
  authorImage: string;
}

const Blog = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>('All');
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [loadMore, setLoadMore] = useState<number>(6);
  const [filteredBlogs, setFilteredBlogs] = useState<FirebaseBlogType[]>([]);
  const [blogsArray, setBlogsArray] = useState<FirebaseBlogType[]>([]);

  const getAndFactorBlogArray = async () => {
    let blogPosts: FirebaseBlogType[] = await getBlogPosts();
    const formattedBlogArray = blogPosts.map(blog => {
      return {
        ...blog,
        all: 'All',
        color:
          blog.blogCategory === 'Development'
            ? colors.orange
            : blog.blogCategory === 'Marketing'
            ? colors.secondary
            : colors.pink,
        image: blog.image,
      };
    });
    setBlogsArray(formattedBlogArray);
  };

  useEffect(() => {
    const filteredArray = blogsArray.filter(
      categ =>
        categ.blogCategory
          .toLowerCase()
          .match(selectedCategory.toLowerCase()) ||
        categ.all?.match(selectedCategory)
    );
    setFilteredBlogs(filteredArray);
  }, [selectedCategory, blogsArray]);

  useEffect(() => {
    getAndFactorBlogArray();
  }, []);

  return (
    <div className={styles.container}>
      {blogCategoryArray.map((item, index) => (
        <div
          onClick={() => {
            setSelectedCategory(item.title);
            setCurrentIndex(index);
          }}
          className={styles.categories}
          style={
            item.title === selectedCategory
              ? {
                  color: item.color,
                  textDecoration: 'underline',
                  textUnderlineOffset: 8,
                }
              : { color: item.color }
          }
          key={index}
        >
          {item.title}
        </div>
      ))}
      <div className={styles.blogContainer}>
        {filteredBlogs.length > 0 ? (
          filteredBlogs
            .slice(0, loadMore)
            .map((item, index) => (
              <BlogCard
                image={item.image}
                date={item.blogDate}
                title={item.blogTitle}
                color={item.color}
                category={item.blogCategory}
                key={index}
                item={item}
              />
            ))
        ) : (
          <img src={images.blogSoon} className={styles.blogSoonImage} />
        )}
      </div>
      <div>
        {loadMore < filteredBlogs.length ? (
          <MainButton
            title={'LOAD MORE'}
            primaryColor={colors.cyan}
            onClick={() => setLoadMore(loadMore + 6)}
          />
        ) : null}
      </div>
      <ScrollUp />
      <Footer />
    </div>
  );
};

export default Blog;
