import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useLayoutEffect,
} from "react";
import images from "../../../core/constants/images";
import strings from "../../../core/constants/strings";
import Footer from "../../components/Footer/Footer";
import MainButton from "../../components/MainButton";
import styles from "./styles.module.scss";
import { Row, Col, FormControl, Form } from "react-bootstrap";
import { colors } from "../../../core/constants/colors";
import {
  createPaymentSession,
  sendEmailToNMCP,
  sendEmailWithQR,
} from "../../../functions/firebase";
import QRCode from "qrcode.react";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
} from "firebase/storage";
import { collection, addDoc, getFirestore } from "firebase/firestore";
import app from "../../../firebase";

const Events = () => {
  const [ticketsNumber, setTicketsNumber] = useState<number>(0);
  const [ticketsPrice, setTicketsPrice] = useState<number>(0);
  const [discordImage, setDiscordImage] = useState<string>(images.discord);
  const [name, setName] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [sessionUrl, setSessionUrl] = useState<string>("");
  const [ticketName, setTicketName] = useState<string>("");
  const [ticketEmail, setTicketEmail] = useState<string>("");
  const [showDetailsScreen, setShowDetailsScreen] = useState<boolean>(false);
  const [pressed, setPressed] = useState<boolean>(false);
  const [firebaseId, setFirebaseId] = useState<string[]>([]);
  const [ticketEmailError, setTicketEmailError] = useState<boolean>(false);
  const [ticketNameError, setTicketNameError] = useState<boolean>(false);
  const [ticketNumberError, setTicketNumberError] = useState<boolean>(false);

  const storage = getStorage();
  const base = getFirestore(app);
  const elemRef = useRef(null);

  const validate = () => {
    let emailError = "";

    if (!email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
      return (emailError = "email invalid");
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid !== null) {
      setEmailError(false);
    } else setEmailError(true);
    if (!emailError) {
      setName("");
      setMessage("");
      setEmail("");
    }
  };

  useEffect(() => {
    setTicketsPrice(ticketsNumber * 100);
    // $("#hiddenElement").hide();
  }, [ticketsNumber]);

  const getSessionUrl = useCallback(
    async (id: string) => {
      const url = await createPaymentSession(ticketsNumber, ticketEmail, id);
      setSessionUrl(url);
    },
    [ticketEmail, ticketsNumber]
  );

  const checkIfFields = () => {
    if (
      !ticketEmail.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g) ||
      ticketEmail === ""
    ) {
      setTicketEmailError(true);
    }

    if (ticketName === "") setTicketNameError(true);

    if (ticketsNumber === 0) setTicketNumberError(true);

    if (
      ticketEmail.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g) &&
      ticketEmail !== "" &&
      ticketName !== "" &&
      ticketsNumber > 0
    ) {
      setPressed(true);
    } else {
      setPressed(false);
    }
  };

  const setQRs = useCallback(async () => {
    const temp: string[] = [];
    for (let i: number = 0; i < ticketsNumber; i++) {
      const docRef = await addDoc(collection(base, "ticketsClone"), {
        email: ticketEmail,
        name: ticketName,
        validated: false,
      });
      temp.push(docRef.id);
    }
    setFirebaseId(temp);
  }, [ticketsNumber, ticketEmail, base, ticketName]);

  const saveQrsInFirebase = useCallback(async () => {
    const qrCode: string[] = [];
    Promise.all(
      firebaseId.map(async (item, index) => {
        const storageRef = ref(storage, item);
        let canvas = document.getElementById(
          `qrCode${index}`
        ) as HTMLCanvasElement;
        const base64Canvas = canvas.toDataURL("image/png").split(";base64,")[1];
        await uploadString(storageRef, base64Canvas, "base64", {
          contentType: "image/png",
        }).then(async (resp) => {
          await getDownloadURL(resp.ref).then((downloadURL) => {
            qrCode.push(downloadURL);
          });
        });
      })
    ).then(async () => {
      const tempDatesDoc = await addDoc(collection(base, "tempInfos"), {
        email: ticketEmail,
        name: ticketName,
        qrCodes: qrCode,
        id: firebaseId,
        sent: false,
      });
      getSessionUrl(tempDatesDoc.id);
    });
  }, [storage, base, firebaseId, ticketEmail, ticketName]);

  useEffect(() => {
    if (pressed) {
      setQRs();
    }
  }, [pressed, setQRs]);

  useEffect(() => {
    if (firebaseId.length > 0) saveQrsInFirebase();
  }, [firebaseId, saveQrsInFirebase]);

  useEffect(() => {
    if (sessionUrl) window.location.href = sessionUrl;
  }, [sessionUrl]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showDetailsScreen &&
        elemRef.current &&
        // @ts-ignore: Unreachable code error
        !elemRef.current.contains(e.target)
      ) {
        setShowDetailsScreen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showDetailsScreen]);

  useEffect(() => {
    const noSpacesEmail: string = ticketEmail.replace(/ /g, "");
    setTicketEmail(noSpacesEmail);
  }, [ticketEmail]);

  return (
    <div>
      <div className={styles.mainContainer}>
        {showDetailsScreen ? (
          <div className={styles.detailsScreen} ref={elemRef}>
            <input
              type="text"
              placeholder="Email..."
              onChange={(e) => setTicketEmail(e.target.value)}
              value={ticketEmail}
              className={ticketEmailError ? styles.inputError : styles.input}
              onClick={() => setTicketEmailError(false)}
            />
            <input
              type="text"
              placeholder="Name..."
              onChange={(e) => setTicketName(e.target.value)}
              value={ticketName}
              className={ticketNameError ? styles.inputError : styles.input}
              onClick={() => setTicketEmailError(false)}
            />
            <div
              className={
                ticketNumberError ? styles.ticketsTextError : styles.ticketsText
              }
            >
              Tickets Number: {ticketsNumber}
            </div>
            <div className={styles.ticketsText}>
              Tickets Price: {ticketsPrice}
            </div>
            <div className={styles.buyingSectionModal}>
              <div className={styles.cumparaBileteModal}>Cumpara bilete</div>
              <div className={styles.rightBuyingContainer}>
                <div className={styles.priceTextModal}>{ticketsPrice} lei</div>
                <div className={styles.ticketsNumberContainerModal}>
                  <div
                    className={styles.operatorContainerModal}
                    onClick={() => {
                      if (ticketsNumber > 0)
                        setTicketsNumber((prevState) => prevState - 1);
                      setTicketNumberError(false);
                    }}
                  >
                    -
                  </div>
                  <div className={styles.numberTicketsModal}>
                    {ticketsNumber}
                  </div>
                  <div
                    className={styles.operatorContainerModal}
                    onClick={() => {
                      setTicketNumberError(false);
                      setTicketsNumber((prevState) => prevState + 1);
                    }}
                  >
                    +
                  </div>
                </div>
                <div className={styles.buyButtonModal}>
                  <MainButton
                    title={pressed ? "LOADING..." : "BUY"}
                    primaryColor="#F6CF85"
                    onClick={checkIfFields}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}
        <div style={{ backgroundImage: `url(${images.circleLinesEvents})` }}>
          <div className={styles.detailsContainer}>
            <img
              src={images.festivalPoster}
              className={styles.whiteRectangle}
            />
            <div className={styles.textContainer}>
              <div className={styles.festivalText}>Festival NCMP</div>
              <div className={styles.communityText}>COMMUNITY BUILDING</div>
              <div className={styles.locationContainer}>
                <div className={styles.iconContainer}>
                  <img
                    src={images.locationIcon}
                    className={styles.locationIcon}
                  />
                </div>
                <div
                  className={styles.addressText}
                  onClick={() =>
                    window.open("https://goo.gl/maps/yAkV2GPzMiP93M2V7")
                  }
                >
                  Danesti, str. Principala, nr 239B, comuna Sisesti
                </div>
              </div>
              <div className={styles.dateContainer}>
                <div className={styles.iconContainer}>
                  <img src={images.watchIcon} className={styles.watchIcon} />
                </div>
                <div className={styles.dateText}>29-31 Iulie</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.mozartText}>Hello Community!</div>
        <div className={styles.mozartText}>
          În perioada 29-31 Iulie, te așteptăm la prima ediție a
          NoMoreCoffeePlease Festival. Celebrarea celor 2 ani ai companiei a
          creat oportunitatea perfectă de a sărbători împreună cu tine! Nu uita
          să-ți iei prietenii, un cort și hai să dăm startul unui weekend #epic!
        </div>
        <div className={styles.mozartText}>
          P.S. Prețul acoperă toate activitățile festivalului!
        </div>
        <div className={styles.mozartText}>
          Let's make some memories together!
        </div>
        <div className={styles.programColumns}>
          <div>
            <div className={styles.programTitle}>Day 1 (de la ora 18:00)</div>
            <div className={styles.programFirstText}>
              - instalarea corturilor
            </div>
            <div className={styles.programText}>- foc de tabără</div>
            <div className={styles.programText}>- ceaun (gulaș)</div>
          </div>
          <div>
            <div className={styles.programTitle}>Day 2 (de la ora 15:00)</div>
            <div className={styles.programFirstText}>- Dj</div>
            <div className={styles.programText}>- street food</div>
            <div className={styles.programText}>- cocktail bar</div>
            <div className={styles.programText}>
              - activități: olărit, tras cu arcul, lap tenis, fotbal etc.
            </div>
            <div className={styles.programText}>- henna tattoo</div>
            <div className={styles.programText}>
              * Pentru camperi, se va oferi mic dejun
            </div>
          </div>
          <div>
            <div className={styles.programTitle}>Day 3 (până la ora 20:00)</div>
            <div className={styles.programFirstText}>- mic dejun </div>
            <div className={styles.programText}>- relaxare</div>
          </div>
        </div>
        <div className={styles.buyingSection}>
          <div className={styles.cumparaBilete}>Cumpara bilete</div>
          <div className={styles.rightBuyingContainer}>
            <div className={styles.priceText}>{ticketsPrice} lei</div>
            <div className={styles.ticketsNumberContainer}>
              <div
                className={styles.operatorContainer}
                onClick={() => {
                  if (ticketsNumber > 0)
                    setTicketsNumber((prevState) => prevState - 1);
                  setTicketNumberError(false);
                }}
              >
                -
              </div>
              <div className={styles.numberTickets}>{ticketsNumber}</div>
              <div
                className={styles.operatorContainer}
                onClick={() => {
                  setTicketsNumber((prevState) => prevState + 1);
                  setTicketNumberError(false);
                }}
              >
                +
              </div>
            </div>
            <div className={styles.buyButton}>
              <MainButton
                title="BUY"
                primaryColor="#F6CF85"
                onClick={() => setShowDetailsScreen(true)}
              />
            </div>
          </div>
        </div>
        <div className={styles.discordContainer}>
          <span className={styles.getInTouchHeadline}>
            {strings.contactUs.getInTouch}
          </span>
          <span className={styles.discordAndMail}>
            {strings.contactUs.discord}
          </span>
          <img
            onMouseEnter={() => setDiscordImage(images.discordHover)}
            onMouseLeave={() => setDiscordImage(images.discord)}
            src={discordImage}
            className={styles.discord}
          />
          <span className={styles.discordAndMail}>
            {strings.contactUs.email}
          </span>
        </div>

        <Form
          className={styles.contactUsForm}
          method="POST"
          action="contact.php"
          onSubmit={handleSubmit}
        >
          <Row
            style={{
              display: "flex",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <Col>
              <Form.Control
                value={name}
                name="name"
                type="text"
                onChange={(text) => setName(text.target.value)}
                className={styles.input}
                placeholder={strings.contactUs.yourName}
                required
              />
            </Col>
            <Col>
              <Form.Control
                value={email}
                name="email"
                type="text"
                onChange={(text) => setEmail(text.target.value)}
                className={styles.input}
                placeholder={strings.contactUs.yourEmail}
                isInvalid={emailError}
                required
              />
            </Col>
          </Row>
          <Col>
            <Form.Control
              value={message}
              name="message"
              as="textarea"
              onChange={(text) => setMessage(text.target.value)}
              className={[styles.input, styles.messageInput].join(" ")}
              placeholder={strings.contactUs.yourMessage}
              required
            />
            {emailError ? (
              <div style={{ color: "red" }}>Invalid Email</div>
            ) : null}
          </Col>
          <div className={styles.bottomContainer}>
            <Col className={styles.sendButton}>
              <button type="submit" className={styles.contactButton}>
                <MainButton
                  title={strings.contactUs.send}
                  primaryColor={colors.manhattan}
                  onClick={() => sendEmailToNMCP(message, email, name, "", "")}
                />
              </button>
            </Col>
            <img src={images.nmcpCommunity} className={styles.nmcpCommunity} />
          </div>
        </Form>
      </div>
      <div id="hiddenElement">
        {firebaseId?.map((item, index) => {
          return (
            <QRCode
              id={`qrCode${index}`}
              value={item}
              size={400}
              level={"H"}
              includeMargin={true}
              imageSettings={{
                src: images.nmcpLogo,
                height: 60,
                width: 80,
                excavate: true,
              }}
            />
          );
        })}
      </div>
      <Footer />
    </div>
  );
};

export default Events;
