import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ProfileCard } from './components';

import styles from './style.module.scss';
import images from '../../../core/constants/images';
import Footer from '../../components/Footer/Footer';
import { ourTeamArray } from '../../../core/constants/arrays';
import ScrollUp from '../../components/ScrollUp/ScrollUp';
import 'react-circular-progressbar/dist/styles.css';
import strings from '../../../core/constants/strings';
import MainButton from '../../components/MainButton';

const OurTeam = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <h1 className={styles.title}>{strings.ourTeam.title}</h1>
          <p className={styles.description}>{strings.ourTeam.description}</p>
          <div className={styles.bottomContainer}>
            <div>
              <div className={styles.textContainer}>
                <h1 className={styles.secondaryTitle}>
                  {strings.ourTeam.secondaryTitle}
                </h1>
                <h1 className={styles.textHeader}>
                  {strings.ourTeam.firstTextHeader}:
                  <h1 className={styles.text}>{strings.ourTeam.firstText}</h1>
                </h1>
                <h1 className={styles.textHeader}>
                  {strings.ourTeam.secondTextHeader}:
                  <h1 className={styles.text}>{strings.ourTeam.secondText}</h1>
                </h1>
              </div>
              <MainButton
                title={strings.contactUs.send}
                primaryColor="#F6CF85"
                onClick={() => (window.location.href = '/contact')}
              />
            </div>
            <img
              src={images.ourTeamPhoto}
              className={styles.mainPhoto}
              alt="teamPhoto"
            />
          </div>
        </div>
        <span className={styles.ourDedicatedTeamText}>Our Dedicated Team</span>
        <Row className={styles.teamMembersListContainer}>
          {Object.values(ourTeamArray).map((item, index) => (
            <Col className={styles.centeredCol}>
              <ProfileCard
                name={item.name}
                role={item.role}
                profilePic={item.profilePic}
                profilePicHover={item.profilePicHover}
                currentIndex={index}
                key={index}
              />
            </Col>
          ))}
        </Row>
        <ScrollUp />
      </div>
      <Footer />
    </>
  );
};

export default OurTeam;
