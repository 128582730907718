import React, { useEffect, useState } from 'react';

const Terms = () => {
  const pdf = require('../../../core/assets/files/Politica_de_confidentialitate.pdf');
  const pdfEng = require('../../../core/assets/files/Politica_de_confidentialitate1-en.pdf');
  const [browserLanguage, setBrowserLanguage] = useState<string>('');

  useEffect(() => {
    setBrowserLanguage(navigator.language);
  }, []);

  return (
    <iframe
      src={browserLanguage.trim() === 'en-US' ? pdfEng : pdf}
      width="100%"
      style={{ position: 'absolute', height: '100%', border: 'none' }}
    />
  );
};

export default Terms;
