import React, { useState, useEffect } from 'react';

const GDPR = () => {
  const pdf = require('../../../core/assets/files/GDPR.pdf');
  const pdfEng = require('../../../core/assets/files/GDPR1-en.pdf');

  const [browserLanguage, setBrowserLanguage] = useState<string>('');

  useEffect(() => {
    setBrowserLanguage(navigator.language);
  }, []);

  return (
    <iframe
      src={browserLanguage.trim() === 'en-US' ? pdfEng : pdf}
      width="100%"
      style={{ position: 'absolute', height: '100%', border: 'none' }}
    />
  );
};

export default GDPR;
