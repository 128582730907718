import React from "react";
import styles from "../../screens/Homepage/style.module.scss";
import images from "../../../core/constants/images";
import strings from "../../../core/constants/strings";

const ExpectationsLine = () => {
  return (
    <div className={styles.expectLineContainer}>
      <div
        className={styles.upperLine}
        style={{
          backgroundImage: `url(${images.expectCirclesLinesTablet})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <img src={images.brainstorming} className={styles.brainstormingImage} />
        <div className={styles.categoryText}>
          <div className={styles.secondCategoryTitle}>
            {strings.homepage.expectations.executionTitle1}
          </div>
          <div className={styles.secondCategoryTitle}>
            {strings.homepage.expectations.executionTitle2}
          </div>
          <div className={styles.secondaryText}>
            {strings.homepage.expectations.weStriveTo}
          </div>
        </div>
        <img src={images.settingsTools} className={styles.settingsTools} />
        <div className={styles.categoryText}>
          <div className={styles.fourthCategoryTitle}>
            {strings.homepage.expectations.deliveryTitle1}
          </div>
          <div className={styles.fourthCategoryTitle}>
            {strings.homepage.expectations.deliveryTitle2}
          </div>
          <div className={styles.secondaryText}>
            {strings.homepage.expectations.delivery}
          </div>
        </div>
      </div>
      <img src={images.expectLineMobile} className={styles.expectLine} />

      <div className={styles.downLine}>
        <div className={styles.categoryText}>
          <div className={styles.firstCategoryTitle}>
            {strings.homepage.expectations.strategyTitle1}
          </div>
          <div className={styles.firstCategoryTitle}>
            {strings.homepage.expectations.strategyTitle2}
          </div>
          <div className={styles.secondaryText}>
            {strings.homepage.expectations.communicationIs}
          </div>
        </div>
        <img
          src={images.executionTools}
          className={styles.executionToolsImage}
        />

        <div className={styles.categoryText}>
          <div className={styles.thirdCategoryTitle}>
            {strings.homepage.expectations.testingTitle1}
          </div>
          <div className={styles.thirdCategoryTitle}>
            {strings.homepage.expectations.testingTitle2}
          </div>
          <div className={styles.secondaryText}>
            {strings.homepage.expectations.weHaveContinous}
          </div>
        </div>

        <img src={images.emailTools} className={styles.emailToolsImage} />
      </div>
    </div>
  );
};

export default ExpectationsLine;
