export const Text = {
  section_0: {
    title_1: "BOOKTRADE",
    title_2: "DELETE ACCOUNT AND PERSONAL DATA",
    paragraph_1:
      "Before proceeding to delete your account, keep in mind that this action is irreversible; your personal data will be permanently deleted from the app.",
    paragraph_2:
      "For deleting your account you have to follow this steps:",
    paragraph_3:
      "1. Press the profile button on the bottom of your screen (right-side).",
    paragraph_4:
      `2. Use the "Settings" button to show the options.`,
    paragraph_5:
      `3. Now press on "Delete Account".`,
    paragraph_6:
      `4. A modal is opened now for confirmation. In order to delete your account, press "YES" button!`,
      paragraph_7:
      `You're done. Your personal data is permanently deleted from BookTrade!`,
  },
};