import React, { useEffect, useState } from "react";

import ScreenHeader from "../../components/ScreenHeader/ScreenHeader";
import { ourTeamArray } from "../../../core/constants/arrays";
import styles from "./style.module.scss";
import images from "../../../core/constants/images";
import Footer from "../../components/Footer/Footer";
import strings from "../../../core/constants/strings";
import ScrollUp from "../../components/ScrollUp/ScrollUp";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import { useLocation } from "react-router-dom";

interface ProjectType {
  name: string;
  description: string;
  time: string;
  projectScreens: any[];
  responsibilities: string;
  acquiredSkills?: string;
  technology?: string;
  projectType: string;
}

interface Props {
  project: ProjectType;
}

interface PersonalSkill {
  skill: string;
  percentage: string;
}

const Project: React.FC<Props> = ({ project }) => {
  const projectScreens = project.projectScreens;
  return (
    <div className={styles.projectContainer}>
      <div className={styles.projectDetails}>
        <div className={styles.projectTitle}>{project.name}</div>
        <div className={styles.projectRoles}>
          {strings.ourTeam.time}:&nbsp;{project.time}
        </div>
        <div className={styles.spacer} />
        <div className={styles.descriptionTitle}>
          {strings.ourTeam.projectDescription}
        </div>
        <div className={styles.descriptionText}>
          {project.description.split("\n").map((text) => {
            return <div>{text}</div>;
          })}
        </div>
      </div>

      {projectScreens.length !== 0 ? (
        <>
          <div className={styles.spacer} />
          <div className={styles.descriptionTitle}>
            {strings.ourTeam.projectScreens}
          </div>
          <ImageCarousel
            imageArray={projectScreens}
            projectType={project.projectType}
          />
        </>
      ) : (
        <></>
      )}
      <div className={styles.spacer} />
      {project.responsibilities ? (
        <>
          <div className={styles.descriptionTitle}>
            {strings.ourTeam.responsibilities}
          </div>
          <div className={styles.descriptionText}>
            {project.responsibilities.split("\n").map((responsibility) => {
              return <div>{responsibility}</div>;
            })}
          </div>
          <div className={styles.spacer} />
        </>
      ) : null}
      {project.acquiredSkills ? (
        <>
          <div className={styles.descriptionTitle}>
            {strings.ourTeam.acquiredSkills}
          </div>
          <div className={styles.descriptionText}>
            {project.acquiredSkills.split("\n").map((skill) => {
              return <div>{skill}</div>;
            })}
          </div>
        </>
      ) : (
        <></>
      )}
      <div className={styles.spacer} />
      {project.technology ? (
        <>
          <div className={styles.descriptionTitle}>
            {strings.ourTeam.technology}
          </div>
          <div className={styles.descriptionText}>{project.technology}</div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const Profile = () => {
  const [imageHover, setImageHover] = useState<string>(images.profilePic);
  const [profileStyleHover, setProfileStyleHover] = useState<string>(
    styles.profilePic
  );

  const location = useLocation();

  const index = location.state
    ? (location.state as { currentIndex?: number })?.currentIndex!
    : ourTeamArray.findIndex(
        (person) =>
          "/team/" + person.name.replace(" ", "") === location.pathname
      );

  useEffect(() => {
    setImageHover(ourTeamArray[index].profilePic);
    setProfileStyleHover(styles.profilePic);
    window.scrollTo(0, 570);
  }, []);

  return (
    <>
      <div className={styles.container}>
        <ScreenHeader pageLogo={images.ourTeam} />
        <div className={styles.upperContainer}>
          <img
            src={imageHover}
            className={profileStyleHover}
            onMouseEnter={() => {
              setImageHover(ourTeamArray[index].profilePicHover);
              setProfileStyleHover(styles.profilePicHover);
            }}
            onMouseLeave={() => {
              setImageHover(ourTeamArray[index].profilePic);
              setProfileStyleHover(styles.profilePic);
            }}
            alt={`${ourTeamArray[index].name}'s profile`}
          />
          <div>
            <div className={styles.name}>{ourTeamArray[index].name}</div>
            <div className={styles.role}>{ourTeamArray[index].role}</div>
            {ourTeamArray[index].responsibility ? (
              <div className={styles.title}>
                {strings.ourTeam.responsibility}:&nbsp;
                <div className={styles.subtitle}>
                  {ourTeamArray[index].responsibility}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className={styles.title}>
              {strings.ourTeam.experience}:&nbsp;
              <div className={styles.subtitle}>
                {ourTeamArray[index].experience}
              </div>
            </div>
            {ourTeamArray[index].email ? (
              <div className={styles.title}>
                {strings.ourTeam.email}:&nbsp;
                <div className={styles.subtitle}>
                  {ourTeamArray[index].email}
                </div>
              </div>
            ) : (
              <></>
            )}
            {ourTeamArray[index].phone ? (
              <div className={styles.title}>
                {strings.ourTeam.phone}:&nbsp;
                <div className={styles.subtitle}>
                  {ourTeamArray[index].phone}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={styles.descriptionContainer}>
          <div className={styles.descriptionTitle}>
            {strings.ourTeam.summary}
          </div>
          <div className={styles.descriptionText}>
            {ourTeamArray[index].personalExp}
          </div>
          <div className={styles.spacer} />
          <div className={styles.percentageRow}>
            <div className={styles.percentageContainer}>
              <div className={styles.percentageContainer}>
                <div className={styles.descriptionTitle}>
                  {strings.ourTeam.professionalSkills}
                </div>
                <div className={styles.spacer} />

                {ourTeamArray[index].personalSkills.map(
                  (item: PersonalSkill) => {
                    return (
                      <>
                        <div className={styles.percentageRow}>
                          <div className={styles.percentageTitle}>
                            {item.skill}
                          </div>
                          <div className={styles.percentageTitle}>
                            {item.percentage}
                          </div>
                        </div>
                        <div className={styles.percentageUnderline} />
                      </>
                    );
                  }
                )}

                <div className={styles.spacer} />
              </div>
              <div className={styles.descriptionTitle}>
                {strings.ourTeam.projects}
              </div>

              {ourTeamArray[index].projects.map((item: ProjectType) => {
                return <Project project={item} />;
              })}
            </div>
          </div>
        </div>
        <ScrollUp />
        <Footer />
      </div>
    </>
  );
};

export default Profile;
