export const colors = {
  primary: "#78cfc0",
  primaryTransparent: "#78cfc0cc",
  secondary: "#ffdc9c",
  projectGray: "#EBEBEB",
  black29op: "#00000029",
  opium: "#8f6f6f",
  doveGray: "#707070",
  white: "#ffffff",
  tertiary: "#8f6f6f",
  lightGray: "#CFCFCF",
  turquoise: "#6aa5ac",
  silver: "#cccccc",
  orange: "#EDA751",
  pink: "#FF91AB",
  gray: "#7E7E7E",
  cyan: "#72BEB7",
  lightningYellow: "rgba(255, 162, 41, 0.8)",
  mauvelous: "rgba(255, 145, 171, 0.80)",
  cherokee: "rgba(246, 207, 133, 0.8)",
};
