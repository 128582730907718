import React from 'react';
import images from '../../../core/constants/images';
import strings from '../../../core/constants/strings';
import routes from '../../../core/navigation/routes';

import styles from './styles.module.scss';
import ContactDetails from '../ContactDetails';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.links}>
        {Object.values(routes).map(
          (route: { name: string; route: string }, index) => (
            <a key={index} href={route.route}>
              {route.name}
            </a>
          )
        )}
      </div>
      <ContactDetails />
      <img src={images.logo}></img>
      <p>{strings.footer.copyright}</p>
      <Link className={styles.GDPR} to={'/gdpr'}>
        GDPR
      </Link>
      <div className={styles.row} style={{ width: '15%' }}>
        <a href="https://www.facebook.com/nomorecoffeeplease">
          <img src={images.facebook} />
        </a>
        <a href="https://www.instagram.com/nomorecoffeepls/?hl=ro">
          <img src={images.instagram} />
        </a>
        <a href="https://www.linkedin.com/company/nomorecoffeeplease/">
          <img src={images.linkedin} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
