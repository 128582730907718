import images from './images';

const projectsObjectEN = {
  bsr: {
    status: 'May 2022',
    projectName: '',
    projectIndividualName: 'BSR',
    projectScreens: images.projectScreens.bsr,
    projectScreen: 'desktop',
    projectType: 'Web Dev',
    projectPortofolioName: 'BSR',
    projectNameStyles: { color: '#696969' },
    projectLogo: images.bsrLogo,
    portofolioProjectLogo: images.portofolio.BSRlogo,
    projectLogoStyles: { width: 300, height: 'auto' },
    projectLogoTabletStyles: { width: 200, height: 'auto' },
    clients: ['BSR'],
    projectImage: images.portofolio.BSR,
    clientsIcons: [images.bsrLogo],
    team: ['Norbi', 'Marcel', 'Kinga', 'Tudor', 'Andrei', 'Daniel'],
    teamFullName: [
      'Norbi Postol',
      'Marcel Marc',
      'Kinga Boros',
      'Tudor Eugeniu',
      'Andrei Pop',
      'Daniel Laios',
    ],
    teamImages: [
      images.Norbi,
      images.Marcel,
      images.Kinga,
      images.TudorEugeniu,
      images.AndreiPop,
      images.DanielLaios,
    ],
    services: ['React'],
    servicesIcons: [images.portofolio.reactIcon],
    category: ['Web Dev'],
    categoryIcons: [images.portofolio.webDevIcon],
    description:
      'Materiality Web, an internal app, is our most significant project and was created for a sustainable business network and consultancy client. They aim to establish a world where everyone can flourish on a healthy planet.',
    aFewWords: `Materiality Web is an internal app that we developed for one of our clients. The client is a B2B organization that helps identify potential risks or issues in any business, no matter its sector. BSR will interview all team members, gather data, and, based on this, come up with a list of things to improve in any business.`,
    summaryResults: `Based on our client's Excel processes, we developed Materiality, a web platform that integrates multiple data points into a time-based chart. This chart provides a historical record of changes and enables users to identify the key topics that a business is focused on. Users can also rate the importance of these topics using this platform.
    The biggest challenge encountered during the project was incorporating the chart functionality. The chart is the centerpiece of the app, allowing the user to interact with the data, change it directly from the UI, import and export Excel files, retrieve information from external tools, and perform various other functions.
    Our approach to developing the app was centered on creating a user-friendly way to interact with complex data, visualizing trends and patterns over time. Also, the app enables users to make changes on the go and have everything saved for later use.
    `,
    testimonials: '',
    industry: 'World Sustenability',
    product: 'Website platform',
    review: {
      author: 'Phil Olarte',
      text: "Andrei is an excellent leader and cultivator of the young developer community. His unique approach helps build better coders during and even after their schooling. Under his leadership, NoMoreCoffeePlease easily understood our business needs, maintained great communication, and built products right the first time. It's a great team with deep knowledge. I've hired them for other projects; their work never disappoints, and no problem is too hard.",
    },
  },
  chalkNotes: {
    status: 'In progress...',
    projectName: '',
    projectScreen: 'mobile',
    projectType: 'Mobile Dev',
    portofolioProjectLogo: images.portofolio.chalkNoteslogo,
    projectScreens: images.projectScreens.chalknotes,
    projectPortofolioName: 'ChalkNotes',
    projectIndividualName: 'ChalkNotes',
    projectImage: images.portofolio.chalkNotes,
    projectNameStyles: { color: '#3EBBF3' },
    projectLogo: images.portofolio.chalkNotesLogo,
    projectLogoStyles: { width: 180, height: 'auto' },
    projectLogoTabletStyles: { width: 81, height: 'auto' },
    clients: ['ChalkNotes'],
    clientsIcons: [images.portofolio.chalkNotesLogo],
    team: ['Denis', 'Dani Laios'],
    teamFullName: ['Denis Chisalita', 'Daniel Laios'],
    teamImages: [images.Denis, images.DanielLaios],
    services: ['React Native'],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Mobile Dev'],
    categoryIcons: [images.portofolio.webDevIcon],
    description:
      'ChalkNotes is a New York-based startup that has developed a platform designed to bring together content creators and consumers in the pursuit of two key things: communities and augmented reality.',
    aFewWords: `ChalkNotes is a New York startup that has in mind connecting people better, combining online and offline, and creating a custom-tailored experience for members of a community. You can create your own community and create an experience for it. We’ve also created an AR feature for this application. Founders: Phil Olarte, Ethan Meyers Andrei Stoica had the pleasure at first of working with Phil on this startup, and later on, he passed the work to his team: Norbi Postol and Denis Chisalita.`,
    summaryResults: `One of the biggest challenges we faced was figuring out how to make the AR feature work seamlessly within the platform. We also had to create a payment system that was easy to use for the users, while still being secure and reliable. Another obstacle was creating a reward system that would motivate users to complete experiences and feel proud of what they accomplished. But, another complex task we faced was building a map that was easy to navigate and use, while also calculating different positions on the map and creating an algorithm for the multiple-choice experiences.
    The solution relies on React-Native, a solution that allows us to deliver a seamless experience for both iOS and Android users. We've also integrated several exciting features, such as the ability to create multiple-choice experiences for others based on locations on the map. And, of course, the augmented reality feature that allows users to interact with virtual objects in the real world, just like Pokemon Go. 
    `,
    testimonials: `"Andrei is an excellent leader and cultivator of the young developer community. His unique approach helps build better coders during and even after their schooling. Under his leadership, NoMoreCoffeePlease easily understood our business needs, maintained great communication, and built products right the first time. "It's a great team with deep knowledge." "I've hired them for other projects; their work never disappoints, and no problem is too hard."`,
    industry: 'Social',
    product: 'app platform',
    review: {
      author: 'Phil Olarte',
      text: "Andrei is an excellent leader and cultivator of the young developer community. His unique approach helps build better coders during and even after their schooling. Under his leadership, NoMoreCoffeePlease easily understood our business needs, maintained great communication, and built products right the first time. It's a great team with deep knowledge. I've hired them for other projects; their work never disappoints, and no problem is too hard.",
    },
  },
  BookTrade: {
    status: 'In progress...',
    projectName: 'BookTrade',
    projectIndividualName: 'BookTrade',
    projectType: 'Mobile Dev',
    portofolioProjectLogo: images.portofolio.bookTradelogo,
    projectScreens: images.projectScreens.bookTrade,
    projectScreen: 'mobile',
    projectPortofolioName: 'BookTrade',
    projectImage: images.portofolio.bookTrade,
    projectNameStyles: { color: '#465280' },
    projectLogo: images.bookTradeLogo,
    projectLogoStyles: { width: 260, height: 'auto' },
    projectLogoTabletStyles: { width: 200, height: 'auto' },
    clients: ['NoMoreCoffeePlease'],
    clientsIcons: [images.nmcpLogo],
    team: ['Sylvi', 'Andrei', 'Catalin', 'Bogdan', 'Bogdan P.'],
    teamFullName: [
      'Sylvi Incze',
      'Andrei Pop',
      'Catalin Racz',
      'Bogdan Tinta',
      'Bogdan Pop',
    ],
    teamImages: [
      images.Sylvi,
      images.AndreiPop,
      images.Catalin,
      images.BogdanPop,
    ],
    services: ['React Native', 'Figma'],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Mobile Dev', 'UI/UX'],
    categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
    description:
      "BookTrade is an internal project, focused on connecting individuals who share a passion for reading and appreciate each other's literary preferences. The platform offers a simple and intuitive interface that allows you to swipe and match with people who share similar book interests.",
    aFewWords: `BookTrade is an internal project, focused on connecting individuals who share a passion for reading and appreciate each other's literary preferences. The platform offers a simple and intuitive interface that allows you to swipe and match with people who share similar book interests.`,
    summaryResults: `This project required a different approach from our usual projects. We poured considerable resources into the development of the platform. We took on the initial designs, management, and other risks, ensuring that the project met our high standards. Our technical approach was focused on creating a foolproof architecture, designed to minimize the risk of failure, an architecture that would stand the test of time, making the platform relevant and functional for at least five years. 
    This app offers an excellent opportunity to establish new friendships with people who share your passion for reading. 
    `,
    testimonials: `"The BookTrade app is a new revolutionary method to create connections between people that share the same interests and match each other's tastes in books (not only books though, as they surely have a lot of things in common)." "I think it will create a lot of friendships."`,
    industry: 'Book Industry',
    product: 'app platform',
    review: { author: '', text: '' },
  },

  dogScouts: {
    status: 'December 2020',
    projectName: 'Dog Scouts',
    projectScreen: 'desktop',
    projectType: 'Web Dev',
    projectPortofolioName: 'Dog Scouts',
    portofolioProjectLogo: images.portofolio.dogScoutslogo,
    projectImage: images.portofolio.dogScouts,
    projectIndividualName: 'Dog Scouts',
    projectScreens: images.projectScreens.dogScouts,
    projectNameStyles: { color: '#EDA751' },
    projectLogo: images.portofolio.dogScoutslogo,
    projectLogoStyles: { width: 200, height: 'auto' },
    projectLogoTabletStyles: { width: 89, height: 'auto' },
    clients: ['Dog Scouts'],
    clientsIcons: [images.portofolio.dogScoutsLogo],
    team: ['Andrei', 'Norbi'],
    teamFullName: ['Andrei Stoica', 'Norbi Postol'],
    teamImages: [images.Stoica, images.Norbi],
    services: ['React'],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Web Dev', 'UI/UX'],
    categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
    description:
      'DogScouts is a website that presents available events for dogs, events that lead dog owners to a community full of joy, games, and other dog lovers.',
    aFewWords: `DogScouts is a website that presents available events for dogs, events that lead dog owners to a community full of joy, games, and other dog lovers.`,
    summaryResults: `Dogscouts is a website where you can see the events made for your dogs. DogScouts is a community for dog and animal lovers where there are organized gatherings for different activities with your dog. They are active in different cities throughout Romania. We've created a simple website where people can watch the next four upcoming events and sign up for the newsletter. The idea behind this website was to showcase the events but also to further build upon it, moving towards videoconferences and custom experiences for each user. For us, this project was taken on because we wanted to help out the local communities with our software, which is our passion.`,
    testimonials: '',
    industry: 'Social',
    product: 'Website platform',
    review: { author: '', text: '' },
  },

  romanticStudio: {
    status: 'September 2022',
    projectName: '',
    projectScreen: 'desktop',
    projectType: 'Web Dev',
    projectPortofolioName: 'Romantic Studio Brasov',
    portofolioProjectLogo: images.portofolio.romanticStudiologo,
    projectImage: images.portofolio.romanticStudioApartment,
    projectIndividualName: 'Romantic Studio Brasov',
    projectScreens: images.projectScreens.brasov,
    projectNameStyles: { color: '#B83131' },
    projectLogo: images.portofolio.romanticStudioLogo,
    projectLogoStyles: { width: 330, height: 150 },
    projectLogoTabletStyles: { width: 200, height: 'auto' },
    clients: ['Romantic Studio Brasov'],
    clientsIcons: [images.portofolio.romanticStudioLogo],
    team: ['Kinga', 'Cata Racz', 'Dani Anca'],
    teamFullName: ['Kinga Boros', 'Catalin Racz', 'Dani Anca'],
    teamImages: [images.Kinga, images.Catalin, images.Dani],
    services: ['React Native', 'Adobe XD'],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Web Dev', 'UI/UX'],
    categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
    description:
      'Romantic Studio Brasov consists of a website that, besides presenting a full of romance rental units for lovers, also allows you to inquire about a reservation using a contact form and a calendar similar to what you see on other booking platforms.',
    aFewWords: `Romantic Studio Brasov is a place where two lovers can enjoy their time together without worrying about anything. From breakfast services and bouquets of roses to jacuzzi and sparkling wine. There are various packages to satisfy everyone's needs. You can pick one of the offers along with other extra services and book your days, and everything else will be managed by the team.`,
    summaryResults: `The most challenging part was the booking process and the calendar. We implemented an "AirBnB kind of" calendar where you can pick the day's range for your visit. Another challenge was the Booking flow, from the starting point to the order confirmation.`,
    testimonials: '',
    industry: 'Social',
    product: 'Website platform',
    review: { author: '', text: '' },
  },

  zem: {
    status: '11 November 2020',
    projectName: '',
    portofolioProjectLogo: images.portofolio.zemlogo,
    projectType: 'Web Dev',
    projectIndividualName: 'ZEM',
    projectPortofolioName: 'ZEM',
    projectScreens: images.projectScreens.zem,
    projectImage: images.portofolio.zem,
    projectScreen: 'desktop',
    projectNameStyles: { color: '#000000' },
    projectLogo: images.zemLogo,
    projectLogoStyles: { width: 260, height: 'auto' },
    projectLogoTabletStyles: { width: 200, height: 'auto' },
    clients: ['zem'],
    clientsIcons: [images.zemLogo],
    teamFullName: ['Norbi Postol', 'Andrei Stoica'],
    team: ['Norbi', 'Andrei'],
    teamImages: [images.Norbi, images.Stoica],
    services: ['React', 'Adobe XD'],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Web Dev', 'UI/UX'],
    categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
    description:
      'Zem is a presentational website for an NGO that had two live events every year. They needed to show video and photo content to medical students in a digital area that was different and more powerful than social media.',
    aFewWords: `This project was done with the collaboration of ZEM NGO, a community for medical school students. ZEM had two live events each year, and they needed to have a digital presence besides social media channels. They came to us to deliver and create their website, a presentational website where we show events and the team behind them. Video and Photo content as well, from the events.`,
    summaryResults: ``,
    testimonials: '',
    industry: 'Social',
    product: 'Website platform',
    review: { author: '', text: '' },
  },
  oasisResidence: {
    status: '12 January 2022',
    portofolioProjectLogo: images.portofolio.oasislogo,
    projectName: '',
    projectType: 'Web Dev',
    projectIndividualName: 'Oasis Residence',
    projectScreens: images.projectScreens.oasis,
    projectScreen: 'desktop',
    projectImage: images.portofolio.oasisResidence,
    projectPortofolioName: 'Oasis Residence',
    projectNameStyles: { color: '#CAA987' },
    projectLogo: images.oasisLogo,
    projectLogoStyles: { width: 300, height: 'auto' },
    projectLogoTabletStyles: { width: 200, height: 'auto' },
    clients: ['Oasis Residence'],
    clientsIcons: [images.oasisLogo],
    team: ['Dani Anca', 'Cata Racz', 'Andrei Pop'],
    teamFullName: ['Dani Anca', 'Catalin Racz', 'Andrei Pop'],
    teamImages: [images.Dani, images.Catalin, images.AndreiPop],
    services: ['React', 'Adobe XD'],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Web Dev', 'UI/UX'],
    categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
    description:
      'Oasis Residence is a rental apartment business that manages multiple properties in Baia Mare. Additionally, they own an apartment with the same name located in Baia Mare.',
    aFewWords: `Oasis Residence Project is a presentational website created for a client. It is about an apartment in Baia Mare that can be booked for as many nights as the customer wants. Besides the presentation of the apartment with photos, videos, and testimonials, the website also incorporates functionalities like sending e-mails, in-site reservations and payments, an admin page for manual syncing with Booking and Airbnb, and the possibility to subscribe to a newsletter.`,
    summaryResults: `The project itself doesn't seem like much of a challenge at first, but when we take a deeper look into the functionalities and everything that goes on behind the scenes, we can understand that it is not really so simple. The biggest challenges were probably making the reservation algorithm work like a charm (with CheckIn or CheckOut only dates and already booked days) and introducing the Stripe payment method in order to complete the reservation (this also includes sending an invoice to the client after the reservation is done).`,
    testimonials: '',
    industry: 'Social',
    product: 'Website platform',
    review: { author: '', text: '' },
  },
  nestInn: {
    status: 'In progress...',
    portofolioProjectLogo: images.nestInnLogo,
    projectName: '',
    projectType: 'Marketing',
    projectIndividualName: 'NestInn',
    projectScreens: [],
    projectScreen: 'desktop',
    projectImage: images.portofolio.nestInn,
    projectPortofolioName: 'NestInn',
    projectNameStyles: { color: '#CAA987' },
    projectLogo: images.oasisLogo,
    projectLogoStyles: { width: 300, height: 'auto' },
    projectLogoTabletStyles: { width: 200, height: 'auto' },
    clients: ['Oasis Residence'],
    clientsIcons: [images.oasisLogo],
    team: ['Rowena S.', 'Andra E.', 'Sylvi I.'],
    teamFullName: ['Rowena Salajan', 'Sylvi Incze'],
    teamImages: [images.Rowena, images.AndraElekes, images.Sylvi],
    services: [
      'Social Media pages',
      'E-mail Marketing',
      'Marketing strategy',
      'Google ADS',
      'Meta ADS',
    ],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Marketing'],
    categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
    description:
      'Nest Inn is a brand of apart-hotels in Cluj-Napoca, with three convenient locations in the city. Each apartment is carefully designed to cater to the needs of travelers, including those on business trips.',
    aFewWords: `Nest Inn is a brand of apart-hotels in Cluj-Napoca, with three convenient locations in the city. Each apartment is carefully designed to cater to the needs of travelers, including those on business trips.`,
    summaryResults: `Having prior experience in aparthotel marketing, we were well-prepared to tackle the Nest Inn project. We initiated the endeavor from scratch, meticulously crafting the brand's identity, actively promoting it on various social media platforms, and consistently launching fresh marketing campaigns. We were captivated by the project right from the start. Cluj-Napoca's reputation for its lively atmosphere keeps us always engaged, eagerly awaiting the latest developments. We're determined not to fall behind on trends, events, or anything else. Research is our key to success!`,
    testimonials: '',
    industry: 'Travel',
    product: 'Marketing',
    review: {
      author: 'Nicoleta Stoica',
      text: "Working with NMCP's marketing team is a piece of cake. They're super friendly and always ready to help. I've been with them since the start of Nest Inn, and I'm really happy. They even organized an event that went very well. Also, they are managing the Social Media pages and Email Marketing, creating a marketing strategy every month. I highly recommend working with them – they won't let you down! ",
    },
  },
  casaCerbului: {
    status: '12 January 2022',
    portofolioProjectLogo: images.casaCerbuluiLogo,
    projectName: '',
    projectType: 'Web Dev',
    projectIndividualName: 'Casa Cerbului',
    projectScreens: [],
    projectScreen: 'desktop',
    projectImage: images.portofolio.casaCerbului,
    projectPortofolioName: 'Casa Cerbului',
    projectNameStyles: { color: '#CAA987' },
    projectLogo: images.oasisLogo,
    projectLogoStyles: { width: 300, height: 'auto' },
    projectLogoTabletStyles: { width: 200, height: 'auto' },
    clients: ['Oasis Residence'],
    clientsIcons: [images.oasisLogo],
    team: ['Andrei Pop', 'Bogdan Tinta'],
    teamFullName: ['Andrei Pop', 'Bogdan Tinta'],
    teamImages: [images.AndreiPop, images.BogdanTinta],
    services: ['React', 'Adobe XD'],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Web Dev', 'UI/UX'],
    categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
    description:
      "Casa Cerbului is a charming cabin near Baia Mare, designed for adventurers and nature enthusiasts alike. Casa Cerbului's website was carefully crafted with one main goal—to authentically capture the cabin's true essence and character. ",
    aFewWords: `Casa Cerbului is a charming cabin near Baia Mare, designed for adventurers and nature enthusiasts alike. Casa Cerbului's website was carefully crafted with one main goal—to authentically capture the cabin's true essence and character. `,
    summaryResults: `Our approach to the project followed our usual process, where we carefully aligned ourselves with the client's needs, conducted thorough research, and then dove straight into the work. 
    Our main goal was to showcase the stunning visuals of the place while implementing effective SEO practices for the website's copy. As always, we used ReactJS with the react-router to create the various pages that appear on the website. 
    `,
    testimonials: '',
    industry: 'Travel',
    product: 'Website platform',
    review: { author: '', text: '' },
  },
  mindHub: {
    status: '12 January 2022',
    portofolioProjectLogo: images.mindhubLogo,
    projectName: '',
    projectType: 'Marketing',
    projectIndividualName: 'Mind Hub',
    projectScreens: [],
    projectScreen: 'desktop',
    projectImage: images.portofolio.mindHub,
    projectPortofolioName: 'Mind Hub',
    projectNameStyles: { color: '#CAA987' },
    projectLogo: images.oasisLogo,
    projectLogoStyles: { width: 300, height: 'auto' },
    projectLogoTabletStyles: { width: 200, height: 'auto' },
    clients: ['Oasis Residence'],
    clientsIcons: [images.oasisLogo],
    team: ['Sylvy I.', 'Rowena S.'],
    teamFullName: ['Sylvi Incze', 'Rowena Salajan'],
    teamImages: [images.Sylvi, images.Rowena],
    services: ['Social Media', 'Meta ADS', 'Offline marketing'],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Web Dev', 'UI/UX'],
    categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
    description:
      'MindHub is a coding school tailored for children in Baia Mare. They offer age-specific classes that guide kids in learning coding from the ground up. Children as young as 6 years old are welcomed to join and explore the world of coding.',
    aFewWords: `MindHub is a coding school tailored for children in Baia Mare. They offer age-specific classes that guide kids in learning coding from the ground up. Children as young as 6 years old are welcomed to join and explore the world of coding.`,
    summaryResults: `We initiated this project by focusing on Social Media, where we were responsible for generating content, graphic design, and crafting ADS for their social media platforms. As we became more accustomed to the project, we organized a collaborative giveaway with Nomorecoffeeplease. This involved inviting ten children to our hub, where they had the opportunity to learn coding and experience a day in the life of a programmer alongside our skilled programmers. Additionally, we implemented offline marketing strategies, including a campaign called "Open Doors," which welcomed anyone interested in code lessons for their children.`,
    testimonials: '',
    industry: 'Learning',
    product: 'Website platform',
    review: { author: '', text: '' },
  },
  dentaLike: {
    status: '12 January 2022',
    portofolioProjectLogo: images.portofolio.dentaLikeLogo,
    projectName: '',
    projectType: 'Web Dev',
    projectIndividualName: 'Dentalike',
    projectScreens: [],
    projectScreen: 'desktop',
    projectImage: images.portofolio.dentalike,
    projectPortofolioName: 'Dentalike',
    projectNameStyles: { color: '#CAA987' },
    projectLogo: images.oasisLogo,
    projectLogoStyles: { width: 300, height: 'auto' },
    projectLogoTabletStyles: { width: 200, height: 'auto' },
    clients: ['Dentalike'],
    clientsIcons: [images.oasisLogo],
    team: ['Tudor E.', 'Bogdan T.'],
    teamFullName: ['Tudor Eugeniu', 'Bogdan Tinta'],
    teamImages: [images.TudorEugeniu, images.BogdanTinta],
    services: ['React', 'Figma'],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Web Dev', 'UI/UX'],
    categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
    description:
      'DentaLike is a dental clinic from Baia Mare, formed by a team of highly qualified professionals from Moldova. ',
    aFewWords: `DentaLike is a dental clinic from Baia Mare, formed by a team of highly qualified professionals from Moldova. `,
    summaryResults: `One of the challenges we encountered was ensuring that the website was optimized for viewing on a range of devices, including desktops, laptops, and mobile devices. In addition to this, we had to make sure that the website was user-friendly and intuitive, providing visitors with easy access to the information they needed. 
    The key considerations was the integration with the clinic's existing management software, thus automating where possible. We also focused on optimizing the website's speed and performance, and ensuring that it would be easy to navigate for potential patients seeking dental care.
    `,
    testimonials: '',
    industry: 'Health',
    product: 'Website platform',
    review: { author: '', text: '' },
  },

  // izibac: {
  //   status: "12 January 2022",
  //   projectName: "iziBac",
  //   projectNameStyles: { color: "#32CD32" },
  //   projectLogo: images.izibacLogo,
  //   projectLogoStyles: { width: 250, height: "auto" },
  //   projectLogoTabletStyles: { width: 200, height: "auto" },
  //   clients: ["iziBac"],
  //   clientsIcons: [images.izibacLogo],
  //   team: ["Norbi", "Andrei"],
  //   teamImages: [images.Norbi, images.Stoica],
  //   services: ["React Native", "Adobe XD"],
  //   servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
  //   category: ["Web Dev", "UI/UX"],
  //   categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
  //   aFewWords: ``,
  //   summaryResults: ``,
  //   testimonials: ""
  // },
};

const projectsObjectRO = {
  bsr: {
    status: 'Mai 2022',
    projectName: '',
    projectIndividualName: 'BSR',
    projectScreens: images.projectScreens.bsr,
    projectScreen: 'desktop',
    projectType: 'Dezvoltare Web',
    projectPortofolioName: 'BSR',
    projectNameStyles: { color: '#696969' },
    projectLogo: images.bsrLogo,
    portofolioProjectLogo: images.portofolio.BSRlogo,
    projectLogoStyles: { width: 300, height: 'auto' },
    projectLogoTabletStyles: { width: 200, height: 'auto' },
    clients: ['BSR'],
    projectImage: images.portofolio.BSR,
    clientsIcons: [images.bsrLogo],
    team: ['Norbi', 'Marcel', 'Kinga', 'Tudor', 'Andrei', 'Daniel'],
    teamFullName: [
      'Norbi Postol',
      'Marcel Marc',
      'Kinga Boros',
      'Tudor Eugeniu',
      'Andrei Pop',
      'Daniel Laios',
    ],
    teamImages: [
      images.Norbi,
      images.Marcel,
      images.Kinga,
      images.TudorEugeniu,
      images.AndreiPop,
      images.DanielLaios,
    ],
    services: ['React'],
    servicesIcons: [images.portofolio.reactIcon],
    category: ['Dezvoltare Web'],
    categoryIcons: [images.portofolio.webDevIcon],
    description:
      'Materiality Web, o aplicație internă, este proiectul nostru cel mai important și a fost creat pentru un client de rețea de afaceri durabile și consultanță. Ei își propun să stabilească o lume în care toată lumea poate prospera pe o planetă sănătoasă.',
    aFewWords: `Materiality Web este o aplicație internă pe care am dezvoltat-o pentru unul dintre clienții noștri. Clientul este o organizație B2B care ajută la identificarea potențialelor riscuri sau probleme în orice afacere, indiferent de sectorul său. BSR va intervieva toți membrii echipei, va aduna date și, pe baza acestora, va elabora o listă de lucruri de îmbunătățit în orice afacere.`,
    summaryResults: `Pe baza proceselor clientului nostru din Excel, am dezvoltat Materiality, o platformă web care integrează mai multe puncte de date într-un grafic bazat pe timp. Acest grafic oferă un înregistrări istorică a modificărilor și permite utilizatorilor să identifice principalele subiecte pe care o afacere le urmărește. Utilizatorii pot, de asemenea, să evalueze importanța acestor subiecte folosind această platformă.
    Cea mai mare provocare întâlnită în timpul proiectului a fost incorporarea funcționalității graficului. Graficul este piesa centrală a aplicației, permițând utilizatorului să interacționeze cu datele, să le schimbe direct din interfață, să importe și să exporte fișiere Excel, să recupereze informații din instrumente externe și să efectueze diverse alte funcții.
    Abordarea noastră în dezvoltarea aplicației s-a concentrat pe crearea unei modalități prietenoase pentru utilizator de a interacționa cu date complexe, vizualizând tendințe și modele de-a lungul timpului. De asemenea, aplicația permite utilizatorilor să facă modificări în timp real și să le salveze pentru utilizarea ulterioară.
    `,
    testimonials: '',
    industry: 'Sustenabilitate Mondială',
    product: 'platformă web',
    review: {
      author: 'Phil Olarte',
      text: 'Andrei este un lider excelent și un cultivator al comunității de tineri dezvoltatori. Abordarea sa unică ajută la formarea unor dezvoltatori mai buni în timpul și chiar după absolvirea școlii. Sub conducerea lui, NoMoreCoffeePlease a înțeles ușor nevoile afacerii noastre și a dezvoltat cu succes Materiality Web, care este acum o parte vitală a modelului nostru de lucru. Fiecare membru al echipei a contribuit în mod semnificativ la succesul acestui proiect, iar comunicarea lor eficientă și orientarea lor către rezultate au fost impresionante.',
    },
  },
  chalkNotes: {
    status: 'În desfășurare...',
    projectName: '',
    projectScreen: 'mobil',
    projectType: 'Dezvoltare Mobilă',
    portofolioProjectLogo: images.portofolio.chalkNoteslogo,
    projectScreens: images.projectScreens.chalknotes,
    projectPortofolioName: 'ChalkNotes',
    projectIndividualName: 'ChalkNotes',
    projectImage: images.portofolio.chalkNotes,
    projectNameStyles: { color: '#3EBBF3' },
    projectLogo: images.portofolio.chalkNotesLogo,
    projectLogoStyles: { width: 180, height: 'auto' },
    projectLogoTabletStyles: { width: 81, height: 'auto' },
    clients: ['ChalkNotes'],
    clientsIcons: [images.portofolio.chalkNotesLogo],
    team: ['Denis', 'Dani Laios'],
    teamFullName: ['Denis Chisalita', 'Daniel Laios'],
    teamImages: [images.Denis, images.DanielLaios],
    services: ['React Native'],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Dezvoltare Mobilă'],
    categoryIcons: [images.portofolio.webDevIcon],
    description:
      'ChalkNotes este o start-up din New York care a dezvoltat o platformă concepută pentru a aduce împreună creatorii de conținut și consumatorii în căutarea a două aspecte cheie: comunități și realitate augmentată.',
    aFewWords: `ChalkNotes este o start-up din New York care își propune să conecteze oamenii mai bine, combinând online-ul și offline-ul și creând o experiență personalizată pentru membrii unei comunități. Puteți crea propria comunitate și să creați o experiență pentru ea. Am creat, de asemenea, o funcție de realitate augmentată pentru această aplicație. Fondatori: Phil Olarte, Ethan Meyers. Andrei Stoica a avut plăcerea să lucreze inițial cu Phil la acest start-up, și ulterior, a transmis munca echipei sale: Norbi Postol și Denis Chisalita.`,
    summaryResults: `Una dintre cele mai mari provocări cu care ne-am confruntat a fost să descoperim cum să facem ca funcția de realitate augmentată să funcționeze perfect în cadrul platformei. A trebuit să creăm, de asemenea, un sistem de plată ușor de utilizat pentru utilizatori, în timp ce rămâne sigur și de încredere. Un alt obstacol a fost crearea unui sistem de recompense care să motiveze utilizatorii să finalizeze experiențele și să se simtă mândri de ceea ce au realizat. Dar, o altă sarcină complexă cu care ne-am confruntat a fost construirea unei hărți ușor de navigat și de utilizat, în timp ce se calculează diferite poziții pe hartă și crearea unui algoritm pentru experiențele cu alegere multiplă.
    Soluția se bazează pe React-Native, o soluție care ne permite să oferim o experiență fără sudură atât pentru utilizatorii iOS, cât și pentru cei Android. De asemenea, am integrat mai multe funcții interesante, precum posibilitatea de a crea experiențe cu alegere multiplă pentru alții pe baza locațiilor de pe hartă. Și, desigur, funcția de realitate augmentată care le permite utilizatorilor să interacționeze cu obiecte virtuale în lumea reală, la fel ca în Pokemon Go.`,
    testimonials: `"Andrei este un lider excelent și un cultivator al comunității de tineri dezvoltatori. Abordarea sa unică ajută la formarea unor dezvoltatori mai buni în timpul și chiar după absolvirea școlii. Sub conducerea sa, NoMoreCoffeePlease a înțeles ușor nevoile afacerii noastre, a menținut o comunicare excelentă și a dezvoltat produse corecte din prima. "Este o echipă excelentă cu cunoștințe profunde." "I-am angajat pentru alte proiecte; munca lor nu dezamăgește niciodată, și nicio problemă nu este prea dificilă."`,
    industry: 'Social',
    product: 'platformă de aplicații',
    review: {
      author: 'Phil Olarte',
      text: `Andrei este un lider excelent și un cultivator al comunității de tineri dezvoltatori. Abordarea sa unică ajută la formarea unor dezvoltatori mai buni în timpul și chiar după absolvirea școlii. Sub conducerea sa, NoMoreCoffeePlease a înțeles ușor nevoile afacerii noastre, a menținut o comunicare excelentă și a dezvoltat produse corecte din prima. "Este o echipă excelentă cu cunoștințe profunde." "I-am angajat pentru alte proiecte; munca lor nu dezamăgește niciodată, și nicio problemă nu este prea dificilă."`,
    },
  },
  BookTrade: {
    status: 'În desfășurare...',
    projectName: 'BookTrade',
    projectIndividualName: 'BookTrade',
    projectType: 'Dezvoltare Mobilă',
    portofolioProjectLogo: images.portofolio.bookTradelogo,
    projectScreens: images.projectScreens.bookTrade,
    projectScreen: 'mobil',
    projectPortofolioName: 'BookTrade',
    projectImage: images.portofolio.bookTrade,
    projectNameStyles: { color: '#465280' },
    projectLogo: images.bookTradeLogo,
    projectLogoStyles: { width: 260, height: 'auto' },
    projectLogoTabletStyles: { width: 200, height: 'auto' },
    clients: ['NoMoreCoffeePlease'],
    clientsIcons: [images.nmcpLogo],
    team: ['Sylvi', 'Andrei', 'Catalin', 'Bogdan', 'Bogdan P.'],
    teamFullName: [
      'Sylvi Incze',
      'Andrei Pop',
      'Catalin Racz',
      'Bogdan Tinta',
      'Bogdan Pop',
    ],
    teamImages: [
      images.Sylvi,
      images.AndreiPop,
      images.Catalin,
      images.BogdanPop,
    ],
    services: ['React Native', 'Figma'],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Dezvoltare Mobilă', 'UI/UX'],
    categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
    description:
      'BookTrade este un proiect intern, concentrat pe conectarea persoanelor care împărtășesc o pasiune pentru lectură și apreciază preferințele literare ale celorlalți. Platforma oferă o interfață simplă și intuitivă care vă permite să faceți swipe și să faceți potrivire cu persoane care împărtășesc interese similare pentru cărți.',
    aFewWords: `BookTrade este un proiect intern, concentrat pe conectarea persoanelor care împărtășesc o pasiune pentru lectură și apreciază preferințele literare ale celorlalți. Platforma oferă o interfață simplă și intuitivă care vă permite să faceți swipe și să faceți potrivire cu persoane care împărtășesc interese similare pentru cărți.`,
    summaryResults: `Acest proiect a necesitat o abordare diferită față de proiectele noastre obișnuite. Am alocat resurse semnificative pentru dezvoltarea platformei. Am preluat designurile inițiale, managementul și alte riscuri, asigurându-ne că proiectul respectă standardele noastre ridicate. Abordarea noastră tehnică s-a concentrat pe crearea unei arhitecturi sigure, concepută pentru a minimiza riscul de eșec, o arhitectură care să reziste testului timpului, făcând platforma relevantă și funcțională pentru cel puțin cinci ani. 
    Această aplicație oferă o oportunitate excelentă de a stabili noi prietenii cu persoane care împărtășesc pasiunea pentru lectură. 
    `,
    testimonials: `"Aplicația BookTrade este o nouă metodă revoluționară de a crea conexiuni între persoane care împărtășesc aceleași interese și gusturi în cărți (și nu numai în cărți, deoarece cu siguranță au multe lucruri în comun)." "Cred că va crea multe prietenii."`,
    industry: 'Industria Cărții',
    product: 'platformă de aplicații',
    review: { author: '', text: '' },
  },
  dogScouts: {
    status: 'Decembrie 2020',
    projectName: 'Dog Scouts',
    projectScreen: 'desktop',
    projectType: 'Dezvoltare Web',
    projectPortofolioName: 'Dog Scouts',
    portofolioProjectLogo: images.portofolio.dogScoutslogo,
    projectImage: images.portofolio.dogScouts,
    projectIndividualName: 'Dog Scouts',
    projectScreens: images.projectScreens.dogScouts,
    projectNameStyles: { color: '#EDA751' },
    projectLogo: images.portofolio.dogScoutslogo,
    projectLogoStyles: { width: 200, height: 'auto' },
    projectLogoTabletStyles: { width: 89, height: 'auto' },
    clients: ['Dog Scouts'],
    clientsIcons: [images.portofolio.dogScoutsLogo],
    team: ['Andrei', 'Norbi'],
    teamFullName: ['Andrei Stoica', 'Norbi Postol'],
    teamImages: [images.Stoica, images.Norbi],
    services: ['React'],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Dezvoltare Web', 'UI/UX'],
    categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
    description:
      'Dog Scouts este un site web care prezintă evenimente disponibile pentru câini, evenimente care îi îndrumă pe proprietarii de câini către o comunitate plină de bucurie, jocuri și alți iubitori de câini.',
    aFewWords: `Dog Scouts este un site web care prezintă evenimente disponibile pentru câini, evenimente care îi îndrumă pe proprietarii de câini către o comunitate plină de bucurie, jocuri și alți iubitori de câini.`,
    summaryResults: `Dog Scouts este un site web unde puteți vedea evenimentele organizate pentru câinii dvs. Dog Scouts este o comunitate pentru iubitorii de câini și animale unde sunt organizate întâlniri pentru diferite activități cu câinii. Sunt activi în diferite orașe din România. Am creat un site web simplu unde oamenii pot vedea următoarele patru evenimente viitoare și se pot înscrie la newsletter. Ideea din spatele acestui site a fost de a prezenta evenimentele, dar și de a dezvolta ulterior conferințe video și experiențe personalizate pentru fiecare utilizator. Pentru noi, acest proiect a fost asumat pentru că am dorit să ajutăm comunitățile locale cu software-ul nostru, care este pasiunea noastră.`,
    testimonials: '',
    industry: 'Social',
    product: 'platformă de site web',
    review: { author: '', text: '' },
  },
  romanticStudio: {
    status: 'Septembrie 2022',
    projectName: '',
    projectScreen: 'desktop',
    projectType: 'Dezvoltare Web',
    projectPortofolioName: 'Romantic Studio Brașov',
    portofolioProjectLogo: images.portofolio.romanticStudiologo,
    projectImage: images.portofolio.romanticStudioApartment,
    projectIndividualName: 'Romantic Studio Brașov',
    projectScreens: images.projectScreens.brasov,
    projectNameStyles: { color: '#B83131' },
    projectLogo: images.portofolio.romanticStudioLogo,
    projectLogoStyles: { width: 330, height: 150 },
    projectLogoTabletStyles: { width: 200, height: 'auto' },
    clients: ['Romantic Studio Brașov'],
    clientsIcons: [images.portofolio.romanticStudioLogo],
    team: ['Kinga', 'Cata Racz', 'Dani Anca'],
    teamFullName: ['Kinga Boros', 'Catalin Racz', 'Dani Anca'],
    teamImages: [images.Kinga, images.Catalin, images.Dani],
    services: ['React Native', 'Adobe XD'],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Dezvoltare Web', 'UI/UX'],
    categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
    description:
      'Romantic Studio Brașov constă într-un site web care, pe lângă prezentarea unităților de închiriat pline de romantism pentru îndrăgostiți, vă permite să faceți o cerere de rezervare folosind un formular de contact și un calendar similar cu ceea ce vedeți pe alte platforme de rezervări.',
    aFewWords: `Romantic Studio Brașov este un loc unde doi îndrăgostiți își pot petrece timpul împreună fără să se mai îngrijoreze de nimic. De la servicii de mic dejun și buchete de trandafiri până la jacuzzi și vin spumant. Există diverse pachete pentru a satisface nevoile fiecăruia. Puteți alege una dintre oferte împreună cu alte servicii suplimentare și vă puteți rezerva zilele, iar restul va fi gestionat de echipa noastră.`,
    summaryResults: `Partea cea mai provocatoare a fost procesul de rezervare și calendarul. Am implementat un calendar de tip "AirBnB" unde puteți alege intervalul de zile pentru vizita dvs. O altă provocare a fost fluxul de rezervare, de la punctul de pornire până la confirmarea comenzii.`,
    testimonials: '',
    industry: 'Social',
    product: 'platformă de site web',
    review: { author: '', text: '' },
  },
  zem: {
    status: '11 Noiembrie 2020',
    projectName: '',
    portofolioProjectLogo: images.portofolio.zemlogo,
    projectType: 'Dezvoltare Web',
    projectIndividualName: 'ZEM',
    projectPortofolioName: 'ZEM',
    projectScreens: images.projectScreens.zem,
    projectImage: images.portofolio.zem,
    projectScreen: 'desktop',
    projectNameStyles: { color: '#000000' },
    projectLogo: images.zemLogo,
    projectLogoStyles: { width: 260, height: 'auto' },
    projectLogoTabletStyles: { width: 200, height: 'auto' },
    clients: ['ZEM'],
    clientsIcons: [images.zemLogo],
    teamFullName: ['Norbi Postol', 'Andrei Stoica'],
    team: ['Norbi', 'Andrei'],
    teamImages: [images.Norbi, images.Stoica],
    services: ['React', 'Adobe XD'],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Dezvoltare Web', 'UI/UX'],
    categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
    description:
      'Zem este un site de prezentare pentru o organizație neguvernamentală care avea două evenimente live în fiecare an. Aveau nevoie să prezinte conținut video și foto pentru studenții la medicină într-un mediu digital diferit și mai puternic decât social media.',
    aFewWords: `Acest proiect a fost realizat în colaborare cu organizația neguvernamentală ZEM, o comunitate pentru studenții la medicină. ZEM avea două evenimente live în fiecare an și aveau nevoie de o prezență digitală în afara canalelor de social media. Au apelat la noi pentru a le dezvolta și crea site-ul web, un site de prezentare în care am prezentat evenimentele și echipa din spatele lor. De asemenea, am inclus conținut video și foto de la evenimente.`,
    summaryResults: ``,
    testimonials: '',
    industry: 'Social',
    product: 'platformă de site web',
    review: { author: '', text: '' },
  },
  oasisResidence: {
    status: '12 Ianuarie 2022',
    portofolioProjectLogo: images.portofolio.oasislogo,
    projectName: '',
    projectType: 'Dezvoltare Web',
    projectIndividualName: 'Oasis Residence',
    projectScreens: images.projectScreens.oasis,
    projectScreen: 'desktop',
    projectImage: images.portofolio.oasisResidence,
    projectPortofolioName: 'Oasis Residence',
    projectNameStyles: { color: '#CAA987' },
    projectLogo: images.oasisLogo,
    projectLogoStyles: { width: 300, height: 'auto' },
    projectLogoTabletStyles: { width: 200, height: 'auto' },
    clients: ['Oasis Residence'],
    clientsIcons: [images.oasisLogo],
    team: ['Dani Anca', 'Cata Racz', 'Andrei Pop'],
    teamFullName: ['Dani Anca', 'Catalin Racz', 'Andrei Pop'],
    teamImages: [images.Dani, images.Catalin, images.AndreiPop],
    services: ['React', 'Adobe XD'],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Dezvoltare Web', 'UI/UX'],
    categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
    description:
      'Oasis Residence este o afacere de închirieri de apartamente care administrează mai multe proprietăți în Baia Mare. În plus, dețin și un apartament cu același nume situat în Baia Mare.',
    aFewWords: `Proiectul Oasis Residence este un site web de prezentare creat pentru un client. Este vorba despre un apartament din Baia Mare care poate fi rezervat pentru câte nopți dorește clientul. Pe lângă prezentarea apartamentului cu fotografii, videoclipuri și mărturii, site-ul web încorporează și funcționalități precum trimiterea de e-mailuri, rezervări și plăți în site, o pagină de administrare pentru sincronizarea manuală cu Booking și Airbnb și posibilitatea de a vă abona la un buletin informativ.`,
    summaryResults: `Proiectul în sine nu pare a fi o provocare la prima vedere, dar când analizăm mai în profunzime funcționalitățile și tot ceea ce se întâmplă în spatele scenei, putem înțelege că nu este chiar atât de simplu. Cele mai mari provocări au fost, probabil, să facem ca algoritmul de rezervare să funcționeze perfect (cu date doar pentru CheckIn sau CheckOut și zile deja rezervate) și introducerea metodei de plată Stripe pentru a finaliza rezervarea (aceasta include și trimiterea unei facturi către client după finalizarea rezervării).`,
    testimonials: '',
    industry: 'Social',
    product: 'platformă de site web',
    review: { author: '', text: '' },
  },
  nestInn: {
    status: 'În desfășurare...',
    portofolioProjectLogo: images.nestInnLogo,
    projectName: '',
    projectType: 'Marketing',
    projectIndividualName: 'NestInn',
    projectScreens: [],
    projectScreen: 'desktop',
    projectImage: images.portofolio.nestInn,
    projectPortofolioName: 'NestInn',
    projectNameStyles: { color: '#CAA987' },
    projectLogo: images.oasisLogo,
    projectLogoStyles: { width: 300, height: 'auto' },
    projectLogoTabletStyles: { width: 200, height: 'auto' },
    clients: ['Oasis Residence'],
    clientsIcons: [images.oasisLogo],
    team: ['Rowena S.', 'Andra E.', 'Sylvi I.'],
    teamFullName: ['Rowena Salajan', 'Andra Ene', 'Sylvi Incze'],
    teamImages: [images.Rowena, images.AndraElekes, images.Sylvi],
    services: [
      'Pagini de Social Media',
      'Marketing prin E-mail',
      'Strategie de Marketing',
      'Google ADS',
      'Meta ADS',
    ],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Marketing'],
    categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
    description:
      'Nest Inn este o marcă de apartamente-hotel din Cluj-Napoca, cu trei locații convenabile în oraș. Fiecare apartament este atent proiectat pentru a satisface nevoile călătorilor, inclusiv a celor în călătorii de afaceri.',
    aFewWords: `Nest Inn este o marcă de apartamente-hotel din Cluj-Napoca, cu trei locații convenabile în oraș. Fiecare apartament este atent proiectat pentru a satisface nevoile călătorilor, inclusiv a celor în călătorii de afaceri.`,
    summaryResults: `Având experiență anterioară în marketingul aparthotelurilor, am fost bine pregătiți să abordăm proiectul Nest Inn. Am inițiat proiectul de la zero, creând cu atenție identitatea brandului, promovându-l activ pe diverse platforme de socializare și lansând în mod constant campanii de marketing proaspete. Am fost captivați de proiect încă de la început. Reputația Cluj-Napoca pentru atmosfera sa animată ne ține mereu angrenați, așteptând cu nerăbdare cele mai recente dezvoltări. Suntem hotărâți să nu rămânem în urmă cu tendințele, evenimentele sau orice altceva. Cercetarea este cheia succesului nostru!`,
    testimonials: '',
    industry: 'Călătorii',
    product: 'Marketing',
    review: {
      author: 'Nicoleta Stoica',
      text: 'Colaborarea cu echipa de marketing NMCP este extrem de ușoară. Sunt foarte prietenoși și întotdeauna gata să ajute. Lucrez cu ei de la începutul Nest Inn și sunt foarte mulțumită. Au organizat chiar și un eveniment care a decurs foarte bine. De asemenea, gestionează paginile de socializare și marketingul prin e-mail, creând o strategie de marketing în fiecare lună. Recomand cu căldură să lucrați cu ei - nu vă vor dezamăgi!',
    },
  },
  casaCerbului: {
    status: '12 ianuarie 2022',
    portofolioProjectLogo: images.casaCerbuluiLogo,
    projectName: '',
    projectType: 'Web Dev',
    projectIndividualName: 'Casa Cerbului',
    projectScreens: [],
    projectScreen: 'desktop',
    projectImage: images.portofolio.casaCerbului,
    projectPortofolioName: 'Casa Cerbului',
    projectNameStyles: { color: '#CAA987' },
    projectLogo: images.oasisLogo,
    projectLogoStyles: { width: 300, height: 'auto' },
    projectLogoTabletStyles: { width: 200, height: 'auto' },
    clients: ['Oasis Residence'],
    clientsIcons: [images.oasisLogo],
    team: ['Andrei Pop', 'Bogdan Tinta'],
    teamFullName: ['Andrei Pop', 'Bogdan Tinta'],
    teamImages: [images.AndreiPop, images.BogdanTinta],
    services: ['React', 'Adobe XD'],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Web Dev', 'UI/UX'],
    categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
    description:
      'Casa Cerbului este o cabană fermecătoare situată în apropiere de Baia Mare, destinată atât aventurierilor, cât și iubitorilor de natură. Website-ul pentru Casa Cerbului a fost creat cu o singură dorință principală - să surprindă autentic esența și caracterul cabanei.',
    aFewWords: `Casa Cerbului este o cabană fermecătoare situată în apropiere de Baia Mare, destinată atât aventurierilor, cât și iubitorilor de natură. Website-ul pentru Casa Cerbului a fost creat cu o singură dorință principală - să surprindă autentic esența și caracterul cabanei.`,
    summaryResults: `Abordarea noastră la acest proiect a urmat procesul nostru obișnuit, în care ne-am aliniat cu atenție la nevoile clientului, am efectuat cercetări exhaustive și apoi ne-am pus imediat la treabă.
    Scopul nostru principal a fost să prezentăm vizual impresionant locul, implementând în același timp practici eficiente de SEO pentru conținutul website-ului. Ca întotdeauna, am utilizat ReactJS cu react-router pentru a crea diversele pagini care apar pe website.
    `,
    testimonials: '',
    industry: 'Călătorii',
    product: 'platformă web',
    review: { author: '', text: '' },
  },
  mindHub: {
    status: '12 ianuarie 2022',
    portofolioProjectLogo: images.mindhubLogo,
    projectName: '',
    projectType: 'Marketing',
    projectIndividualName: 'Mind Hub',
    projectScreens: [],
    projectScreen: 'desktop',
    projectImage: images.portofolio.mindHub,
    projectPortofolioName: 'Mind Hub',
    projectNameStyles: { color: '#CAA987' },
    projectLogo: images.oasisLogo,
    projectLogoStyles: { width: 300, height: 'auto' },
    projectLogoTabletStyles: { width: 200, height: 'auto' },
    clients: ['Oasis Residence'],
    clientsIcons: [images.oasisLogo],
    team: ['Sylvy I.', 'Rowena S.'],
    teamFullName: ['Sylvi Incze', 'Rowena Salajan'],
    teamImages: [images.Sylvi, images.Rowena],
    services: ['Social Media', 'Meta ADS', 'Marketing offline'],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Marketing'],
    categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
    description:
      'MindHub este o școală de programare specializată pentru copii din Baia Mare. Ei oferă cursuri specifice vârstei care îi ghidează pe copii să învețe programare de la zero. Copiii încă de la vârsta de 6 ani sunt bineveniți să se alăture și să exploreze lumea programării.',
    aFewWords: `MindHub este o școală de programare specializată pentru copii din Baia Mare. Ei oferă cursuri specifice vârstei care îi ghidează pe copii să învețe programare de la zero. Copiii încă de la vârsta de 6 ani sunt bineveniți să se alăture și să exploreze lumea programării.`,
    summaryResults: `Am inițiat acest proiect concentrându-ne pe Social Media, unde am fost responsabili pentru generarea de conținut, design grafic și crearea de ADS-uri pentru platformele lor de socializare. Pe măsură ce ne-am obișnuit mai bine cu proiectul, am organizat o campanie colaborativă de oferte cu Nomorecoffeeplease. Aceasta a implicat invitația a zece copii la sediul nostru, unde au avut oportunitatea să învețe programare și să experimenteze o zi din viața unui programator alături de programatorii noștri experimentați. În plus, am implementat strategii de marketing offline, inclusiv o campanie numită "Ușile Deschise", care a primit cu brațele deschise pe oricine era interesat de lecții de programare pentru copiii lor.`,
    testimonials: '',
    industry: 'Educație',
    product: 'platformă de marketing',
    review: { author: '', text: '' },
  },
  dentaLike: {
    status: '12 ianuarie 2022',
    portofolioProjectLogo: images.portofolio.dentaLikeLogo,
    projectName: '',
    projectType: 'Web Dev',
    projectIndividualName: 'Dentalike',
    projectScreens: [],
    projectScreen: 'desktop',
    projectImage: images.portofolio.dentalike,
    projectPortofolioName: 'Dentalike',
    projectNameStyles: { color: '#CAA987' },
    projectLogo: images.oasisLogo,
    projectLogoStyles: { width: 300, height: 'auto' },
    projectLogoTabletStyles: { width: 200, height: 'auto' },
    clients: ['Dentalike'],
    clientsIcons: [images.oasisLogo],
    team: ['Tudor E.', 'Bogdan T.'],
    teamFullName: ['Tudor Eugeniu', 'Bogdan Tinta'],
    teamImages: [images.TudorEugeniu, images.BogdanTinta],
    services: ['React', 'Figma'],
    servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
    category: ['Web Dev', 'UI/UX'],
    categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
    description:
      'DentaLike este o clinică stomatologică din Baia Mare, formată dintr-o echipă de profesioniști foarte calificați din Moldova.',
    aFewWords: `DentaLike este o clinică stomatologică din Baia Mare, formată dintr-o echipă de profesioniști foarte calificați din Moldova.`,
    summaryResults: `Una dintre provocările cu care ne-am confruntat a fost asigurarea optimizării site-ului pentru vizualizarea pe o gamă de dispozitive, inclusiv desktop-uri, laptop-uri și dispozitive mobile. În plus, a trebuit să ne asigurăm că site-ul este prietenos pentru utilizatori și intuitiv, oferind vizitatorilor acces ușor la informațiile de care aveau nevoie. 
    Una dintre considerațiile cheie a fost integrarea cu software-ul de management existent al clinicii, automatizând în măsura posibilă. Ne-am concentrat, de asemenea, pe optimizarea vitezei și performanței site-ului și pe asigurarea că acesta va fi ușor de navigat pentru potențialii pacienți care caută îngrijire dentară.
    `,
    testimonials: '',
    industry: 'Sănătate',
    product: 'platformă web',
    review: { author: '', text: '' },
  },
  // izibac: {
  //   status: "12 ianuarie 2022",
  //   projectName: "iziBac",
  //   projectNameStyles: { color: "#32CD32" },
  //   projectLogo: images.izibacLogo,
  //   projectLogoStyles: { width: 250, height: "auto" },
  //   projectLogoTabletStyles: { width: 200, height: "auto" },
  //   clients: ["iziBac"],
  //   clientsIcons: [images.izibacLogo],
  //   team: ["Norbi", "Andrei"],
  //   teamImages: [images.Norbi, images.Stoica],
  //   services: ["React Native", "Adobe XD"],
  //   servicesIcons: [images.portofolio.reactIcon, images.portofolio.xdIcon],
  //   category: ["Web Dev", "UI/UX"],
  //   categoryIcons: [images.portofolio.webDevIcon, images.portofolio.uiUXicon],
  //   aFewWords: ``,
  //   summaryResults: ``,
  //   testimonials: ""
  // }
};

const cardsEN = [
  {
    title: 'MOBILE DEVELOPMENT (Espresso)',
    id: 'mobile',
    description: `We use React-Native Technology for your mobile app.
    This solution comes with lots of advantages, such as:
    a team that is flexible between mobile and web, IOS and Android, in one place.
    And there are almost no cons. It has great support from the community,
    and we didn't encounter anything that we couldn't do with it.
    Below are some examples of the apps that we've created.
    Click on them to learn more, or click "See Projects" to see all of them.`,
    primaryColor: '#EDA751',
    mainImage: images.app_development,
    projects: [
      { image: images.chalkNotesLogo, link: 'ChalkNotes' },
      { image: images.bookTradeLogo, link: 'BookTrade' },
      { image: images.izibacLogo, link: '' },
    ],
  },
  {
    title: 'WEB DEVELOPMENT (Latte Macchiato)',
    id: 'web',
    description: `In web development, we use React, 
    as its popularity is growing as a solution that we can safely say is safe. 
    Safe from: a bayarnd team, from no support, from dying out. 
    Why? Because we're in the JavaScript ecosystem, and here, things are just starting to ramp up. 
    See the projects that we take pride in below.`,
    primaryColor: '#72BEB7',
    mainImage: images.web_development,
    projects: [
      { image: images.portofolio.dentaLikeLogo, link: 'Dentalike' },
      { image: images.romanticStudioLogo, link: 'Romantic%20Studio%20Brasov' },
      { image: images.bsrLogo, link: 'BSR' },
    ],
  },
  {
    title: 'UI/UX DESIGN (Capuccino)',
    id: 'design',
    description: `We are developing products from scratch, 
    and that means designing, strategizing, 
    and coming up with the best user flow that we can make. 
    Of course, you can come on your own, 
    but we still offer support if you find yourself in need of it.`,
    primaryColor: '#FF91AB',
    mainImage: images.ui_ux,
    projects: [
      { image: images.romanticStudioLogo, link: 'Romantic%20Studio%20Brasov' },
      { image: images.oasisLogo, link: 'Oasis%20Residence' },
      { image: images.casaCerbuluiLogo, link: 'Casa%20Cerbului' },
    ],
  },
  {
    title: 'MARKETING (Flat White)',
    id: 'marketing',
    description: `Initially, marketing was created with the intention of doing it for us. 
    Then the first customer appeared who needed our services, and probably we did a good job. 
    As a result, we offer full-service marketing. 
    We want to collaborate and partner with brands that push society forward. 
    Let's raise your brand together.`,
    primaryColor: '#FFDC9C',
    mainImage: images.marketing,
    projects: [
      { image: images.romanticStudioLogo, link: 'Romantic%20Studio%20Brasov' },
      { image: images.oasisLogo, link: 'Oasis%20Residence' },
      { image: images.nestInnLogo, link: 'NestInn' },
    ],
  },
];

const cardsRO = [
  {
    title: 'DEZVOLTARE MOBILĂ (Espresso)',
    id: 'mobile',
    description:
      'Utilizăm tehnologia React-Native pentru aplicația dvs. mobilă. Această soluție vine cu multe avantaje, cum ar fi: o echipă flexibilă între mobil și web, IOS și Android, într-un singur loc. Și aproape că nu are dezavantaje. Are un sprijin excelent din partea comunității, și nu ne-am întâlnit cu nimic ce nu am putea face cu ea. Mai jos sunt câteva exemple de aplicații pe care le-am creat. Faceți clic pe ele pentru a afla mai multe, sau faceți clic pe "Vezi Proiecte" pentru a le vedea pe toate.',
    primaryColor: '#EDA751',
    mainImage: images.app_development,
    projects: [
      { image: images.chalkNotesLogo, link: 'ChalkNotes' },
      { image: images.bookTradeLogo, link: 'BookTrade' },
      { image: images.izibacLogo, link: '' },
    ],
  },
  {
    title: 'DEZVOLTARE WEB (Latte Macchiato)',
    id: 'web',
    description:
      'În dezvoltarea web, utilizăm React, deoarece popularitatea sa este în creștere ca o soluție pe care putem spune cu siguranță că este sigură. Sigură de: o echipă bayarnd, de lipsa suportului, de dispariție. De ce? Pentru că suntem în ecosistemul JavaScript, și aici, lucrurile abia încep să se intensifice. Vedeți proiectele de care suntem mândri mai jos.',
    primaryColor: '#72BEB7',
    mainImage: images.web_development,
    projects: [
      { image: images.portofolio.dentaLikeLogo, link: 'Dentalike' },
      {
        image: images.romanticStudioLogo,
        link: 'Romantic%20Studio%20Brasov',
      },
      { image: images.bsrLogo, link: 'BSR' },
    ],
  },
  {
    title: 'DESIGN UI/UX (Cappuccino)',
    id: 'design',
    description:
      'Dezvoltăm produse de la zero, și asta înseamnă proiectare, strategizare, și conceperea celui mai bun flux de utilizare pe care îl putem realiza. Desigur, puteți veni pe cont propriu, dar tot oferim suport dacă vă aflați în nevoie de acesta.',
    primaryColor: '#FF91AB',
    mainImage: images.ui_ux,
    projects: [
      {
        image: images.romanticStudioLogo,
        link: 'Romantic%20Studio%20Brasov',
      },
      { image: images.oasisLogo, link: 'Oasis%20Residence' },
      { image: images.casaCerbuluiLogo, link: 'Casa%20Cerbului' },
    ],
  },
  {
    title: 'MARKETING (Flat White)',
    id: 'marketing',
    description:
      'Inițial, marketingul a fost creat cu intenția de a-l face pentru noi. Apoi a apărut primul client care avea nevoie de serviciile noastre, și probabil că am făcut o treabă bună. Ca rezultat, oferim servicii complete de marketing. Dorim să colaborăm și să ne parteneriem cu mărci care împing societatea înainte. Să ridicăm împreună marca dvs.',
    primaryColor: '#FFDC9C',
    mainImage: images.marketing,
    projects: [
      {
        image: images.romanticStudioLogo,
        link: 'Romantic%20Studio%20Brasov',
      },
      { image: images.oasisLogo, link: 'Oasis%20Residence' },
      { image: images.nestInnLogo, link: 'NestInn' },
    ],
  },
];

export const cards = navigator.language === 'ro' ? cardsRO : cardsEN;

export const projectsObject =
  navigator.language === 'ro' ? projectsObjectRO : projectsObjectEN;

export const peopleObjects = [
  {
    name: 'Andrei Stoica',
    fct: 'CEO',
    image: images.Stoica,
    imageArt: images.StoicaArt,
  },
  {
    name: 'Andreea',
    fct: 'HR',
    image: images.Andreea,
    imageArt: images.AndreeaArt,
  },
  {
    name: 'Nico',
    fct: 'CFO',
    image: images.NicoStoica,
    imageArt: images.NicoStoicaArt,
  },
  {
    name: 'Norbi Postol',
    fct: 'CTO',
    image: images.Norbi,
    imageArt: images.NorbiArt,
  },
  // {
  //   name: "Adrian Dobrican",
  //   fct: "UI/UX Designer",
  //   image: images.Adi,
  //   imageArt: images.AdiArt,
  // },
  // {
  //   name: "Sylvi",
  //   fct: "UI/UX Designer",
  //   image: images.Sylvi,
  //   imageArt: images.SylviArt,
  // },
  // {
  //   name: "Andra Elekes",
  //   fct: "Marketeer",
  //   image: images.AndraElekes,
  //   imageArt: images.AndraElekesArt,
  // },
  // {
  //   name: "Kinga Boros",
  //   fct: "Web Developer",
  //   image: images.Kinga,
  //   imageArt: images.KingaArt,
  // },
  // {
  //   name: "Dani Anca",
  //   fct: "Web Developer",
  //   image: images.Dani,
  //   imageArt: images.DaniArt,
  // },
  {
    name: 'Marcel',
    fct: 'Mobile Developer',
    image: images.Marcel,
    imageArt: images.MarcelArt,
  },
  {
    name: 'Denis',
    fct: 'Mobile Developer',
    image: images.Denis,
    imageArt: images.DenisArt,
  },

  {
    name: 'Catalin',
    fct: 'Mobile Developer',
    image: images.Catalin,
    imageArt: images.CatalinArt,
  },
  {
    name: 'Daniel',
    fct: 'Mobile Developer',
    image: images.DanielLaios,
    imageArt: images.DanielLaiosArt,
  },
  // {
  //   name: "Bogdan",
  //   fct: "Mobile Developer",
  //   image: images.BogdanTinta,
  //   imageArt: images.BogdanTintaArt,
  // },
  // {
  //   name: "Alex Vitalariu",
  //   fct: "Web Developer",
  //   image: images.AlexVitalariu,
  //   imageArt: images.AlexVitalariuArt,
  // },
  {
    name: 'Andrei',
    fct: 'Mobile Developer',
    image: images.AndreiPop,
    imageArt: images.AndreiPopArt,
  },
  {
    name: 'Tudor',
    fct: 'React Developer',
    image: images.TudorEugeniu,
    imageArt: images.TudorEugeniuArt,
  },
  {
    name: 'Rowena',
    fct: 'Marketeer',
    image: images.Rowena,
    imageArt: images.Rowena,
  },
  {
    name: 'Bianca',
    fct: 'Sales',
    image: images.BiancaBaciu,
    imageArt: images.BiancaBaciu,
  },
  {
    name: 'Bogdan',
    fct: 'Mobile Developer',
    image: images.BogdanPop,
    imageArt: images.BogdanPop,
  },
  {
    name: 'Kinga',
    fct: 'React Developer',
    image: images.Kinga,
    imageArt: images.KingaArt,
  },
];

export const blogWriters = [
  {
    name: 'Andrei Stoica',
    function: 'CEO and Senior Developer',
    image: images.Stoica,
    imageArt: images.StoicaArt,
  },
  {
    name: 'Andra Elekes',
    function: 'Marketeer',
    image: images.AndraElekes,
    imageArt: images.AndraElekesArt,
  },
  {
    name: 'Andrei Pop',
    function: 'React/React-Native Developer',
    image: images.AndreiPop,
    imageArt: images.AndreiPopArt,
  },
  {
    name: 'Tudor Eugeniu',
    function: 'React Developer',
    image: images.TudorEugeniu,
    imageArt: images.TudorEugeniuArt,
  },
];

export const googleMapCenterPos = {
  lat: 47.650189183544924,
  lng: 23.582596762536777,
};
