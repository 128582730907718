import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAU_dDbD-AQzHUdmKj4SM01jEpf3TNTu60",
  authDomain: "festivalnmcp.firebaseapp.com",
  projectId: "festivalnmcp",
  storageBucket: "festivalnmcp.appspot.com",
  messagingSenderId: "842070858542",
  appId: "1:842070858542:web:2d7fb962d6b6cc1b6db8bf",
  measurementId: "G-YJD3TXL8K7",
};

const app = initializeApp(firebaseConfig);

export default app;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
