import React, { useState, useEffect, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import images from "../../../core/constants/images";
import Footer from "../../components/Footer/Footer";
import PeopleGallery from "../../components/Homepage/PeopleGallery";
import MainButton from "../../components/MainButton";
import { peopleObjects } from "../../../core/constants/data";
import styles from "./style.module.scss";
import strings from "../../../core/constants/strings";
import "./style.scss";
import PortofolioPart from "../../components/Homepage/PortofolioPart";
import ExpectationsLine from "../../components/Homepage/ExpectationsLine";
import ScrollUp from "../../components/ScrollUp/ScrollUp";
import routes from "../../../core/navigation/routes";
import NewPeopleGallery from "../../components/HomepageContents/newPeopleGallery";
import NewWhyUsPage from "../../components/HomepageContents/newWhyUs";
import { HashLink } from "react-router-hash-link";

interface GalleryType {
  image: any | undefined;
  name: string | undefined;
  fct: string | undefined;
}

const Homepage = () => {
  const navigate = useNavigate();
  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const [effect, setEffect] = useState<boolean>(false);
  const [personsObject, setPersonsObject] = useState<GalleryType[]>([
    {
      image: peopleObjects[0].image,
      name: peopleObjects[0].name,
      fct: peopleObjects[0].fct,
    },
    {
      image: peopleObjects[1].image,
      name: peopleObjects[1].name,
      fct: peopleObjects[1].fct,
    },
    {
      image: peopleObjects[2].image,
      name: peopleObjects[2].name,
      fct: peopleObjects[2].fct,
    },
    {
      image: peopleObjects[3].image,
      name: peopleObjects[3].name,
      fct: peopleObjects[3].fct,
    },
    {
      image: peopleObjects[4].image,
      name: peopleObjects[4].name,
      fct: peopleObjects[4].fct,
    },
  ]);

  const modifyImage = useCallback((index: number) => {
    setEffect(true);
    setTimeout(() => {
      setEffect(false);
      setPhotoIndex(index);
    }, 500);
  }, []);

  const changePersonsInObject = useCallback(() => {
    setPersonsObject([]);
    for (let i = photoIndex; i < photoIndex + 5; i++)
      if (i < peopleObjects.length)
        setPersonsObject((prevState) => {
          let tempObject = prevState;
          tempObject.push({
            image: peopleObjects[i].image,
            name: peopleObjects[i].name,
            fct: peopleObjects[i].fct,
          });
          return tempObject;
        });
      else
        setPersonsObject((prevState) => {
          let tempObject = prevState;
          tempObject.push({
            image: undefined,
            name: undefined,
            fct: undefined,
          });
          return tempObject;
        });
  }, [photoIndex]);

  useEffect(() => {
    changePersonsInObject();
  }, [photoIndex]);

  return (
    <div className={styles.container}>
      <div className={styles.paddingLeftRight}>
        <img
          src={images.topHomepageBannerTablet}
          className={styles.topHomepageBanner}
        ></img>

        <div className={styles.topContainer}>
          <p className={styles.titleContainer}>{strings.homepage.someTitle}</p>
          <p className={styles.aboutUsContainer}>
            {strings.homepage.wordsAboutUs}
          </p>
        </div>

        <div>
          <img className={styles.letterA} src={images.letterA} />

          <img className={styles.letterW} src={images.letterW} />

          <img className={styles.letterM} src={images.letterM} />

          <img className={styles.letterU} src={images.letterU} />
        </div>
        <img src={images.nmcpLogoTablet} className={styles.nmcpLogo} />
      </div>
      <NewWhyUsPage />
      <div
        style={{
          backgroundImage: `url(${images.servicesCirclesLinesTablet})`,
        }}
      >
        <img src={images.services} className={styles.servicesImage} />
      </div>
      <Col className={styles.servicesContainer}>
        <div
          className={styles.servicesBannerContainer}
          style={{
            backgroundImage: `url(${images.servicesBannerTablet})`,
          }}
        >
          <div className={styles.servicesListContainer}>
            <div>
              <HashLink smooth to={"/services#mobile"}>
                <img
                  src={images.mobileDev}
                  className={styles.servicesFirstImage}
                />
              </HashLink>
              <HashLink smooth to={"/services#web"}>
                <img
                  src={images.webDev}
                  className={styles.servicesFirstImage}
                />
              </HashLink>
            </div>
            <div className={styles.servicesListSecond}>
              <HashLink smooth to={"/services#design"}>
                <img src={images.uiUX} className={styles.servicesSecondImage} />{" "}
              </HashLink>
              <HashLink smooth to={"/services#marketing"}>
                <img
                  src={images.marketingHome}
                  className={styles.servicesSecondImage}
                />
              </HashLink>
            </div>
          </div>
        </div>
      </Col>
      <a className={styles.mainButton} href="/services/">
        <MainButton
          primaryColor={"#78CFC0"}
          title={"VIEW MORE"}
          onClick={() => {}}
        />
      </a>
      <div
        style={{
          backgroundImage: `url(${images.portofolioCirclesLinesMobile})`,
          contain: "content",
        }}
      >
        <img src={images.portofolioLogo} className={styles.portofolioLogo} />
      </div>

      <PortofolioPart />

      <a className={styles.mainButtonPortofolio} href="/ourwork/">
        <MainButton
          primaryColor={"#EDA751"}
          title={"VIEW MORE"}
          onClick={() => {}}
        />
      </a>
      <div className={styles.whatToExpect}>
        <img src={images.whattoexpect} className={styles.whatToExpectImage} />
      </div>

      <ExpectationsLine />
      {/* <OldPeopleGallery /> */}
      <NewPeopleGallery />
      <ScrollUp />
      <Footer />
      <ScrollUp />
    </div>
  );
};
export default Homepage;
