import React from "react";
import styles from "../../screens/Homepage/style.module.scss";
import images from "../../../core/constants/images";

const PortofolioPart = () => {
  return (
    <div
      style={{ backgroundImage: `url(${images.portofolioBannerTablet})` }}
      className={styles.portofolioBanner}
    >
      <div className={styles.honeycombContainer}>
        <div className={styles.firstHoneycombRow}>
          <div className={styles.diamondShape}>
            <img src={images.dogScoutsLogo} className={styles.honeycombImage} />
          </div>
        </div>
        <div className={styles.secondHoneycombRow}>
          <div className={styles.diamondShape}>
            <img
              src={images.chalkNotesLogo}
              className={styles.honeycombImage}
            />
          </div>
          <div className={styles.diamondShape}>
            <img
              src={images.romanticStudioLogo}
              className={styles.honeycombImage}
            />
          </div>
        </div>
        <div className={styles.thirdHoneycombRow}>
          <div className={styles.diamondShape}>
            <img src={images.izibacLogo} className={styles.honeycombImage} />
          </div>
          <div className={styles.diamondShape}>
            <img
              src={images.casaCerbuluiLogo}
              className={styles.honeycombImage}
              style={{ height: "100%" }}
            />
          </div>
          <div className={styles.diamondShape}>
            <img src={images.zemLogo} className={styles.honeycombImage} />
          </div>
        </div>
        <div className={styles.secondHoneycombRow}>
          <div className={styles.diamondShape}>
            <img
              src={images.oasisLogo}
              style={{ height: "14%" }}
              className={styles.honeycombImage}
            />
          </div>
          <div className={styles.diamondShape}>
            <img src={images.bookTradeLogo} className={styles.honeycombImage} />
          </div>
        </div>
        <div className={styles.lastHoneycombRow}>
          <div className={styles.diamondShape}>
            <img
              src={images.bsrLogo}
              className={styles.honeycombImage}
              style={{ height: "30%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortofolioPart;
