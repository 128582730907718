import React from "react";
import styles from "./style.module.scss";
import images from "../../../core/constants/images";

interface ImageProps {
  src: string;
  hoverSrc: string;
  classImages: string;
  altImages: string;
}

const onHover = (img: HTMLImageElement, src: string, hoverSrc: string) => {
  img.src = hoverSrc;
  img.dataset.hoverSrc = src;
};

///

const Image: React.FC<ImageProps> = ({
  src,
  hoverSrc,
  classImages,
  altImages,
}) => {
  const onImageHover = (e: React.MouseEvent<HTMLImageElement>) => {
    const img = e.currentTarget;
    onHover(img, src, hoverSrc);
  };
  const onImageOut = (e: React.MouseEvent<HTMLImageElement>) => {
    const img = e.currentTarget;
    img.src = img.dataset.hoverSrc || src;
    img.removeAttribute("data-hover-src");
  };

  return (
    <img
      src={src}
      alt={altImages}
      onMouseOver={onImageHover}
      onMouseOut={onImageOut}
      className={classImages}
    />
  );
};

///

const OurPartnersImages: React.FC = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.firstThreePhotos}>
          <Image
            src={images.portofolio.ness}
            hoverSrc={images.portofolio.nessColor}
            classImages={styles.nessImage}
            altImages=""
          />
          <Image
            src={images.portofolio.monlet}
            hoverSrc={images.portofolio.monletColor}
            classImages={styles.moonletImage}
            altImages=""
          />
          <Image
            src={images.portofolio.ausy}
            hoverSrc={images.portofolio.ausyColor}
            classImages={styles.image}
            altImages=""
          />
        </div>
        <div className={styles.lastThreePhotos}>
          <Image
            src={images.portofolio.pentalog}
            hoverSrc={images.portofolio.pentalogColor}
            classImages={styles.pentalogImage}
            altImages=""
          />

          <Image
            src={images.portofolio.odeen}
            hoverSrc={images.portofolio.odeenColor}
            classImages={styles.odeenImage}
            altImages=""
          />
          <Image
            src={images.portofolio.izibac}
            hoverSrc={images.portofolio.izibacColor}
            classImages={styles.izibacImage}
            altImages=""
          />
        </div>
      </div>
      <Image
        src={images.nestInnLogo}
        hoverSrc={images.nestInnLogo}
        classImages={styles.nestInnImage}
        altImages=""
      />
    </>
  );
};
export default OurPartnersImages;
