import React, { useState } from "react";
import styles from "./FormNmcp.module.scss";
import MainButton from "../MainButton";
import images from "../../../core/constants/images";
import strings from "../../../core/constants/strings";
import { optionsArray } from "../../../core/constants/arrays";
import { sendEmailToNMCP } from "../../../functions/firebase";
import { colors } from "../../../core/theme/colors";

function FormNmcp() {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>(
    `${strings.homepage.selectedDefault}`
  );
  const [termsCheck, setTermsCheck] = useState<boolean>(false);
  const [errors, setErrors] = useState<number[]>([]);
  const [confirmModalVisible, setConfirmModalVisible] =
    useState<boolean>(false);
  const defVla = strings.homepage.selectedDefault;
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const phoneNumberRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

  const selectedOptionHandler = (value: string) => {
    setSelected(value);
    setIsActive(false);
    setSubject(value);
  };

  const sendMailHandler = () => {
    if (
      name.length === 0 ||
      !emailRegex.test(email) ||
      !phoneNumberRegex.test(phoneNumber) ||
      selected === strings.homepage.selectedDefault ||
      !termsCheck
    ) {
      errorHandler(name.length === 0, 1);
      errorHandler(!emailRegex.test(email), 2);
      errorHandler(!phoneNumberRegex.test(phoneNumber), 3);
      errorHandler(selected === strings.homepage.selectedDefault, 4);
      errorHandler(!termsCheck, 5);
    } else {
      sendEmailToNMCP(message, email, name, phoneNumber, subject);
      setName("");
      setEmail("");
      setPhoneNumber("");
      setMessage("");
      setSelected(`${strings.homepage.selectedDefault}`);
      setTermsCheck(false);
      setConfirmModalVisible(true);
    }
  };

  // if action true means add and if false delete
  const errorHandler = (action: boolean, index: number) => {
    const errorIndex = errors.findIndex((item) => item === index);
    if (action && !errors.includes(index)) {
      setErrors((prevState) => [...prevState, index]);
    } else if (!action && errors.includes(index) && errorIndex !== -1) {
      setErrors((prevState) => [...prevState.filter((item) => item !== index)]);
    }
  };

  return (
    <div>
      <form>
        <div className={styles.container}>
          {confirmModalVisible ? (
            <div className={styles.modalBackDrop}>
              <div className={styles.modalContainer}>
                <span className={styles.modalTitle}>
                  We've received your email, we will contact you as soon as
                  possible.
                </span>
                <MainButton
                  title="CLOSE"
                  onClick={() => setConfirmModalVisible(false)}
                  primaryColor={colors.orange}
                />
              </div>
            </div>
          ) : null}
          <div className={styles.inputContainer}>
            <input
              value={name}
              type="text"
              placeholder={strings.homepage.yourNamePlace}
              onChange={(e) => setName(e.target.value)}
              onBlur={() => errorHandler(name.length === 0, 1)}
              onFocus={() => errorHandler(false, 1)}
              className={errors.includes(1) ? styles.errorInput : ""}
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              value={email}
              type="email"
              placeholder={strings.homepage.yourEmailPlace}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={() => errorHandler(!emailRegex.test(email), 2)}
              onFocus={() => errorHandler(false, 2)}
              className={errors.includes(2) ? styles.errorInput : ""}
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              value={phoneNumber}
              type="tel"
              placeholder={strings.homepage.yourPhonePlace}
              onChange={(e) => setPhoneNumber(e.target.value)}
              onBlur={() =>
                errorHandler(!phoneNumberRegex.test(phoneNumber), 3)
              }
              onFocus={() => errorHandler(false, 3)}
              className={errors.includes(3) ? styles.errorInput : ""}
            />
          </div>
          <div className={styles.selectContainer}>
            <div
              className={`${styles.selectElement} ${
                !isActive ? null : styles.active
              } ${selected === defVla ? null : styles.isSelected} ${
                errors.includes(4) ? styles.errorInput : ""
              }`}
              onClick={() => {
                setIsActive((prevState) => !prevState);
                errorHandler(false, 4);
              }}
            >
              <div>{selected}</div>

              <img src={images.dropdownArrow} alt="dropdownArrow" />
            </div>
            <div
              className={`${styles.optionsContainer} ${
                !isActive ? null : styles.active
              }`}
            >
              {optionsArray.map((option, index) => {
                return (
                  <div className={styles.optionElement} key={index}>
                    <input
                      type="radio"
                      id={option.id}
                      value={option.value}
                      name="category"
                    />
                    <label
                      htmlFor={option.id}
                      onClick={() => selectedOptionHandler(`${option.value}`)}
                    >
                      {option.value}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.inputContainer}>
            <textarea
              value={message}
              placeholder={strings.homepage.yourMessagePlace}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className={styles.radioContainer}>
            <input
              type="checkbox"
              onChange={() => {
                setTermsCheck((prevState) => !prevState);
                errorHandler(false, 5);
              }}
              className={errors.includes(5) ? styles.errorCheck : ""}
              checked={termsCheck}
            />
            <p className={errors.includes(5) ? styles.errorInput : ""}>
              {strings.homepage.iAgree}
              <span onClick={() => (window.location.href = "/Terms")}>
                {strings.homepage.termsAndConditions}
              </span>
            </p>
          </div>
          <div className={styles.buttonContainer}>
            <MainButton
              primaryColor={"#EDA751"}
              title={strings.contactUs.send}
              onClick={sendMailHandler}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default FormNmcp;
