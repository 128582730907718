import React, { useCallback, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Portofolio from "./desktop/screens/Portofolio/Portofolio";
import Navigation from "./core/navigation/Navigation";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import ReactGA from "react-ga4";

ReactGA.initialize("G-YJD3TXL8K7");

function App() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname.replaceAll("/", ""),
    });
  }, []);
  return <Navigation />;
}

export default App;
