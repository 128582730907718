import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import MainButton from "../MainButton";

interface ProjectCardProps {
  title: string;
  description: string;
  imagesProject: string;
  altImages: string;
  screenType: string;
  portofolioName: string;
}

const ProjectCard = ({
  title,
  description,
  imagesProject,
  altImages,
  screenType,
  portofolioName,
}: ProjectCardProps) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{title}</h2>
      <img
        src={imagesProject}
        alt={altImages}
        className={`${
          screenType === "desktop" ? styles.imagesDesktop : styles.imagesMobile
        }`}
      />
      <div className={styles.textContainer}>
        <h3 className={styles.description}>{description}</h3>
        <a className={styles.marginButton} href={`/ourwork/${portofolioName}`}>
          <MainButton
            primaryColor={"#EDA751"}
            title={"SEE MORE"}
            onClick={() => {}}
          />
        </a>
      </div>
    </div>
  );
};
export default ProjectCard;
