import React, { useState } from "react";
import styles from "./style.module.scss";
import images from "../../../core/constants/images";

const ScrollUp = () => {
  const [isScrollButtonActive, setScrollButtonActive] = useState(false);

  window.onscroll = function () {
    if (window.pageYOffset < 1600) {
      setScrollButtonActive(false);
    } else {
      setScrollButtonActive(true);
    }
  };

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  return (
    <div className={styles.scrollTopButtonContainer}>
      {/* <div
        className={
          isScrollButtonActive
            ? styles.goTopButton
            : `${styles.goTopButton} ${styles.hideThis}`
        }
      >
        <div className={styles.upCircleButton} onClick={topFunction}>
          <span className={styles.goTopImg}></span>
        </div>
      </div> */}

      <div
        className={
          isScrollButtonActive
            ? styles.topBtnGroup
            : `${styles.topBtnGroup} ${styles.hideThis}`
        }
        onClick={topFunction}
      >
        <div className={styles.upCircleButton}>
          <span className={styles.goTopImg}></span>
        </div>
        <div className={styles.goTopButton}></div>
      </div>
    </div>
  );
};
export default ScrollUp;
