import $ from "jquery";

$(document).ready(function () {
  $("#successDiv").hide();
  $("#errorDiv").hide();

  $("form").submit(function (e) {
    e.preventDefault();
    const data = $(this).serialize();

    $.ajax({
      url: "/contact.php",
      type: "post",
      data: data,
      success: (resp) => {
        if (resp === "true") {
          $("#errorDiv").hide();
          $("#successDiv").show();
          setTimeout(() => {
            $("#successDiv").hide();
          }, 3000);
        } else {
          $("#successDiv").hide();
          $("#errorDiv").show();
          setTimeout(() => {
            $("#errorDiv").hide();
          }, 3000);
        }
      },
      error: (e) => {
        console.log("error");
      },
    });
  });
});
