import React from 'react';
import { HashLink } from 'react-router-hash-link';

import styles from './style.module.scss';
import MainButton from '../../components/MainButton';

interface IProps {
  primaryColor: string;
  title: string;
  description: string;
  mainImage: string;
  projects: Array<{ image: string; link: string }>;
}

const ServicesCard = ({
  primaryColor,
  title,
  description,
  mainImage,
  projects,
}: IProps) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.verticalLine}
        style={{ backgroundColor: primaryColor }}
      />
      <div className={styles.card} style={{ borderColor: primaryColor }}>
        <img src={mainImage} className={styles.mainImg} />
        <div style={{ marginRight: 32, marginLeft: 86 }}>
          <h3
            style={{ color: primaryColor }}
            className={styles.servicesCardTitle}
          >
            {title}
          </h3>
          <p className={styles.servicesCardDescription}>{description}</p>
          <div className={styles.mainRow}>
            <div className={styles.projectsContainer}>
              {projects.map((project: { image: string; link: string }) => (
                <HashLink
                  to={`/ourwork/${project.link}`}
                  className={styles.projectImgContainer}
                >
                  <img className={styles.projectsImg} src={project.image} />
                </HashLink>
              ))}
            </div>
            <div className={styles.button}>
              <MainButton
                title={'SEE PROJECTS'}
                onClick={() => (window.location.href = '/ourwork')}
                primaryColor={primaryColor}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesCard;
