import React from "react";
import ScreenHeader from "../../components/ScreenHeader/ScreenHeader";
import images from "../../../core/constants/images";
import styles from "./style.module.scss";
import ServicesCard from "../../components/ServicesCard/ServicesCard";
import Footer from "../../components/Footer/Footer";
import strings from "../../../core/constants/strings";
import ScrollUp from "../../components/ScrollUp/ScrollUp";
import { cards } from "../../../core/constants/data";

const Services = () => {
  return (
    <div className={styles.container}>
      <div className={styles.headerTextContainer}>
        <p>{strings.services.title}</p>
      </div>
      <img src={images.services_nmcp} className={styles.servicesNmcpLogo}></img>

      {cards.map((card) => (
        <div
          id={card.id}
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ServicesCard
            title={card.title}
            description={card.description}
            primaryColor={card.primaryColor}
            mainImage={card.mainImage}
          />
        </div>
      ))}

      <Footer />
      <ScrollUp />
    </div>
  );
};

export default Services;
