import strings from "../constants/strings";

const routes: {
  [key: string]: {
    name: string;
    route: string;
  };
} = {
  home: {
    name: "",
    route: "/",
  },
  aboutus: {
    name: strings.routes.aboutUs,
    route: "/aboutus/",
  },
  services: {
    name: strings.routes.services,
    route: "/services/",
  },
  portofolio: {
    name: strings.routes.ourWork,
    route: "/ourwork/",
  },
  team: {
    name: strings.routes.ourTeam,
    route: "/team/",
  },
  blog: {
    name: strings.routes.blog,
    route: "/blog/",
  },
  contact: {
    name: strings.routes.contact,
    route: "/contact/",
  },
  // events: {
  //   name: "EVENTS",
  //   route: "/events/",
  // }
};

export default routes;
