import React from "react";
import styles from "./styles.module.scss";
import images from "../../../core/constants/images";
import MainButton from "../../components/MainButton";

const PaymentUnsuccessful = () => {
  return (
    <div className={styles.container} style={{ marginTop: "10vh" }}>
      <img src={images.unsmiley} className={styles.smiley} />
      <div className={styles.title}>Payment successful!</div>
      <div>
        <div className={styles.details}>
          There has been an error processing your request. Please contact
          customer support at 0743 833 116 or contact@nomorecoffeeplease.com for
          further details. Apologies for the inconvenience!
        </div>
      </div>
      <button className={styles.okButton}>
        <MainButton
          title="TRY AGAIN"
          primaryColor="#F6CF85"
          onClick={() =>
            (window.location.href = "https://nomorecoffeeplease.com/events")
          }
        />
      </button>
    </div>
  );
};

export default PaymentUnsuccessful;
