import React, { useState } from 'react';
import images from '../../../core/constants/images';
import styles from './styles.module.scss';
interface ReviewData {
  author: string;
  text: string;
}
interface IProps {
  reviewData: any;
}
const ReviewCard: React.FC<IProps> = ({ reviewData }) => {
  const [reviewIndex, setReviewIndex] = useState<number>(0);
  return (
    <div
      className={styles.container}
      style={{ backgroundImage: `url(${images.portofolio.reviewBackground})` }}
    >
      {reviewData.length > 0 ? (
        <div className={styles.mainContainer}>
          <div className={styles.imageContainer}>
            <img
              className={reviewIndex === 0 ? `${styles.disabled}` : ''}
              src={images.portofolio.reviewCardLeftArrow}
              onClick={() => {
                if (reviewIndex !== 0)
                  setReviewIndex(prevState => prevState - 1);
              }}
              alt="arrow"
            />
          </div>
          {reviewData.map((item: ReviewData, index: number) => {
            if (index === reviewIndex)
              return (
                <div className={styles.detailsContainer}>
                  <div className={styles.reviewAuthor}>{item.author}</div>
                  <div className={styles.reviewText}>"{item.text}"</div>
                </div>
              );
          })}
          <div className={styles.imageContainer}>
            <img
              className={
                reviewData.length - 1 === reviewIndex
                  ? `${styles.disabled}`
                  : ''
              }
              onClick={() => {
                if (reviewIndex !== reviewData.length - 1)
                  setReviewIndex(prevState => prevState + 1);
              }}
              src={images.portofolio.reviewCardRightArrow}
              alt="arrow"
            />
          </div>
        </div>
      ) : (
        <div className={styles.noArrowsContainer}>
          <div className={styles.detailsContainer}>
            <div className={styles.reviewAuthor}>{reviewData.author}</div>
            <div className={styles.reviewText}>"{reviewData.text}"</div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ReviewCard;
