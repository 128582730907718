import React, { useEffect } from "react";
import {
  getDoc,
  getFirestore,
  doc,
  addDoc,
  setDoc,
  collection,
  updateDoc,
} from "firebase/firestore";
import { useLocation } from "react-router-dom";
import app from "../../../firebase";
import { sendEmailWithQR } from "../../../functions/firebase";
import styles from "./styles.module.scss";
import images from "../../../core/constants/images";
import MainButton from "../../components/MainButton";

const PaymentSuccessful = () => {
  const location = useLocation();
  const id = location.search.substring(1);
  const base = getFirestore(app);

  const sendEmailQrs = async () => {
    const detailsObject = doc(base, "tempInfos", id);
    const infos = await getDoc(detailsObject).then((resp) => resp.data());
    if (infos)
      if (infos.sent === false) {
        infos.qrCodes.forEach(async (item: any, index: number) => {
          const docRef = doc(base, "tickets", infos.id[index]);
          await setDoc(docRef, {
            email: infos.email,
            name: infos.name,
            validated: false,
          });
        });
        sendEmailWithQR(infos.qrCodes, infos?.email, infos?.name);
        await updateDoc(doc(base, "tempInfos", id), { sent: true });
      }
  };

  useEffect(() => {
    sendEmailQrs();
  }, []);

  return (
    <div className={styles.container} style={{ marginTop: "10vh" }}>
      <img src={images.smiley} className={styles.smiley} />
      <div className={styles.title}>Payment successful!</div>
      <div>
        <div className={styles.details}>
          Thank you for the purchace! The ticket(s) has been attached to your
          e-mail. If you have any enquiries please contact us on 0743 833 116.
        </div>
      </div>
      <button className={styles.okButton}>
        <MainButton
          title="OK"
          primaryColor="#F6CF85"
          onClick={() =>
            (window.location.href = "https://nomorecoffeeplease.com/")
          }
        />
      </button>
    </div>
  );
};

export default PaymentSuccessful;
