import React, { useState, useCallback } from "react";
import images from "../../../core/constants/images";
import PeopleGallery from "../Homepage/PeopleGallery";
import { peopleObjects } from "../../../core/constants/data";
import styles from "../../../tablet/screens/Homepage/style.module.scss";

interface IProps {
  personData: string | any[];
}

const OldPeopleGallery: React.FC<IProps> = ({ personData }) => {
  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const [effect, setEffect] = useState<boolean>(false);
  const modifyImage = useCallback((index: number) => {
    setEffect(true);
    setTimeout(() => {
      setEffect(false);
      setPhotoIndex(index);
    }, 500);
  }, []);
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${images.ourTeamBannerTabletProject}), url(${images.ourTeamBannerTabletProject})`,
          backgroundPosition:
            photoIndex >= 5 || personData.length < 5
              ? "center, center"
              : "top 50px right 50%, bottom 60px right 50%",
        }}
        className={styles.ourTeamBanner}
      >
        <PeopleGallery
          effect={effect}
          name1={personData[photoIndex].name}
          image1={personData[photoIndex].image}
          imageArt1={personData[photoIndex].imageArt}
          fct1={personData[photoIndex].fct}
          name2={
            photoIndex + 1 < personData.length
              ? personData[photoIndex + 1].name
              : null
          }
          image2={
            photoIndex + 1 < personData.length
              ? personData[photoIndex + 1].image
              : null
          }
          imageArt2={
            photoIndex + 1 < personData.length
              ? personData[photoIndex + 1].imageArt
              : null
          }
          fct2={
            photoIndex + 1 < personData.length
              ? personData[photoIndex + 1].fct
              : null
          }
          name3={
            photoIndex + 2 < personData.length
              ? personData[photoIndex + 2].name
              : null
          }
          image3={
            photoIndex + 2 < personData.length
              ? personData[photoIndex + 2].image
              : null
          }
          imageArt3={
            photoIndex + 2 < personData.length
              ? personData[photoIndex + 2].imageArt
              : null
          }
          fct3={
            photoIndex + 2 < personData.length
              ? personData[photoIndex + 2].fct
              : null
          }
          name4={
            photoIndex + 3 < personData.length
              ? personData[photoIndex + 3].name
              : null
          }
          image4={
            photoIndex + 3 < personData.length
              ? personData[photoIndex + 3].image
              : null
          }
          imageArt4={
            photoIndex + 3 < personData.length
              ? personData[photoIndex + 3].imageArt
              : null
          }
          fct4={
            photoIndex + 3 < personData.length
              ? personData[photoIndex + 3].fct
              : null
          }
          name5={
            photoIndex + 4 < personData.length
              ? personData[photoIndex + 4].name
              : null
          }
          image5={
            photoIndex + 4 < personData.length
              ? personData[photoIndex + 4].image
              : null
          }
          imageArt5={
            photoIndex + 4 < personData.length
              ? personData[photoIndex + 4].imageArt
              : null
          }
          fct5={
            photoIndex + 4 < personData.length
              ? personData[photoIndex + 4].fct
              : null
          }
        />
      </div>

      <div className={styles.galleryLinesContainer}>
        {new Array(Math.floor(personData.length / 5) + 1)
          .fill(0)
          .map((item, index) => {
            return (
              <img
                src={
                  photoIndex < (index + 1) * 5 && photoIndex > index * 5 - 1
                    ? images.lineBold
                    : images.lineNotBold
                }
                className={styles.lineImage}
                onClick={() => modifyImage(index * 5)}
              />
            );
          })}
      </div>
    </div>
  );
};

export default OldPeopleGallery;
