import { url } from "inspector"
import React from "react"
import images from "../../../core/constants/images"
import styles from "./style.module.scss"

interface IProps {
	pageLogo: string
}

const ScreenHeader = ({ pageLogo }: IProps) => {
	return (
		<div
			style={{ backgroundImage: `url("${images.header}")` }}
			className={styles.container}
		>
			<img
				src={pageLogo}
				className={styles.pageLogo}
				style={
					pageLogo ===
					"/static/media/services.582356b8abba87ade4f6.png"
						? { height: "auto", width: "24vw", marginTop: "-40px" }
						: {}
				}
			></img>
		</div>
	)
}

export default ScreenHeader
