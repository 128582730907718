import React from "react";
import { Link } from "react-router-dom";
import MainButton from "../MainButton";
import styles from "./styles.module.scss";

const BlogCard = ({ image, title, date, category, color, item }: any) => {
  return (
    <div className={styles.container}>
      <Link
        className={styles.buttonLink}
        to={{
          pathname: `/blog/${title.replace(/ /g, "")}`,
        }}
        state={{ item: item }}
      >
        <div
          style={{ backgroundImage: `url(${image})` }}
          className={styles.image}
        >
          <div className={styles.button}>
            <MainButton
              title={category.toUpperCase()}
              primaryColor={color}
              onClick={() => {}}
            />
          </div>
        </div>
      </Link>
      <div className={styles.date}>{date}</div>
      <div className={styles.title}>{title}</div>
      <Link
        className={styles.buttonLink}
        to={{
          pathname: `/blog/${title.replace(/ /g, "")}`,
        }}
        state={{ item: item }}
      >
        <div
          className={styles.readMoreButton}
          style={{ borderBottomColor: color }}
        >
          Read More
        </div>
      </Link>
    </div>
  );
};

export default BlogCard;
