import React from "react";

import { Text } from "./strings";
import styles from "./style.module.scss";

const Privacy = () => {
  return (
    <div className={styles.privacy}>
      <div>
        <div className={styles.titleMargin}>
          <div className={styles.title}>{Text.section_0.title_1}</div>
          <div className={styles.title}>{Text.section_0.title_2}</div>
        </div>
        <div className={styles.paragraph}>{Text.section_0.paragraph_1}</div>
        <div className={styles.paragraph}>
          Our confidentiality policy (mentioned further below) is consistent
          with EU Regulation 2016/679 regarding the processing, by
          NOMORECOFFEEPLEASE, of personal data provided through 'BookTrade'.
        </div>
        <div className={styles.paragraph}>{Text.section_0.paragraph_3}</div>
        <div className={styles.paragraph}>{Text.section_0.paragraph_4}</div>
        <div className={styles.paragraph}>{Text.section_0.paragraph_5}</div>
        <div className={styles.paragraph}>{Text.section_0.paragraph_6}</div>
      </div>
      <div>
        <div className={styles.bold}>{Text.section_1.title}</div>
        <div>{Text.section_1.paragraph_1}</div>
        <div className={styles.paragraph}>{Text.section_1.paragraph_2}</div>
      </div>
      <div>
        <div className={styles.bold}>{Text.section_2.title}</div>
      </div>
      <div>
        <div className={styles.bold}>{Text.section_2_1.title}</div>
        <div>{Text.section_2_1.body}</div>
      </div>
      <div>
        <div className={styles.bold}>{Text.section_2_2.title}</div>
        <div>{Text.section_2_2.paragraph_1}</div>
        <div className={styles.paragraph}>{Text.section_2_2.paragraph_2}</div>
        <div className={styles.paragraph}>{Text.section_2_2.paragraph_3}</div>
        <div className={styles.paragraph}>{Text.section_2_2.paragraph_4}</div>
        <div className={styles.paragraph}>{Text.section_2_2.paragraph_5}</div>
      </div>
      <div>
        <div className={styles.bold}>{Text.section_3.title}</div>
        <div>{Text.section_3.body}</div>
      </div>
      <div>
        <div className={styles.bold}>{Text.section_4.title}</div>
        <div>{Text.section_4.body}</div>
        <div className={styles.section4}>{Text.section_4.line_1}</div>
        <div className={styles.section4}>{Text.section_4.line_2}</div>
        <div className={styles.section4}>{Text.section_4.line_3}</div>
        <div className={styles.section4}>{Text.section_4.line_4}</div>
        <div className={styles.section4}>{Text.section_4.line_5}</div>
        <div className={styles.section4}>{Text.section_4.line_6}</div>
        <div className={styles.section4}>{Text.section_4.line_7}</div>
      </div>
      <div>
        <div className={styles.bold}>{Text.section_5.title}</div>
        <div>{Text.section_5.body}</div>
      </div>
      <div>
        <div className={styles.section5}>
          <strong className={styles.strooongs}>5.1</strong>
          {Text.section_5_1.body}
        </div>
      </div>
      <div>
        <div className={styles.section5}>
          <strong className={styles.strooongs}>5.2 </strong>
          {Text.section_5_2.body}
        </div>
      </div>
      <div>
        <div className={styles.bold}>{Text.section_6.title}</div>
        <div>{Text.section_6.paragraph_1}</div>
        <div className={styles.paragraph}>{Text.section_6.paragraph_2}</div>
      </div>
      <div>
        <div className={styles.bold}>{Text.section_7.title}</div>
        <div>{Text.section_7.paragraph_1}</div>
        <div className={styles.paragraph}>{Text.section_7.paragraph_2}</div>
        <div className={styles.paragraph}>{Text.section_7.paragraph_3}</div>
      </div>
      <div>
        <div className={styles.bold}>{Text.section_8.title}</div>
        <div>{Text.section_8.body}</div>
      </div>
      <div>
        <div className={styles.bold}>{Text.section_9.title}</div>
        <div>{Text.section_9.body}</div>
      </div>
    </div>
  );
};

export default Privacy;
