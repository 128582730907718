const stringsEN = {
  aboutUs: {
    ourStory: "Our story",
    aboutUsDescription: `Our journey? Oh, it's quite the tale! It kicked off in 2020 when a simple passion snowballed into what you know today as Nomorecoffeeplease. But let me tell you, it wasn't always this way. We were four buddies initially, putting in full-time hours that stretched into overtime and beyond. As the clock ticked on, experience piled up, but so did the workload. That's when we knew it was time for an upgrade. We connected with people who held our same beliefs close to their hearts - the belief in doing something that matters and will make a chance.\n
	 Today, Nomorecoffeeplease has made great strides forward! Our company is organized into three departments that collaborate closely at times and independently at others. However, one thing remains constant: we're always ready to lend a helping hand whenever needed. 
	 Anything can become achievable when you carry the drive, enthusiasm, and, of course, surround yourself with the right people!\n
	`,
    aboutDiscord: `If you like our vibe, join our community Discord channel for some great vibes, cool discussions on shared interests, and a chance to learn something new. Don't forget our weekly morning coffee catch-up and fun game nights – seriously, you won't want to pass up on all the excitement! Be part of the fun!`,
    growth: "Growth",
    transparency: "Transparency",
    friendship: "Friendship",
    equity: "Equity",
    empoweringCommunities: "Empowering Communities",
    teamwork: "Teamwork",
  },
  portofolio: {
    client: "CLIENT",
    team: "TEAM",
    services: "TECHNOLOGIES",
    category: "CATEGORY",
    aFewWords: "SUMMARY",
    summaryResults: "CHALLENGES",
    ourLatest: "Something that matters",
    testimonials: "TESTIMONIALS",
    loremIpsum:
      "The projects in which we invested time and energy are all listed below. You’ll be able to see what we tried to accomplish and how our vision came to life. You’ll find diverse projects from different industries. That’s because we are different, and at Nomorecoffeeplease, we choose our work. ",
    contactUs: "CONTACT US",
  },

  contactUs: {
    getInTouch: "Join our NMCP COMMUNITY",
    discord: "on Discord:",
    email: "or send us an e-mail:",
    yourName: "Your name *",
    yourEmail: "Your email *",
    yourMessage: "Your Message...",
    send: "GET IN TOUCH",
  },

  footer: {
    address: "Str. Mihai Eminescu, Nr 36/3, Baia Mare, Maramures",
    phone: "+40 742 826 647",
    email: "contact@nomorecoffeeplease.com",
    copyright:
      "Copyright © 2024 | NoMoreCoffeePlease SRL | All rights reserved | Designed and powered by us.",
    contactPerson: "Andrei Stoica - CEO",
  },
  services: {
    title: "Services What We Provide",
    subtitles: [
      `The community focuses on these areas. If you want TALENT, this is where you find it.`,
      `Marketing: The soul of the company`,
      `Mobile Development: The Most Used Channel in the World`,
      ` UI/UX: There has to be something beautiful in everything we do.`,
      `Web Development: Where convenience meets utility`,
    ],
  },

  homepage: {
    someTitle: "GREETINGS TRAVELER",
    developement: "DEVELOPMENT",
    app: "APP",
    web: "WEB",
    uiUX: "UI/UX",
    marketing: "MARKETING",
    design: "DESIGN",
    technicalities: "Technicalities",
    passion: "Passion",
    collaboration: "Collaboration",
    mobileDev: "MOBILE DEVELOPMENT",
    webDev: "WEB DEVELOPMENT",
    uiUXDesign: "UI/UX DESIGN",
    weUseReact:
      " We use React-Native Technology for your mobile app, so you can have both IOS & Android in one place.",
    inWebDev:
      "In web development we use... React, as it's popularity is growing as a solution that we safely say is safe.",
    weAreDevelop:
      " We are developing products from scratch, and that means: design, strategizing and coming up with the best user flow that we can make.",
    marketingWas:
      "Marketing was made with the purpose to do it for us, at first. Then the first customer appeared that needed our services.",
    ourServices:
      "Our services are something that might interest you. Not because we’re good at it, but because we’re great at it. Our first SERVICE is: TRUST. Hopefully, this has convinced you to join us. If not, we can also do some mobile development.",
    wordsAboutUs:
      "Come, take a break and sit by the cat. She’ll hand you a coffee until you say it’s enough… (*coughs: no.more.coffee.please*)…",
    weStrive: `We strive to develop ourselves in technical fields like: React, React-Native, AR, VR, Marketing, QA, UI/UX, and if you want to expand this list, you are my guest!
		We do what we love, and we build a career on our terms, guided by the great Coffee Cat!`,
    everyoneAtNmcp:
      "Everyone at NMCP has that fire inside them that keeps them going, even in tough times. Not giving up is our wildcard, and thus, we drive towards success. We welcome those who are passionate and want to become a better version of themselves. Those people change the world.",
    weAchieve:
      "We achieve our goals and yours through hard work and motivation. Responsibility is in our blood, and coffee... We can collaborate however you like: Equity, Direct Pay, both, Years of the best Roasted Coffee… you decide. But we know for sure that whatever it is, it will be great!",
    expectations: {
      weStriveTo:
        "We strive to create a nice process for everyone. An awesome and magnificent product will come out of the Great Cat; hopefully the next one is yours.",
      delivery:
        "Continuous Delivery or Maintenance. You decide what you want to do next; it’s your call.",
      communicationIs:
        "Communication is very important to us to discover your needs and expectations regarding your desired product.",
      weHaveContinous:
        "We have a continuous delivery setup. A scrum master is also required to handle all organizational issues. We’re prepared, BIG TIME!",
      strategyTitle1: "Strategy, Planning",
      strategyTitle2: "and Coffee-ing",
      executionTitle1: "Execution / Development",
      executionTitle2: "(Roasting stuff)",
      testingTitle1: "Testing (Software Testing)",
      testingTitle2: "(Code too)",
      deliveryTitle1: "It’s done!",
      deliveryTitle2: "We’ll ship with documentation included.",
    },
    helpP1Text1: "Who feels overwhelmed with tasks and in need of",
    helpP1Text2: "extra human resources",
    helpP1Text3:
      ". Get a skilled team to lend a hand and get your projects across the finish line.",
    helpP2Text1: "Who",
    helpP2Text2: "got a project just waiting to come to life",
    helpP2Text3:
      ". We're here for you, from brainstorming and designing to turning it into a website or mobile app. ",
    helpP3Text1: "Who’s looking for",
    helpP3Text2: "marketing support",
    helpP3Text3:
      ". We're here to help with tasks such as managing social media, email marketing, and Google Ads, PR, Branding, Event organizing.",
    helpP4Text1: "Who’s looking to",
    helpP4Text2:
      "enhance the social media graphics or create seamless designs for projects",
    helpP4Text3: ". Count on us to help you achieve your goals.",
    helpP5Text1: "Who’s excited about technology, design, or marketing and",
    helpP5Text2: "searching for a job",
    helpP5Text3: ". Get in touch with us and let's have a chat!",
    helpP6Text1: "We welcome those",
    helpP6Text2: "who are passionate",
    helpP6Text3:
      "and want to become the better version of themselves. Those people change worlds.",
    selectedDefault: "I want to talk about *",
    scheduleText: "Let’s team up and bring your vision to life!",
    yourNamePlace: "Your Name *",
    yourEmailPlace: "Your Email *",
    yourPhonePlace: "Your Phone Number *",
    yourMessagePlace: "Your Message...",
    iAgree: "I agree with the ",
    termsAndConditions: "terms and conditions",
    business: "Business",
    internship: "Internship",
    newProject: "New project",
    community: "Community",
    joinOur: "Join our NMCP COMMUNITY",
    onDiscord: "on Discord:",
    schedule: "Schedule a call",
    whyUsRoleText:
      "a startup founder, Product Manager, Software Developer, CEO or CTO",
    weCanHelpIfYouAre: "We can help if you are",
  },

  ourTeam: {
    title1: "Prosperity means caring",
    title2: "Skilled Professional Team",
    description: `We are NMCP's people - a young and passionate team on a mission to shake things up with our work. Every task, project, and day brings us new opportunities to grow, learn, and evolve. We bring our unique skills to the table, making a real impact in our community and aiming to expand that influence in the business world. Developers, marketers, sales persons, HR, we are all on the same mission. With different knowledge, we do what we know best and have fun at the same time. It's not just about work for us; we're here to make a positive difference!`,
    dedicatedTitle: "Our Dedicated Team",
    time: "Time",
    projectDescription: "Project description",
    projectScreens: "Project screens",
    responsibilities: "Responsibilities",
    acquiredSkills: "Acquired Skills",
    technology: "Technology",
    responsibility: "Responsibility",
    experience: "Experience",
    email: "Email",
    phone: "Phone",
    summary: "Summary",
    professionalSkills: "Professional Skills",
    projects: "Projects",
    seeProfile: "SEE PROFILE",
    title: "Our team",
    secondaryTitle: "Ways of collaboration",
    firstTextHeader: "Land you a hand",
    firstText:
      "Do you have a project that needs a developer? Look no further – we've got you covered! Just reach out to us and consider your project in good hands.",
    secondTextHeader: "Your project masters",
    secondText:
      "It might sound a bit cliché, but we handle everything from start to finish. From initial consulting to the final delivery, consider it all taken care of.",
  },
  ourwork: {
    ourworkDetails:
      "With 16 projects successfully completed, a network of 7 trusted partners, and a team of 23 collaborators, we're well-equipped to bring your ideas to life.",
    industry: "Industry",
    product: "Product",
    aFewWords: "A FEW WORDS",
    technologies: "TECHNOLOGIES",
    projectScreens: "PROJECT SCREENS",
    projectDetails: "PROJECT DETAILS",
    projectTeam: "PROJECT TEAM",
    projects: "PROJECTS",
    partners: "PARTNERS",
    collaborators: "COLLABORATORS",
  },
  routes: {
    aboutUs: "ABOUT US",
    services: "SERVICES",
    ourWork: "OUR WORK",
    ourTeam: "OUR TEAM",
    blog: "BLOG",
    contact: "CONTACT",
  },
};

const stringsRO = {
  aboutUs: {
    ourStory: "Povestea Noastră",
    aboutUsDescription: `Călătoria noastră? Ei bine, este o poveste destul de interesantă! A început în 2020, când o simplă pasiune a evoluat în ceea ce cunoașteți astăzi ca și Nomorecoffeeplease. Dar să vă spun, nu a fost întotdeauna așa. Am fost patru prieteni la început, lucrând cu ore suplimentare și până târziu în noapte. Pe măsură ce timpul trecea, experiența se acumula, dar și volumul de muncă. Atunci am știut că a venit timpul pentru o actualizare. Ne-am conectat cu oameni care împărtășeau aceleași convingeri - convingerea că facem ceva care contează și face o schimbare.
    Astăzi, Nomorecoffeeplease a făcut pași mari înainte! Compania noastră este organizată în trei departamente care colaborează strâns uneori și independent în altele. Cu toate acestea, un lucru rămâne constant: suntem mereu pregătiți să întindem o mână de ajutor când este nevoie.
    Orice poate deveni realizabil atunci când ai pasiunea, entuziasmul și, desigur, când ești înconjurat de oamenii potriviți!
    `,
    aboutDiscord: `Dacă vă place atmosfera noastră, alăturați-vă canalului nostru de comunitate Discord pentru discuții interesante despre interese comune și ocazia de a învăța ceva nou. Nu uitați de cafeneaua noastră săptămânală și seri distractive de jocuri - serios, nu veți vrea să ratați tot divertismentul! Fii parte din distracție!`,
    growth: "Creștere",
    transparency: "Transparență",
    friendship: "Prietenie",
    equity: "Echitate",
    empoweringCommunities: "Sprijinirea Comunităților",
    teamwork: "Echipă",
  },
  portofolio: {
    client: "CLIENT",
    team: "ECHIPĂ",
    services: "TEHNOLOGII",
    category: "CATEGORIE",
    aFewWords: "REZUMAT",
    summaryResults: "PROVOCĂRI",
    ourLatest: "Ceva care contează",
    testimonials: "MĂRTURII",
    loremIpsum:
      "Proiectele în care am investit timp și energie sunt toate enumerate mai jos. Veți putea vedea ce am încercat să realizăm și cum a prins viață viziunea noastră. Veți găsi proiecte diverse din diferite industrii. Asta pentru că suntem diferiți și la Nomorecoffeeplease, alegem munca noastră.",
    contactUs: "CONTACTEAZĂ-NE",
  },

  contactUs: {
    getInTouch: "Alătură-te COMUNITĂȚII NMCP",
    discord: "pe Discord:",
    email: "sau trimite-ne un e-mail:",
    yourName: "Numele tău *",
    yourEmail: "Adresa ta de email *",
    yourMessage: "Mesajul tău...",
    send: "TRIMITE",
  },

  footer: {
    address: "Str. Mihai Eminescu, Nr 36/3, Baia Mare, Maramureș",
    phone: "+40 742 826 647",
    email: "contact@nomorecoffeeplease.com",
    copyright:
      "Copyright © 2024 | NoMoreCoffeePlease SRL | Toate drepturile rezervate | Proiectat și susținut de noi.",
    contactPerson: "Andrei Stoica - CEO",
  },
  services: {
    title: "Servicii Oferite",
    subtitles: [
      `Comunitatea se concentrează pe aceste domenii. Dacă cauți TALENT, aici îl vei găsi.`,
      `Marketing: Sufletul companiei`,
      `Dezvoltare Mobilă: Cea mai utilizată cale din lume`,
      ` UI/UX: Trebuie să fie ceva frumos în tot ceea ce facem.`,
      `Dezvoltare Web: Unde confortul se întâlnește cu utilitatea`,
    ],
  },
  homepage: {
    someTitle: "BUN VENIT, CĂLĂTORULE",
    developement: "DEZVOLTARE",
    app: "APLICAȚIE",
    web: "SITE WEB",
    uiUX: "UI/UX",
    marketing: "MARKETING",
    design: "DESIGN",
    technicalities: "Tehnicități",
    passion: "Pasiune",
    collaboration: "Colaborare",
    mobileDev: "DEZVOLTARE MOBILĂ",
    webDev: "DEZVOLTARE WEB",
    uiUXDesign: "UI/UX DESIGN",
    weUseReact:
      " Folosim tehnologia React-Native pentru aplicația ta mobilă, astfel încât să poți avea atât IOS, cât și Android în același loc.",
    inWebDev:
      "În dezvoltarea web folosim... React, deoarece popularitatea sa crește ca o soluție pe care o putem considera sigură.",
    weAreDevelop:
      " Dezvoltăm produse de la zero, și asta înseamnă: design, strategie și crearea celui mai bun user-flow pe care îl putem realiza.",
    marketingWas:
      "Marketingul a fost creat cu scopul de a-l face pentru noi, la început. Apoi, primul client a apărut și a avut nevoie de serviciile noastre.",
    ourServices:
      "Serviciile noastre sunt ceva care te-ar putea interesa. Nu pentru că suntem buni la asta, ci pentru că suntem foarte buni la asta. Primul nostru SERVICIU este: ÎNCREDEREA. Sperăm că acest lucru te-a convins să te alături nouă. Dacă nu, putem face și dezvoltare mobilă.",
    wordsAboutUs:
      "Hai, ia o pauză și așează-te lângă pisică. Ea îți va servi o cafea până când spui că este suficient... (*cough: no.more.coffee.please*)...",
    weStrive: `Ne străduim să ne dezvoltăm în domenii tehnice precum: React, React-Native, AR, VR, Marketing, QA, UI/UX, iar dacă dorești să extinzi această listă, ești binevenit!
		Facem ceea ce iubim și ne construim o carieră în propriile noastre condiții, ghidați de marea Pisică a Cafelei!`,
    everyoneAtNmcp:
      "Fiecare membru NMCP are acea pasiune interioară care îi menține motivați, chiar și în momentele dificile. Nu renunțăm niciodată, astfel că mizăm pe succes. Primim cu brațele deschise pe cei pasionați și dornici să devină o versiune mai bună a lor înșiși. Acești oameni schimbă lumea.",
    weAchieve:
      "Atât obiectivele noastre, cât și ale tale, le atingem prin muncă asiduă și motivație. Responsabilitatea este în sângele nostru, și bineînțeles, cafeaua... Putem colabora în orice fel dorești: Participare la Profit, Plată Directă, ambele, Ani de cea mai bună Cafea Prăjită... tu decizi. Dar știm sigur că oricare ar fi, va fi grozav!",
    expectations: {
      weStriveTo:
        "Ne străduim să creăm un proces plăcut pentru toată lumea. Un produs minunat și magnific va ieși din Pisica Magnifică; sperăm ca următorul să fie al tău.",
      delivery:
        "Livrare continuă sau Mentenanță. Tu decizi ce vrei să facem în continuare; tu iei decizia.",
      communicationIs:
        "Comunicarea este foarte importantă pentru noi pentru a descoperi nevoile și așteptările tale în ceea ce privește produsul dorit.",
      weHaveContinous:
        "Avem o configurație de dezvoltare continuă. Un scrum master este și el necesar pentru a gestiona toate problemele organizaționale. Suntem pregătiți, într-un cuvânt, LA MAXIM!",
      strategyTitle1: "Strategie, Planificare",
      strategyTitle2: "și Cafeluțe",
      executionTitle1: "Execuție / Dezvoltare",
      executionTitle2: "(Prăjire de lucruri)",
      testingTitle1: "Testare (Testare Software)",
      testingTitle2: "(Cod, de asemenea)",
      deliveryTitle1: "E gata!",
      deliveryTitle2: "Vom livra cu documentație inclusă.",
    },
    helpP1Text1: "Care se simte copleșit de sarcini și are nevoie de",
    helpP1Text2: "resurse umane suplimentare",
    helpP1Text3:
      ". Obține o echipă experimentată care să te ajute și să ducă proiectele tale la final.",
    helpP2Text1: "Care",
    helpP2Text2: "are un proiect care așteaptă doar să prindă viață",
    helpP2Text3:
      ". Suntem aici pentru tine, de la brainstorming și design la transformarea lui într-un site web sau aplicație mobilă. ",
    helpP3Text1: "Care caută",
    helpP3Text2: "suport în marketing",
    helpP3Text3:
      ". Suntem aici pentru a te ajuta cu sarcini precum gestionarea rețelelor sociale, marketingul prin email și Google Ads, PR, Branding, organizarea de evenimente.",
    helpP4Text1: "Care dorește să",
    helpP4Text2:
      "îmbunătățească grafica pentru rețelele sociale sau să creeze designuri perfecte pentru proiecte",
    helpP4Text3:
      ". Poți conta pe noi pentru a te ajuta să-ți atingi obiectivele.",
    helpP5Text1: "Care este entuziasmat de tehnologie, design sau marketing și",
    helpP5Text2: "căută un loc de muncă",
    helpP5Text3: ". Intră în contact cu noi și hai să discutăm!",
    helpP6Text1: "Dăm bun venit celor",
    helpP6Text2: "pasionați",
    helpP6Text3:
      "care doresc să devină o versiune mai bună a lor înșiși. Acești oameni schimbă lumea.",
    selectedDefault: "Vreau să vorbesc despre *",
    scheduleText: "Să ne unim forțele și să dăm viață viziunii tale!",
    yourNamePlace: "Numele tău *",
    yourEmailPlace: "Adresa ta de email *",
    yourPhonePlace: "Numărul tău de telefon *",
    yourMessagePlace: "Mesajul tău...",
    iAgree: "Sunt de acord cu ",
    termsAndConditions: "termenii și condițiile",
    business: "Business",
    internship: "Stagiu",
    newProject: "Proiect Nou",
    community: "Comunitate",
    joinOur: "Alătură-te COMUNITĂȚII NMCP",
    onDiscord: "pe Discord:",
    schedule: "Programare pentru o discuție",
    whyUsRoleText:
      "un fondator de startup, Manager de Produs, Dezvoltator Software, CEO sau CTO",
    weCanHelpIfYouAre: "Te putem ajuta dacă ești",
  },

  ourTeam: {
    title1: "Prosperitatea înseamnă îngrijire",
    title2: "Echipă Profesională Cu Abilități Remarcabile",
    description: `Credem că fiecare persoană ar trebui să fie într-o poziție de învățare și creștere continuă.
      Și încercăm să facilităm acest lucru gândindu-ne la cariera fiecăruia și făcând un plan. Mai jos, poți găsi
      detalii despre fiecare membru din comunitatea Nomorecoffeeplease.`,
    dedicatedTitle: "Echipa Noastră Dedicată",
    time: "Timp",
    projectDescription: "Descrierea Proiectului",
    projectScreens: "Capturi de Ecran ale Proiectului",
    responsibilities: "Responsabilități",
    acquiredSkills: "Abilități Dobândite",
    technology: "Tehnologie",
    responsibility: "Responsabilitate",
    experience: "Experiență",
    email: "Email",
    phone: "Telefon",
    summary: "Rezumat",
    professionalSkills: "Abilități Profesionale",
    projects: "Proiecte",
    title: "Our team",
    seeProfile: "VEZI PROFILUL",
    secondaryTitle: "Modalități de colaborare",
    firstTextHeader: "A-ți întinde o mână de ajutor",
    firstText:
      "Ai un proiect care are nevoie de un dezvoltator? Nu căuta mai departe - te-am acoperit! Doar contactează-ne și consideră că proiectul tău este în mâini bune.",
    secondTextHeader: "Maestrii proiectului tău",
    secondText:
      "Ar putea suna puțin clișeic, dar ne ocupăm de tot, de la început până la sfârșit. De la consultanța inițială până la livrarea finală, consideră că totul este în grija noastră.",
  },
  ourwork: {
    ourworkDetails:
      "Cu 16 proiecte finalizate cu succes, o rețea de 7 parteneri de încredere și o echipă de 23 de colaboratori, suntem bine pregătiți să dăm viață ideilor tale.",
    industry: "Industrie",
    product: "Produs",
    aFewWords: "CÂTEVA CUVINTE",
    technologies: "TEHNOLOGII",
    projectScreens: "CAPTURI DE ECRAN ALE PROIECTULUI",
    projectDetails: "DETALII DESPRE PROIECT",
    projectTeam: "ECHIPA DE PROIECT",
    projects: "PROIECTE",
    partners: "PARTENERI",
    collaborators: "COLABORATORI",
  },
  routes: {
    aboutUs: "DESPRE NOI",
    services: "SERVICII",
    ourWork: "MUNCA NOASTRĂ",
    ourTeam: "ECHIPA NOASTRĂ",
    blog: "BLOG",
    contact: "CONTACT",
  },
};

const strings = navigator.language === "ro" ? stringsRO : stringsEN;

export default strings;
