import React from 'react';

import images from '../../../core/constants/images';
import styles from './style.module.scss';
import ServicesCard from '../../components/ServicesCard/ServicesCard';
import Footer from '../../components/Footer/Footer';
import strings from '../../../core/constants/strings';
import ScrollUp from '../../components/ScrollUp/ScrollUp';
import { cards } from '../../../core/constants/data';

const Services = () => {
  return (
    <div className={styles.container}>
      <div className={styles.headerTextContainer}>
        <p>{strings.services.title}</p>
      </div>
      <img src={images.services_nmcp} className={styles.servicesNmcpLogo}></img>
      <div className={styles.subheaderTextContainer}>
        {strings.services.subtitles.map(item => {
          return <div>{item}</div>;
        })}
      </div>

      {cards.map(card => (
        <div
          id={card.id}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ServicesCard
            title={card.title}
            description={card.description}
            primaryColor={card.primaryColor}
            mainImage={card.mainImage}
            projects={card.projects}
          />
        </div>
      ))}
      <ScrollUp />
      <div id="marketing" style={{ width: '100%' }}>
        <Footer />
      </div>
    </div>
  );
};

export default Services;
