import React from 'react';
import styles from '../../screens/Homepage/style.module.scss';
import images from '../../../core/constants/images';
import { colors } from '../../../core/constants/colors';

const PortofolioPart = () => {
  return (
    <div
      style={{ backgroundImage: `url(${images.portofolioBanner})` }}
      className={styles.portofolioBanner}
    >
      <div className={styles.honeycombContainer}>
        <div className={styles.firstHoneycombRow}>
          <div className={styles.diamondShape}>
            <img src={images.dogScoutsLogo} className={styles.honeycombImage} />
            <div className={styles.titlesContainer}>
              <div></div>
              <div className={styles.projectTitle}>Dog Scouts</div>
              <div
                className={styles.serviceTitle}
                style={{ color: colors.downy }}
              >
                Web Dev
              </div>
            </div>
          </div>
          <div className={styles.diamondShape}>
            <img
              src={images.chalkNotesLogo}
              className={styles.honeycombImage}
            />
            <div className={styles.titlesContainer}>
              <div></div>
              <div className={styles.projectTitle}>Chalk Notes</div>
              <div
                className={styles.serviceTitle}
                style={{ color: colors.sandyBrown }}
              >
                App Dev
              </div>
            </div>
          </div>
          <div className={styles.diamondShape}>
            <img
              src={images.romanticStudioLogo}
              className={styles.honeycombImage}
            />
            <div className={styles.titlesContainer}>
              <div></div>
              <div className={styles.projectTitle}>Studio Brasov</div>
              <div
                className={styles.serviceTitle}
                style={{ color: colors.manhattan }}
              >
                Marketing
              </div>
            </div>
          </div>
          <div className={styles.diamondShape}>
            <img src={images.bookTradeLogo} className={styles.honeycombImage} />
            <div className={styles.titlesContainer}>
              <div></div>
              <div className={styles.projectTitle}>BookTrade</div>
              <div
                className={styles.serviceTitle}
                style={{ color: colors.sandyBrown }}
              >
                App Dev
              </div>
            </div>
          </div>
        </div>
        <div className={styles.secondHoneycombRow}>
          <div className={styles.diamondShape}>
            <img src={images.izibacLogo} className={styles.honeycombImage} />
            <div className={styles.titlesContainer}>
              <div></div>
              <div className={styles.projectTitle}>iziBac</div>
              <div
                className={styles.serviceTitle}
                style={{ color: colors.sandyBrown }}
              >
                App Dev
              </div>
            </div>
          </div>
          <div className={styles.diamondShape}>
            <img src={images.zemLogo} className={styles.honeycombImage} />
            <div className={styles.titlesContainer}>
              <div></div>
              <div className={styles.projectTitle}>ZemCluj</div>
              <div
                className={styles.serviceTitle}
                style={{ color: colors.downy }}
              >
                Web Dev
              </div>
            </div>
          </div>
          <div className={styles.diamondShape}>
            <img
              src={images.casaCerbuluiLogo}
              className={styles.honeycombImage}
              style={{ height: '100%' }}
            />
            <div className={styles.titlesContainer}>
              <div></div>
              <div className={styles.projectTitle}>Casa cerbului</div>
              <div
                className={styles.serviceTitle}
                style={{ color: colors.downy }}
              >
                Web Dev
              </div>
            </div>
          </div>
          <div className={styles.diamondShape}>
            <img
              src={images.oasisLogo}
              className={styles.honeycombImage}
              style={{ height: '14%' }}
            />
            <div className={styles.titlesContainer}>
              <div></div>
              <div className={styles.projectTitle}>Oasis Residence</div>
              <div
                className={styles.serviceTitle}
                style={{ color: colors.downy }}
              >
                Web Dev
              </div>
            </div>
          </div>
          <div className={styles.diamondShape}>
            <img
              src={images.bsrLogo}
              className={styles.honeycombImage}
              style={{ height: '25%' }}
            />
            <div className={styles.titlesContainer}>
              <div></div>
              <div className={styles.projectTitle}>BSR</div>
              <div
                className={styles.serviceTitle}
                style={{ color: colors.downy }}
              >
                Web Dev
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortofolioPart;
