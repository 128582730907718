import React from "react";
import styles from "./newPeopleGallery.module.scss";
import images from "../../../core/constants/images";
import strings from "../../../core/constants/strings";
import FormNmcp from "./FormNmcp";
import { staffArray } from "../../../core/constants/arrays";

function NewPeopleGallery() {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.staffContainer}>
          <div className={styles.scheduleContainer}>
            <h1>{strings.homepage.schedule}</h1>
            <p>{strings.homepage.scheduleText}</p>
          </div>
          <div className={styles.profilesContainer}>
            {staffArray.map((person, index) => {
              return (
                <div key={index} className={styles.profiles}>
                  <img src={person.image} alt="profileImage" />
                  <h3>{person.name}</h3>
                  <p>{person.role}</p>
                </div>
              );
            })}
          </div>
        </div>
        <FormNmcp />
      </div>
    </>
  );
}

export default NewPeopleGallery;
