import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Footer from "../../components/Footer/Footer";
import MainButton from "../../components/MainButton";
import { useLocation } from "react-router-dom";
import ScrollUp from "../../components/ScrollUp/ScrollUp";
import { getBlogPost } from "../../../functions/firebase";
import { colors } from "../../../core/theme/colors";

const BlogPost = () => {
  const { pathname, state }: any = useLocation();
  const [blogData, setBlogData] = useState([]);

  const getBlogPostHandler = async () => {
    try {
      const data = await getBlogPost(pathname);

      const dataWithColors = {
        ...data,
        color:
          data.blogCategory === "Development"
            ? colors.orange
            : data.blogCategory === "Marketing"
            ? colors.secondary
            : colors.pink,
      };

      setBlogData(dataWithColors);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getBlogPostHandler();
  }, []);
  const blogPostData = state ? state.item : blogData;

  return (
    <div className={styles.container}>
      <div
        style={{
          backgroundImage: `url(${blogPostData.image})`,
        }}
        className={styles.backgroundImage}
      >
        <div>
          <MainButton
            title={blogPostData.blogCategory}
            primaryColor={blogPostData.color}
            onClick={() => {}}
          />
        </div>
      </div>
      <div className={styles.authorContainer}>
        <img
          src={blogPostData.authorImage}
          className={styles.authorImage}
          alt=""
        ></img>
        <div className={styles.personDiv}>
          <div className={styles.nameAndRole}>{blogPostData.authorName}</div>
          <div className={styles.authorFunction}>
            {blogPostData.authorFunction}
          </div>
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.title}>{blogPostData.blogTitle}</div>
        <div
          dangerouslySetInnerHTML={{ __html: blogPostData.blogBody }}
          className={styles.text}
        ></div>
      </div>
      <ScrollUp />
      <Footer />
    </div>
  );
};
export default BlogPost;
