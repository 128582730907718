export const Text = {
    section_0: {
      title_1: "TERMS OF SERVICE & FUNCTIONING  ",
      title_2: "BEHAVIOUR REGARDING 'BookTrade' MOBILE APP",
      paragraph_1:
        "The purpose of these Terms of Service is to ensure that the users (as they are defined below) are properly informed about the usage and functioning behaviour of the 'BookTrade' app.",
      paragraph_2:
        "Our confidentiality policy (mentioned further below) is consistent with EU Regulation 2016/679 regarding the processing, by NOMORECOFFEEPLEASE, of personal data provided through 'BookTrade'.",
      paragraph_3:
        "The Terms of Service of the 'BookTrade' app, as they are presented in this document, are and will always be interpreted in conformity with romanian law. These terms establish the manner of usage of the 'BookTrade' app.",
      paragraph_4:
        "By registering in app you confirm that you are 18 years of age or older and that you are not banned from using services similar to those offered by 'BookTrade'.",
      paragraph_5:
        "Through downloading and using 'BookTrade', the user agrees to comply with these terms and conditions and confidentiality policy. If the user does not agree to these terms of service and confidentiality policy in their entirety, the user is required to immediately stop using the app.",
      paragraph_6:
        "We reserve our right to bring changes to the Terms of Service, confdentiality policy and functioning behaviour of the app. By continuing using the app after these eventual changes had been made, the user renews his/her agreement to these terms. If the users do not agree to the Terms of Service and confidentiality policy, or to the changes brought to them, they are required to immediately stop using the app."
    },
    section_1: {
      title: "1. What is BookTrade?",
      paragraph_1:
        "Booktrade is a mobile app consisting of software that will be accesible from iOS and Android devices. The app provides a series of services consisting in communication methods, matching user preferences etc, having the sole purpose of trading books between users.",
      paragraph_2:
        "The term 'app' is defined as being the sum of all of its components: software, design, logo, name, content etc."
    },
    section_2: {
      title: "2. How to access BookTrade?"
    },
    section_2_1: {
      title: "2.1 User registration and identification",
      body: "BookTrade registration requires some mandatory personal information: name, surname, e-mail address."
    },
    section_2_2: {
      title: "2.2 User authentification and app usage",
      paragraph_1:
        "The app stores authentification data of your account. For this purpose, you agree and understand that you are responsible for keeping your personal authentification data confidential.",
      paragraph_2:
        "If you are using BookTrade with a family or corporate account, you agree that all family members or employees that were provided with the authentification data are authorized to access the account and the services provided by the app.",
      paragraph_3:
        "Vă obligați să notificați Booktrade imediat, fără întârzieri nejustificate, atunci când sesizați utilizarea neautorizată a datelor dumneavoastra de conectare. De asemenea, sunteți de acord să notificați Booktrade imediat, fără întârzieri nejustificate, în același mod cu privire la orice altă încălcare a securității în legătură cu aplicatia despre care aveți cunoștință.",
      paragraph_4:
        "BookTrade has the right to suspend your account or authentification data in case any security compromise or/and unauthorized or fraudulent usage of your account is suspected. BookTrade will inform you before or, if that is not possible, immediately after suspending your account or authentification data, specifying the reasons of the suspention, excepting the case in which providing this information could compromise reasonable security measures or would contradict the law.",
      paragraph_5:
        "Neither BookTrade nor NOMORECOFFEEPLEASE S.R.L are responsible for false declaration of identity by the users or any damage caused by incorrect usage of the app."
    },
    section_3: {
      title: "3. What can you do with Booktrade?",
      body: "BookTrade's sole purpose is the facilitation of book exchanges between registered users, after matching them based on a predefined algorithm and excludes any other purpose other than this. Transportation/exchange methods, as well as any risks associated with them, remain the user's responsibility."
    },
    section_4: {
      title: "4. Statements and guarantees",
      body: "At the moment of registering for BookTrade you declare and guarantee the following:",
      line_1:
        "- you have the right, the authority and full capacity of accessing and using the app;",
      line_2:
        "- you do not share you login information with third parties, except authorized persons within a corporate or family account, for the reason that sharing this information would expose you to security risks. You are exclusively responsible for the data submited through BookTrade remaining confidential, as well as for any activity associated to your account;",
      line_3:
        "- you are required to immediately inform BookTrade of any unauthorized use of your account or any security breach that you could reasonably notice. However, regardless of whether BookTrade was notified, we are not responsible for any possible damages resulted from an unauthorized use of your account (this use being with or without your knowleadge);",
      line_4:
        "- you will not forge or share false information about your identity or intentions in regards to this application.",
      line_5:
        "- you will not send, share or offer any information/goods that are partially or fully illegal, false, fraudulent, inadequate, offensive or that would harm public order or good morals",
      line_6:
        "- you will not infringe any rights provided by law, rights of possession, intangible rights, confidentiality rights or personal data rights;",
      line_7:
        "- you will not use the app content with the intention to resell or redistribute the services offered by BookTrade."
    },
    section_5: {
      title: "5. Terminating usage of BookTrade",
      body: "The termination of usage by a registered user could result from the following:"
    },
    section_5_1: {
      body: "- from the user's initiative, by uninstalling the application;"
    },
    section_5_2: {
      body: "- from BookTrade's initiative, in case any infringements of proper conduct or of the terms and conditions are noticed, that could bring damage of any kind to the company."
    },
    section_6: {
      title: "6. Intelectual property",
      paragraph_1:
        "The users declare that they have been informed that any graphic designs, content or application materials accessed through the app belong to BookTrade. The exceptions are information, graphic elements that belong to other entities, that are the property of third parties. The user agrees that any piece of software used regarding the services offered by BookTrade contain private information that exist under property rights, protected by Intelectual property rights and other applicable laws.",
      paragraph_2:
        "The user does not have the right to reproduce, copy, sell or exploit the services, information and/or audio-video materials accessible through partial or full use of the application. Modifying, copying, renting, borrowing, selling, distributing or creating any materials derived from services or software offered by BookTrade is forbidden."
    },
    section_7: {
      title: "7. Our responsibilities",
      paragraph_1:
        "You agree that the application will not be delivered without errors or continuously, that certain imperfections will not be corrected the instant they are noticed. The user is the only one responsible for using the application.",
      paragraph_2:
        "Furthermore, the users are responsible for any direct/indirect damage (intentional or accidental) caused to BookTrade or any user or person that BookTrade is responsible for, including the owner of the account or through fraudulent usage of the application.",
      paragraph_3:
        "The user has been informed and agrees that BookTrade will not be held responsible in any shape or form, for any direct or indirect damages caused to the user as a result of the application not functioning correctly or not functioning at all."
    },
    section_8: {
      title: "8. Applicable law",
      body: "In case of a confilct/misunderstanding in regards to BookTrade's usage, we will attempt to resolve said conflicts through amicable measures, negotiations in this case being mediated by good will. All disputes will be settled by competent courts from Baia Mare (Romania), with the exception being the case when the law specifies other exclusive jurisdiction for you."
    },
    section_9: {
      title: "9. Assistance",
      body: "If you have any issues regarding BookTrade's use, you can contact us through this address: contact@nomorecoffeeplease.com"
    }
  };