import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAU_dDbD-AQzHUdmKj4SM01jEpf3TNTu60",
  authDomain: "festivalnmcp.firebaseapp.com",
  projectId: "festivalnmcp",
  storageBucket: "festivalnmcp.appspot.com",
  messagingSenderId: "842070858542",
  appId: "1:842070858542:web:2d7fb962d6b6cc1b6db8bf",
  measurementId: "G-YJD3TXL8K7"
};

const app = initializeApp(firebaseConfig);

export default app;