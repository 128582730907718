import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import images from '../../../core/constants/images';
import Footer from '../../components/Footer/Footer';
import PeopleGallery from '../../components/Homepage/PeopleGallery';
import MainButton from '../../components/MainButton';
import { peopleObjects } from '../../../core/constants/data';
import styles from './style.module.scss';
import strings from '../../../core/constants/strings';
import './style.scss';
import PortofolioPart from '../../components/Homepage/PortofolioPart';
import ExpectationsLine from '../../components/Homepage/ExpectationsLine';
import ScrollUp from '../../components/ScrollUp/ScrollUp';
import { colors } from '../../../core/theme/colors';
import { HashLink } from 'react-router-hash-link';
import OldWhyUs from '../../components/HomepageContents/oldWhyUs';
import NewWhyUsPage from '../../components/HomepageContents/newWhyUs';
import OldPeopleGallery from '../../components/HomepageContents/oldPeopleGallery';
import NewPeopleGallery from '../../components/HomepageContents/newPeopleGallery';

const Homepage = () => {
  return (
    <div>
      <div className={styles.paddingLeftRight}>
        <img src={images.banner2} className={styles.banner}></img>
        <div className={styles.topContainer}>
          <p className={styles.titleContainer}>{strings.homepage.someTitle}</p>
          <p className={styles.aboutUsContainer}>
            {strings.homepage.wordsAboutUs}
          </p>
        </div>

        <div>
          <div className={styles.letterA}>
            <img style={{ width: '7vw' }} src={images.letterA} />
            <div className={styles.hoverTextContainer}>
              <div className={styles.serviceTitleText}>
                {strings.homepage.app}
              </div>
              <div className={styles.serviceCategoryText}>
                {strings.homepage.developement}
              </div>
            </div>
          </div>
          <div className={styles.letterW}>
            <img
              style={{
                width: '7vw',
              }}
              src={images.letterW}
            />
            <div className={styles.hoverTextContainer}>
              <div className={styles.serviceTitleText}>
                {strings.homepage.web}
              </div>
              <div className={styles.serviceCategoryText}>
                {strings.homepage.developement}
              </div>
            </div>
          </div>
          <div className={styles.letterM}>
            <img
              style={{
                width: '7vw',
              }}
              src={images.letterM}
            />
            <div className={styles.hoverTextContainer}>
              <div className={styles.serviceCategoryTextM}>
                {strings.homepage.marketing}
              </div>
            </div>
          </div>
          <div className={styles.letterU}>
            <img
              style={{
                width: '7vw',
              }}
              src={images.letterU}
            />
            <div className={styles.hoverTextContainer}>
              <div className={styles.serviceTitleU}>
                {strings.homepage.uiUX}
              </div>
              <div className={styles.serviceCategoryTextU}>
                {strings.homepage.design}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <OldWhyUs /> */}

      <NewWhyUsPage />

      <Row className={styles.servicesContainer}>
        <div
          className={styles.servicesBannerContainer}
          style={{ backgroundImage: `url(${images.servicesBanner})` }}
        >
          <div className={styles.servicesListContainer}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <HashLink
                smooth
                className={styles.servicesAnimationContainer}
                style={{
                  borderRadius: '15px',
                }}
                to={'/services#mobile'}
              >
                <img
                  src={images.mobilePhone}
                  className={styles.categoryImage}
                  alt="icon"
                />
                <div
                  className={styles.textAnimation}
                  style={{ color: '#EDA751' }}
                >
                  {strings.homepage.mobileDev}
                </div>
                <div className={styles.servicesDescription}>
                  {strings.homepage.weUseReact}
                </div>
              </HashLink>
              <HashLink
                smooth
                className={styles.servicesAnimationContainer}
                style={{
                  marginLeft: '6vw',
                  borderColor: '#6AA5AC',
                  borderRadius: '15px',
                }}
                to={'/services#web'}
              >
                <img src={images.desktop} className={styles.categoryImage} />
                <div
                  className={styles.textAnimation}
                  style={{ color: '#6AA5AC' }}
                >
                  {strings.homepage.webDev}
                </div>
                <div className={styles.servicesDescription}>
                  {strings.homepage.inWebDev}
                </div>
              </HashLink>
            </div>
            <div className={styles.servicesListSecond}>
              <HashLink
                smooth
                className={styles.servicesAnimationContainer}
                style={{
                  borderColor: '#FF91AB',
                  borderRadius: '15px',
                }}
                to={'/services#design'}
              >
                <img
                  src={images.designModel}
                  className={styles.categoryImage}
                />
                <div
                  className={styles.textAnimation}
                  style={{ color: '#FF91AB' }}
                >
                  {strings.homepage.uiUXDesign}
                </div>
                <div className={styles.servicesDescription}>
                  {strings.homepage.weAreDevelop}
                </div>
              </HashLink>
              <HashLink
                smooth
                className={styles.servicesAnimationContainer}
                style={{
                  marginLeft: '6vw',
                  borderRadius: '15px',
                  borderColor: '#FFDC9C',
                }}
                to={'/services#marketing'}
              >
                <img
                  src={images.rocket}
                  className={styles.categoryImage}
                  style={{ height: '8vw' }}
                />
                <div
                  className={styles.textAnimation}
                  style={{ color: '#FFDC9C' }}
                >
                  {strings.homepage.marketing}
                </div>
                <div className={styles.servicesDescription}>
                  {strings.homepage.marketingWas}
                </div>
              </HashLink>
            </div>
          </div>
          <Col xs={4} className={styles.servicesText}>
            <img src={images.services} className={styles.servicesLogoImage} />
            <p className={styles.servicesTitle}>
              {strings.homepage.ourServices}
            </p>
            <MainButton
              primaryColor={colors.cyan}
              title={'VIEW MORE'}
              onClick={() => (window.location.href = '/services')}
            />
          </Col>
        </div>
      </Row>

      <img src={images.portofolioLogo} className={styles.portofolioLogo} />

      <PortofolioPart />

      <div className={styles.mainButtonPortofolio}>
        <a href="/ourwork" style={{ textDecoration: 'none' }}>
          <MainButton
            primaryColor={'#EDA751'}
            title={'VIEW MORE'}
            onClick={() => (window.location.href = '/ourwork')}
          />
        </a>
      </div>

      <div className={styles.whatToExpect}>
        <img src={images.whattoexpect} className={styles.whatToExpectImage} />
      </div>
      <ExpectationsLine />
      {/* <OldPeopleGallery /> */}
      <NewPeopleGallery />
      <ScrollUp />
      <Footer />
    </div>
  );
};
export default Homepage;
