import React, { useState } from 'react';
import images from '../../../core/constants/images';
import styles from './styles.module.scss';

interface Props {
  imageArray: any[];
  projectType: string;
}

const ImageCarousel: React.FC<Props> = ({ imageArray, projectType }) => {
  const [imageSetNumber, setImageSetNumber] = useState<number>(0);

  return (
    <div className={styles.screensContainer}>
      {imageArray.length > (projectType === 'Mobile Dev' ? 4 : 2) ? (
        <a
          onClick={() =>
            imageSetNumber > 0 ? setImageSetNumber(imageSetNumber - 1) : null
          }
        >
          <img
            src={images.arrowLeft}
            className={`${styles.arrows} ${
              imageSetNumber === 0 ? styles.disabled : ''
            }`}
            alt="Previous images."
          />
        </a>
      ) : (
        <div className={styles.placeholderDiv} />
      )}
      {imageArray.map((item: any, index: number) => {
        if (
          imageSetNumber ===
          Math.floor(index / (projectType === 'Mobile Dev' ? 4 : 2))
        ) {
          return (
            <a href={item} target="_blank">
              <div
                className={
                  projectType === 'Mobile Dev'
                    ? styles.imageContainer
                    : styles.imageContainerWeb
                }
              >
                <img
                  src={item}
                  className={styles.image}
                  alt="Project screenshot."
                />
              </div>
            </a>
          );
        } else return <></>;
      })}

      {imageArray.length > (projectType === 'Mobile Dev' ? 4 : 2) ? (
        <a
          onClick={() =>
            imageSetNumber <
            imageArray.length / (projectType === 'Mobile Dev' ? 4 : 2) - 1
              ? setImageSetNumber(imageSetNumber + 1)
              : null
          }
        >
          <img
            src={images.arrowRight}
            className={`${styles.arrows} ${
              imageArray.length - 3 === imageSetNumber ? styles.disabled : ''
            }`}
            alt="Next images."
          />
        </a>
      ) : (
        <div className={styles.placeholderDiv} />
      )}
    </div>
  );
};

export default ImageCarousel;
