import React, { useState } from "react";
import { Row, Col, FormControl, Form } from "react-bootstrap";

import ScreenHeader from "../../components/ScreenHeader/ScreenHeader";
import Footer from "../../components/Footer/Footer";
import ContactDetails from "../../components/ContactDetails";

import styles from "./styles.module.scss";
import images from "../../../core/constants/images";
import strings from "../../../core/constants/strings";
import MainButton from "../../components/MainButton";
import "./formSubmit";
import { colors } from "../../../core/constants/colors";
import ScrollUp from "../../components/ScrollUp/ScrollUp";

import { sendEmailToNMCP } from "../../../functions/firebase";
import NewPeopleGallery from "../../components/HomepageContents/newPeopleGallery";

const ContactUs = () => {
  const [discordImage, setDiscordImage] = useState<string>(images.discord);
  const [name, setName] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);

  const validate = () => {
    let emailError = "";

    if (!email.includes("@")) {
      return (emailError = "email invalid");
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid !== null) {
      setEmailError(false);
    } else setEmailError(true);
    if (!emailError) {
      setName("");
      setMessage("");
      setEmail("");
    }
  };

  return (
    <div>
      <div className={styles.discordContainer}>
        <NewPeopleGallery />
      </div>
      <ScrollUp />
      <Footer />
    </div>
  );
};

export default ContactUs;
