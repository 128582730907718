import React, { useState } from "react";
import { Link } from "react-router-dom";
import images from "../../../../../core/constants/images";
import strings from "../../../../../core/constants/strings";
import MainButton from "../../../../components/MainButton";

import styles from "./styles.module.scss";

interface dataType {
  name: string;
  role: string;
  profilePic: any;
  profilePicHover: any;
  responsability: string;
  experience: string;
  email: string;
  phone: string;
  facebook: string;
  instagram: string;
  linkedin: string;
  personalExp: string;
  personalSkills: string[];
  earlyYears: string;
  careerGuidelines: string;
}

interface IProps {
  name: string;
  role: string;
  profilePic: string;
  profilePicHover: string;
  facebook: string;
  instagram: string;
  linkedin: string;
  currentIndex: number;
  data: any;
}

const ProfileCard: React.FC<IProps> = ({
  name,
  role,
  profilePic,
  profilePicHover,
  facebook,
  instagram,
  linkedin,
  currentIndex,
  data,
}) => {
  const [profileHover, setProfileHover] = useState<string>(profilePic);
  const [profileStyleHover, setProfileStyleHover] = useState<string>(
    styles.profilePicture
  );
  const [index, setIndex] = useState<number>(0);

  const location = {
    pathname: `/team/${name.replace(" ", "")}`,
    state: { currentIndex },
  };

  return (
    <div
      className={styles.profileBackground}
      style={{ backgroundImage: `url(${images.profile_background})` }}
    >
      <img className={profileStyleHover} src={profileHover} />
      <div className={styles.memberDetailsContainer}>
        <span className={styles.name}>{name}</span>
        <span className={styles.role}>{role}</span>
      </div>

      <div className={styles.seeProfileButtonContainer}>
        <Link
          to={location}
          state={{ currentIndex }}
          className={styles.buttonLink}
        >
          <MainButton
            title={strings.ourTeam.seeProfile}
            primaryColor={"#EDA751"}
            onClick={() => {}}
          />
        </Link>
      </div>
    </div>
  );
};

export default ProfileCard;
