import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import MainButton from "../MainButton";

interface ProjectCardProps {
  title: string;
  description: string;
  imagesProject: string;
  altImages: string;
  indexCard: number;
  portofolioName: string;
}

const ProjectCard = ({
  title,
  description,
  imagesProject,
  altImages,
  indexCard,
  portofolioName,
}: ProjectCardProps) => {
  const navigate = useNavigate();

  return (
    <div
      className={`${styles.container} ${
        indexCard % 2 === 1 ? styles.containerReversed : ""
      }`}
    >
      <div className={styles.textContainer}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.title}>
          <h3 className={styles.description}>{description}</h3>
        </div>
        <a
          className={`${styles.marginButton} ${
            indexCard % 2 === 1 ? styles.alignRight : ""
          }`}
          href={`/ourwork/${portofolioName}`}
        >
          <MainButton
            primaryColor={"#EDA751"}
            title={"SEE MORE"}
            onClick={() => {}}
          />
        </a>
      </div>
      <img src={imagesProject} alt={altImages} className={styles.images} />
    </div>
  );
};
export default ProjectCard;
