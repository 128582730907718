import React, { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import strings from '../../../core/constants/strings';
import images from '../../../core/constants/images';
import Footer from '../../components/Footer/Footer';
import { aboutUsImageArray } from '../../../core/constants/arrays';
import ScrollUp from '../../components/ScrollUp/ScrollUp';

const AboutUs = () => {
  const [screenWidth, setScreenWidth] = useState<number>(0);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    handleResize();

    window.addEventListener('resize', handleResize);
  }, []);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <div
            style={
              screenWidth < 1500
                ? {
                    flexDirection: 'column',
                    alignItems: 'center',
                    display: 'flex',
                  }
                : {}
            }
          >
            <h1 className={styles.title}>{strings.aboutUs.ourStory}</h1>
            {screenWidth < 1500 ? (
              <img
                src={images.aboutUs.mainPhoto}
                className={styles.mainPhotoDesktopSmall}
              />
            ) : null}
            <p className={styles.description}>
              {strings.aboutUs.aboutUsDescription}
            </p>
          </div>
          {screenWidth > 1500 ? (
            <img src={images.aboutUs.mainPhoto} className={styles.mainPhoto} />
          ) : null}
        </div>
        <div
          style={{ backgroundImage: `url(${images.aboutUs.bundleBackground})` }}
          className={styles.aboutImageBackground}
        >
          {aboutUsImageArray.map(aboutImage => {
            return (
              <div className={styles.aboutImageContainer}>
                <div
                  style={{
                    backgroundColor: aboutImage.color,
                  }}
                  className={styles.colorOverlay}
                >
                  {aboutImage.text}
                </div>
                <img src={aboutImage.image} className={styles.aboutImage} />
              </div>
            );
          })}
        </div>
        <div className={styles.aboutDiscordText}>
          {strings.aboutUs.aboutDiscord}
        </div>
        <div className={styles.discordContainer}>
          <span className={styles.getInTouchHeadline}>
            {strings.contactUs.getInTouch}
          </span>
          <span className={styles.discordAndMail}>
            {strings.contactUs.discord}
          </span>
          <img
            src={images.discord}
            className={styles.discord}
            onClick={() => window.open('https://discord.gg/xMV2zwdjfr')}
          />
        </div>
      </div>
      <ScrollUp />
      <Footer />
    </>
  );
};

export default AboutUs;
