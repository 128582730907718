import React from "react";
import styles from "./newWhyUs.module.scss";
import strings from "../../../core/constants/strings";

function NewWhyUsPage() {
  return (
    <div className={styles.container}>
      <h1>{strings.homepage.weCanHelpIfYouAre}</h1>
      <p>{strings.homepage.whyUsRoleText}</p>
      <div className={styles.gridContainer}>
        <div>
          <p>
            {strings.homepage.helpP1Text1}
            <span> {strings.homepage.helpP1Text2} </span>
            {strings.homepage.helpP1Text3}
          </p>
        </div>
        <div>
          <p>
            {strings.homepage.helpP2Text1}
            <span> {strings.homepage.helpP2Text2} </span>
            {strings.homepage.helpP2Text3}
          </p>
        </div>
        <div>
          <p>
            {strings.homepage.helpP3Text1}
            <span> {strings.homepage.helpP3Text2}</span>
            {strings.homepage.helpP3Text3}
          </p>
        </div>
        <div>
          <p>
            {strings.homepage.helpP4Text1}
            <span> {strings.homepage.helpP4Text2} </span>
            {strings.homepage.helpP4Text3}
          </p>
        </div>
      </div>
    </div>
  );
}

export default NewWhyUsPage;
