import React, { useEffect, useRef, useState } from "react";
import { Discuss } from 'react-loader-spinner'
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import { Form } from "react-bootstrap";
import styles from "./styles.module.scss";
import Footer from "../../components/Footer/Footer";
import MainButton from "../../components/MainButton";
import ScrollUp from "../../components/ScrollUp/ScrollUp";
import { saveBlogPost } from "../../../functions/firebase";
import { blogWriters } from "../../../core/constants/data";
import "react-quill/dist/quill.snow.css";
import { getDownloadURL, getStorage, ref, uploadBytes, uploadString } from "firebase/storage";
import VideoResize from 'quill-video-resize-module';

Quill.register("modules/imageResize", ImageResize);
Quill.register('modules/VideoResize', VideoResize);

const BlogBuilder = () => {
  const [blogTitle, setBlogTitle] = useState<string>("");
  const [blogContent, setBlogContent] = useState<string>("");
  const [blogCategory, setBlogCategory] = useState<string>("Development");
  const [blogDate, setBlogDate] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [selectedImageBase64, setSelectedImageBase64] = useState<string | ArrayBuffer | null>("");
  const [selectedAuthorObject, setSelectedAuthorObject] = useState<string>("")
  const [authorName, setAuthorName] = useState<string>("");
  const [authorFunction, setAuthorFunction] = useState<string>("");
  const [authorImage, setAuthorImage] = useState<string | ArrayBuffer | null>("");
  const [loaderVisible, setLoaderVisible] = useState<boolean>(false)

  const storage = getStorage();

  const saveBlogPostFirebase = async () => {
    setLoaderVisible(true)
    await saveBlogPost(
      blogTitle,
      blogContent,
      blogCategory,
      blogDate,
      selectedImageBase64,
      authorName,
      authorFunction,
      authorImage
    ).then((resp) => {
      alert("Your blog has been saved");
      setLoaderVisible(false)
    }).catch(err => {
      alert("There is an error posting your blog!");
      setLoaderVisible(false)
    });
  };

  const selectAuthorFields = () => {
    const authorObject = JSON.parse(selectedAuthorObject);
    setAuthorName(authorObject.name);
    setAuthorFunction(authorObject.function);
    const file = document.getElementById("author-image") as HTMLImageElement | null
    if (file !== null)
      fetch(file.src).then((res) => res.blob()).then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setAuthorImage(reader!.result)
        }
        reader.readAsDataURL(blob);
      })
  }

  const modules = {
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
    VideoResize: {
      modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
     },
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const getBase64Image = async () => {
    const storageRef = ref(storage, selectedImage.name);
    await uploadBytes(storageRef, selectedImage).then(async (resp) => await getDownloadURL(resp.ref).then((downloadURL) => {
      setSelectedImageBase64(downloadURL);
    }))
  };


  useEffect(() => {
    if (selectedImage) getBase64Image();
  }, [selectedImage]);

  useEffect(() => {
    if (selectedAuthorObject !== "")
      selectAuthorFields();
  }, [selectedAuthorObject])

  return (
    <div className={styles.container}>
      <Discuss
        visible={loaderVisible}
        height="200"
        width="200"
        ariaLabel="comment-loading"
        wrapperStyle={{ position: "fixed", zIndex: "5", backgroundColor: "rgba(255,255,255,0.8)", width: "100%", height: "100vh", left: "50%", transform: "translate(-50%,0)", top: "0" }}
        wrapperClass="comment-wrapper"
        colors={["#78CFC0", "#78CFC0"]}
      />
      <div className={styles.textContainer}>
        <div
          className={styles.blogTitleContainer}
        >
          <div>
            <div>Enter Blog Title</div>
            <input
              type="text"
              className={styles.blogTitleInput}
              value={blogTitle}
              onChange={(e) => setBlogTitle(e.target.value)}
            />
          </div>
        </div>
        <div
          className={styles.blogImageContainer}
        >
          <div>
            <div>Choose Blog Author</div>
            <Form.Select
              onChange={(e) => setSelectedAuthorObject(e.target.value)}
              value={selectedAuthorObject}
              className={styles.datePicker}
            >
              <option value="">Select an author</option>
              {blogWriters.map((writer) => {
                return (<option value={JSON.stringify(writer)}>{writer.name}</option>)
              })}
            </Form.Select>
            <img id="author-image" src={selectedAuthorObject !== "" ? JSON.parse(selectedAuthorObject).imageArt : ""} alt="author image" style={{ display: "none" }}></img>
          </div>
        </div>
        <div
          className={styles.blogImageContainer}
        >
          <div>
            <div>Add blog image</div>
            <input
              type="file"
              className={styles.blogTitleInput}
              onChange={(e) => {
                if (!e.target.files) return;
                setSelectedImage(e.target.files[0]);
              }}
            />
          </div>
        </div>
        {selectedImage ? (
          <img
            alt="not found"
            id="blog-image"
            className={styles.selectedImage}
            src={URL.createObjectURL(selectedImage)}
          />
        ) : null}
        <div
          className={styles.blogImageContainer}
        >
          <div>
            <div>Choose Blog Date</div>
            <Form.Control
              type="date"
              onChange={(e) => setBlogDate(e.target.value)}
              value={blogDate}
              className={styles.datePicker}
            />
          </div>
        </div>

        <div
          className={styles.blogImageContainer}
        >
          <div>
            <div>Choose Blog Category</div>
            <Form.Select
              onChange={(e) => setBlogCategory(e.target.value)}
              value={blogCategory}
              className={styles.datePicker}
            >
              <option value="Development">Development</option>
              <option value="Marketing">Marketing</option>
              <option value="Design">Design</option>
            </Form.Select>
          </div>
        </div>
        <div className={styles.contentContainer}>
          <div>Enter Blog Content</div>
          <ReactQuill
            theme="snow"
            value={blogContent}
            onChange={setBlogContent}
            modules={modules}
            className={styles.blogContent}
          />
        </div>
      </div>

      <div
        className={styles.saveButton}
      >
        <MainButton
          title="SAVE BLOGPOST"
          primaryColor={"#EDA751"}
          onClick={saveBlogPostFirebase}
        />
      </div>
      <ScrollUp />
      <Footer />
    </div>
  );
};

export default BlogBuilder;
