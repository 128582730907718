import React from "react";

import { Text } from "./strings";
import styles from "./style.module.scss";

const BookTradeDelete = () => {
    return (
        <div className={styles.privacy}>
            <div>
                <div className={styles.titleMargin}>
                    <div className={styles.title}>{Text.section_0.title_1}</div>
                    <div className={styles.title}>{Text.section_0.title_2}</div>
                </div>
                <div className={styles.paragraph}>{Text.section_0.paragraph_1}</div>
              
                <div className={styles.paragraph}>{Text.section_0.paragraph_3}</div>
                <div className={styles.paragraph}>{Text.section_0.paragraph_4}</div>
                <div className={styles.paragraph}>{Text.section_0.paragraph_5}</div>
                <div className={styles.paragraph}>{Text.section_0.paragraph_6}</div>
                <div className={styles.paragraph}>{Text.section_0.paragraph_7}</div>
            </div>

        </div>
    );
};

export default BookTradeDelete;
