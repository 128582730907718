import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ScreenHeader from "../../components/ScreenHeader/ScreenHeader";
import images from "../../../core/constants/images";
import { projectsObject } from "../../../core/constants/data";
import PeopleGallery from "../../components/Homepage/PeopleGallery";
import styles from "./style.module.scss";
import { peopleObjects } from "../../../core/constants/data";
import Footer from "../../components/Footer/Footer";
import ScrollUp from "../../components/ScrollUp/ScrollUp";
import ProjectTeam from "../../components/ProjectTeam/ProjectTeam";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import ReviewCard from "../../components/ReviewCard";
import OldPeopleGallery from "../../components/HomepageContents/oldPeopleGallery";
import MainButton from "../../components/MainButton";
import { colors } from "../../../core/theme/colors";
import strings from "../../../core/constants/strings";
interface GalleryType {
  image: any | undefined;
  name: string | undefined;
  fct: string | undefined;
}
const Project = ({}) => {
  const params = useParams();
  const projectsData = Object.values(projectsObject).filter(
    (project) => project.projectIndividualName === params.portofolioName
  );
  const personData = peopleObjects.filter((item) => {
    if (projectsData[0].teamImages.includes(item.image)) {
      return item;
    }
  });

  const [personsObject, setPersonsObject] = useState<GalleryType[]>([
    {
      image: personData[0].image,
      name: personData[0].name,
      fct: personData[0].fct,
    },
    {
      image: personData[1].image,
      name: personData[1].name,
      fct: personData[1].fct,
    },
    {
      image: personData[2]?.image,
      name: personData[2]?.name,
      fct: personData[2]?.fct,
    },
    {
      image: personData[3]?.image,
      name: personData[3]?.name,
      fct: personData[3]?.fct,
    },
  ]);

  // useEffect(() => {
  // 	window.scrollTo(0, 0)
  // }, [])

  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const [effect, setEffect] = useState<boolean>(false);

  const style1 = {
    backgroundImage: `url(${images.ourTeamBannerMobileSVG})`,
    backgroundPosition: "center",
    backgroundPositionY: "-40px",
    backgroundSize: "85% 55%",
    backgroundOrigin: "padding-box",
  };
  const style2 = {
    backgroundImage: `url(${images.ourTeamBannerMobileSVG})`,
    backgroundPosition: "center",
    backgroundSize: "85% 65%",

    backgroundOrigin: "padding-box",
    backgroundRepeat: "no-repeat",
    marginBottom: "-50px",
  };

  return (
    <div className={styles.portofolioContainer}>
      <div className={styles.mainPhoto}>
        <div className={styles.mainTextContainer}>
          <img
            className={styles.eclipse}
            src={require("../../../core/assets/eclipse.svg").default}
          />
          <div className={styles.containerProjectLogo}>
            <img
              className={` ${
                projectsData[0].projectIndividualName === "ChalkNotes" ||
                projectsData[0].projectIndividualName === "Dog Scouts"
                  ? styles.projectLogoChalkNote
                  : styles.projectLogo
              } `}
              src={projectsData[0].portofolioProjectLogo}
            />
          </div>
        </div>
      </div>
      <div className={styles.topDetailContainer}>
        <div className={styles.detailsContainer}>
          <span className={styles.topDetailType}>
            {strings.ourwork.industry}:
          </span>
          <span className={styles.topDetailText}>
            {projectsData[0].product}
          </span>
        </div>
        <div className={styles.detailsContainer}>
          <span className={styles.topDetailType}>
            {strings.ourwork.product}:
          </span>
          <span className={styles.topDetailText}>
            {projectsData[0].industry}
          </span>
        </div>
      </div>
      {!!projectsData[0].review.text.length ? (
        <ReviewCard reviewData={projectsData[0].review} />
      ) : null}
      <div className={styles.textContainer}>
        <h2 className={styles.title}>{strings.ourwork.aFewWords}</h2>
        <h3 className={styles.littleText}>{projectsData[0].aFewWords}</h3>
      </div>
      <div className={styles.textContainer}>
        <h2 className={styles.title}>{strings.ourwork.technologies}</h2>
        <div className={styles.technologiesContainer}>
          {projectsData[0].services.map((service) => {
            return (
              <div className={styles.technologyContainer}>
                <div className={styles.technologyText}>{service}</div>
              </div>
            );
          })}
        </div>
      </div>
      {projectsData[0].projectScreens.length === 0 ? (
        ""
      ) : (
        <div
          className={`${styles.textContainer} ${styles.marginsProjectScreen}`}
        >
          <h2 className={styles.title}>{strings.ourwork.projectScreens}</h2>
          <ImageCarousel
            imageArray={projectsData[0].projectScreens}
            projectType={projectsData[0].projectType}
          />
        </div>
      )}
      <div className={styles.textContainer}>
        <h2 className={styles.title}>{strings.ourwork.projectDetails}</h2>
        <h3 className={styles.littleText}>{projectsData[0].summaryResults}</h3>
      </div>

      <h1 className={`${styles.title} ${styles.marginsTitle}`}>
        {strings.ourwork.projectTeam}
      </h1>
      <OldPeopleGallery personData={personData} />
      <MainButton
        title="CONTACT US"
        primaryColor={colors.orange}
        onClick={() => (window.location.href = "/contact")}
      />
      <Footer />
      <ScrollUp />
    </div>
  );
};
export default Project;
