import React from "react";
import { Row, Col } from "react-bootstrap";

import strings from "../../../core/constants/strings";

import styles from "./styles.module.scss";
import images from "../../../core/constants/images";

interface Props {
  textColor?: string;
}

const ContactDetails: React.FC<Props> = ({ textColor = "white" }) => {
  return (
    <div className={styles.row}>
      <div className={styles.contactButtonContainer}>
        <a
          className={styles.contactBtnGroup}
          href="https://maps.app.goo.gl/Cvyb7bp6weFzU8SP9"
        >
          <div className={styles.contactButtonInner}>
            <img src={images.location} />
          </div>
          <div className={styles.contactButton}></div>
        </a>
        <span className={styles.text} style={{ color: textColor }}>
          {strings.footer.address}
        </span>
      </div>
      <div className={styles.contactButtonContainer}>
        <a
          href={`tel:${strings.footer.phone}`}
          className={styles.contactBtnGroup}
        >
          <div className={styles.contactButtonInner}>
            <img src={images.phone} />
          </div>
          <div className={styles.contactButton}></div>
        </a>
        <span className={styles.text} style={{ color: textColor }}>
          {strings.footer.phone}
        </span>
        <span className={styles.text} style={{ color: textColor }}>
          {strings.footer.contactPerson}
        </span>
      </div>
      <div className={styles.contactButtonContainer}>
        <a
          href={`mailto:${strings.footer.email}`}
          className={styles.contactBtnGroup}
        >
          <div className={styles.contactButtonInner}>
            <img src={images.mail} />
          </div>
          <div className={styles.contactButton}></div>
        </a>
        <span className={styles.text} style={{ color: textColor }}>
          {strings.footer.email}
        </span>
      </div>
    </div>
  );
};

export default ContactDetails;
