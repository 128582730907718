import React from "react";
import { Row } from "react-bootstrap";
import styles from "./style.module.scss";
import MainButton from "../../components/MainButton";

interface IProps {
  primaryColor: string;
  title: string;
  description: string;
  mainImage: string;
}

const ServicesCard = ({
  primaryColor,
  title,
  description,
  mainImage,
}: IProps) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.verticalLine}
        style={{ backgroundColor: primaryColor }}
      />
      <div className={styles.card} style={{ borderColor: primaryColor }}>
        <img src={mainImage} className={styles.mainImage} />
        <div className={styles.textContainer}>
          <h3
            style={{ color: primaryColor, fontSize: "22px" }}
            className={styles.title}
          >
            {title}
          </h3>
          <p
            style={{ color: "#8F6F6F", fontSize: "16px" }}
            className={styles.title}
          >
            {description}
          </p>
          <div className={styles.mainRow}>
            <MainButton
              title={"VIEW MORE"}
              onClick={() => {}}
              primaryColor={primaryColor}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesCard;
