import React, { useCallback, useEffect, useState } from "react";
import styles from "../../screens/Homepage/style.module.scss";
import { Col } from "react-bootstrap";

interface IProps {
  effect: boolean;
  name1: string;
  fct1: string;
  image1: any;
  imageArt1: any;
  name2?: string | null;
  fct2?: string | null;
  image2?: any | null;
  imageArt2?: any | null;
  name3?: string | null;
  fct3?: string | null;
  image3?: any | null;
  imageArt3?: any | null;
  name4?: string | null;
  fct4?: string | null;
  image4?: any | null;
  imageArt4?: any | null;
  name5?: string | null;
  fct5?: string | null;
  image5?: any | null;
  imageArt5?: any | null;
}

const PeopleGallery = ({
  effect,
  name1,
  fct1,
  image1,
  imageArt1,
  name2,
  fct2,
  image2,
  imageArt2,
  name3,
  fct3,
  image3,
  imageArt3,
  name4,
  fct4,
  image4,
  imageArt4,
  name5,
  fct5,
  image5,
  imageArt5,
}: IProps) => {
  return (
    <div
      className={[
        effect ? styles.easeOut : styles.easeIn,
        styles.personsContainer,
      ].join(" ")}
    >
      {name1 ? (
        <Col>
          <a className={styles.onHoverImage}>
            <img src={image1} className={styles.personImage} />
            <img src={imageArt1} className={styles.personImage} />
          </a>
          <div className={styles.nameText}>{name1}</div>
          <div className={styles.functionText}>{fct1}</div>
        </Col>
      ) : (
        <div />
      )}
      {name2 ? (
        <Col>
          <a className={styles.onHoverImage}>
            <img src={image2} className={styles.personImageUneven} />
            <img src={imageArt2} className={styles.personImageUneven} />
          </a>
          <div className={styles.nameText}>{name2}</div>
          <div className={styles.functionText}>{fct2}</div>
        </Col>
      ) : (
        <div />
      )}
      {name3 ? (
        <Col>
          <a className={styles.onHoverImage}>
            <img src={image3} className={styles.personImage} />
            <img src={imageArt3} className={styles.personImage} />
          </a>
          <div className={styles.nameText}>{name3}</div>
          <div className={styles.functionText}>{fct3}</div>
        </Col>
      ) : (
        <div />
      )}
      {name4 ? (
        <Col>
          <a className={styles.onHoverImage}>
            <img src={image4} className={styles.personImageUneven} />
            <img src={imageArt4} className={styles.personImageUneven} />
          </a>
          <div className={styles.nameText}>{name4}</div>
          <div className={styles.functionText}>{fct4}</div>
        </Col>
      ) : (
        <div />
      )}
      {name5 ? (
        <Col>
          <a className={styles.onHoverImage}>
            <img src={image5} className={styles.personImage} />
            <img src={imageArt5} className={styles.personImage} />
          </a>
          <div className={styles.nameText}>{name5}</div>
          <div className={styles.functionText}>{fct5}</div>
        </Col>
      ) : (
        <div />
      )}
    </div>
  );
};

export default PeopleGallery;
