import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

//navbars
import NavbarDesktop from '../../desktop/components/Navbar/Navbar';
import NavbarTablet from '../../tablet/components/Navbar/Navbar';
import NavbarMobile from '../../mobile/components/Navbar/Navbar';

//Desktopscreens
import {
  OurTeamDesktop,
  ServicesDesktop,
  PortofolioDesktop,
  HomePageDesktop,
  ContactUsDesktop,
  ProfileDesktop,
  BlogDesktop,
  BlogPostDesktop,
  EventsDesktop,
  PaymentSuccessfulDesktop,
  PaymentUnsuccessfulDesktop,
  InProgressDesktop,
  ProjectDesktop,
  AboutUsDesktop,
} from '../../desktop/screens/';

//TabletScreens
import {
  ServicesTablet,
  PortofolioTablet,
  OurTeamTablet,
  ProfileTablet,
  ContactUsTablet,
  HomePageTablet,
  BlogTablet,
  BlogPostTablet,
  EventsTablet,
  PaymentSuccessfulTablet,
  PaymentUnsuccessfulTablet,
  InProgressTablet,
  ProjectTablet,
  AboutUsTablet,
} from '../../tablet/screens';

//MobileScreens
import {
  ServicesMobile,
  PortofolioMobile,
  OurTeamMobile,
  ContactUsMobile,
  HomePageMobile,
  ProfileMobile,
  BlogMobile,
  BlogPostMobile,
  EventsMobile,
  PaymentSuccessfulMobile,
  PaymentUnsuccessfulMobile,
  InProgressMobile,
  ProjectMobile,
  AboutUsMobile,
} from '../../mobile/screens';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import BlogBuilder from '../../desktop/screens/BlogBuilder';
import BookTradeDelete from '../../desktop/screens/BookTrade/DeleteAccount';
import Privacy from '../../desktop/screens/BookTrade/PrivacyPolicy';
import Terms from '../../desktop/screens/Terms/Terms';
import GDPR from '../../desktop/screens/GDPR/GDPR';

const renderRouter = (platform: string) => {
  if (platform === 'desktop' && isDesktop)
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePageDesktop />} />
          <Route path="/aboutus" element={<AboutUsDesktop />} />
          <Route path="/services" element={<ServicesDesktop />} />
          <Route path="/ourwork" element={<PortofolioDesktop />} />
          <Route path="/contact" element={<ContactUsDesktop />} />
          <Route path="/team" element={<OurTeamDesktop />} />
          <Route path="/team/:user" element={<ProfileDesktop />} />
          <Route path="/blog" element={<BlogDesktop />} />
          <Route path="/blog/:post" element={<BlogPostDesktop />} />
          <Route path="/blogBuilder" element={<BlogBuilder />} />
          {/* <Route path="/events" element={<EventsDesktop />} /> */}
          <Route path="/booktrade/privacy-policy" element={<Privacy />} />
          <Route
            path="/booktrade/delete-account"
            element={<BookTradeDelete />}
          />
          <Route
            path="/PaymentSuccessful"
            element={<PaymentSuccessfulDesktop />}
          />
          <Route
            path="/PaymentUnSuccessful"
            element={<PaymentUnsuccessfulDesktop />}
          />
          <Route path="/ourwork/:portofolioName" element={<ProjectDesktop />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/gdpr" element={<GDPR />} />
        </Routes>
      </BrowserRouter>
    );
  else if (platform === 'tablet' || isTablet)
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePageTablet />} />
          <Route path="/aboutus" element={<AboutUsTablet />} />
          <Route path="/services" element={<ServicesTablet />} />
          <Route path="/ourwork" element={<PortofolioTablet />} />
          <Route path="/contact" element={<ContactUsTablet />} />
          <Route path="/team" element={<OurTeamTablet />} />
          <Route path="/team/:user" element={<ProfileTablet />} />
          <Route path="/blog" element={<BlogTablet />} />
          <Route path="/blog/:post" element={<BlogPostTablet />} />
          {/* <Route path="/events" element={<EventsTablet />} /> */}
          <Route path="/booktrade/privacy-policy" element={<Privacy />} />
          <Route
            path="/booktrade/delete-account"
            element={<BookTradeDelete />}
          />
          <Route
            path="/PaymentSuccessful"
            element={<PaymentSuccessfulTablet />}
          />
          <Route
            path="/PaymentUnsuccessful"
            element={<PaymentUnsuccessfulTablet />}
          />
          <Route path="/ourwork/:portofolioName" element={<ProjectTablet />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/gdpr" element={<GDPR />} />
        </Routes>
      </BrowserRouter>
    );
  else
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePageMobile />} />
          <Route path="/aboutus" element={<AboutUsMobile />} />
          <Route path="/services" element={<ServicesMobile />} />
          <Route path="/ourwork" element={<PortofolioMobile />} />
          <Route path="/contact" element={<ContactUsMobile />} />
          <Route path="/team" element={<OurTeamMobile />} />
          <Route path="/team/:user" element={<ProfileMobile />} />
          <Route path="/blog" element={<BlogMobile />} />
          <Route path="/blog/:post" element={<BlogPostMobile />} />
          {/* <Route path="/events" element={<EventsMobile />} /> */}
          <Route path="/booktrade/privacy-policy" element={<Privacy />} />
          <Route
            path="/booktrade/delete-account"
            element={<BookTradeDelete />}
          />
          <Route
            path="/PaymentSuccessful"
            element={<PaymentSuccessfulMobile />}
          />
          <Route
            path="/PaymentUnsuccessful"
            element={<PaymentUnsuccessfulMobile />}
          />
          <Route path="/ourwork/:portofolioName" element={<ProjectMobile />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/gdpr" element={<GDPR />} />
        </Routes>
      </BrowserRouter>
    );
};

const renderNavbar = (platform: string) => {
  if (platform === 'desktop') {
    return <NavbarDesktop />;
  } else if (platform === 'tablet') {
    return <NavbarTablet />;
  } else return <NavbarMobile />;
};

const Navigation = () => {
  const [platform, setPlatform] = useState<string>('desktop');

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1200) setPlatform('desktop');
      else if (window.innerWidth < 600) setPlatform('mobile');
      else if (window.innerWidth < 1200 && window.innerWidth > 600)
        setPlatform('tablet');
    }
    handleResize();

    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {window.location.pathname !== '/PaymentSuccessful' &&
      window.location.pathname !== '/PaymentUnsuccessful' &&
      window.location.pathname !== '/booktrade/privacy-policy' &&
      window.location.pathname !== '/Terms' &&
      window.location.pathname !== '/GDPR'
        ? renderNavbar(platform)
        : null}
      {renderRouter(platform)}
    </>
  );
};

export default Navigation;
