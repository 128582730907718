import React from "react";
import styles from "./styles.module.scss";

interface IProps {
  title: string;
  primaryColor: string;
  onClick: () => void;
}

const MainButton = ({ title, primaryColor, onClick }: IProps) => {
  return (
    <div className={styles.btnGroup} onClick={onClick}>
      <div className={styles.innerButton}>
        <span>{title}</span>
      </div>
      <div
        className={styles.mainButton}
        style={{ backgroundColor: primaryColor }}
      ></div>
    </div>
  );
};

export default MainButton;
