import React, { useState, useEffect } from 'react';
import routes from '../../../core/navigation/routes';
import images from '../../../core/constants/images';
import styles from './style.module.scss';

const Navbar = () => {
  const [lastScrollY, setLastScrollY] = useState(0);
  const [show, setShow] = useState(false);

  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY === 0) {
        setShow(false);
      } else if (window.scrollY > lastScrollY) {
        setShow(true);
      } else {
        setShow(false);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <div className={styles.container}>
      <div className={`${styles.navbar} ${show && styles.hidden}`}>
        <a href={routes.home.route}>
          <img className={styles.logo} src={images.logo}></img>
        </a>
        <div className={styles.links}>
          {Object.values(routes).map(
            (item: { name: string; route: string }, index) => (
              <a key={index} href={item.route}>
                {item.name}
              </a>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
